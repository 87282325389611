import { RefObject, useEffect, useMemo, useRef } from 'react'
import debounce from 'lodash.debounce'

type useOnResizeType = (props: {
  track?: boolean
  delay?: number
  elementRef?: RefObject<HTMLDivElement | null>
  onResize?: () => void
}) => void

const createResizeObserver = (callback: () => void) => {
  try {
    return new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry?.contentRect?.height) {
          callback()
        }
      })
    })
  } catch {
    // Do nothing, the browser doesn't support `ResizeObserver`
  }
}

const useOnResizeObserver: useOnResizeType = ({
  track = true,
  delay = 100,
  elementRef,
  onResize,
}) => {
  const resizeObserverRef = useRef<ResizeObserver>(undefined)

  const onResizeHandler = useMemo(
    () => (track && onResize ? debounce(onResize, delay) : undefined),
    [track, delay, onResize]
  )

  useEffect(() => {
    const element = elementRef?.current
    const shouldTrack = track && element && onResizeHandler

    if (shouldTrack) {
      resizeObserverRef.current = createResizeObserver(onResizeHandler)

      if (resizeObserverRef.current) {
        resizeObserverRef.current.observe(element)
      }
    }
    return () => {
      if (shouldTrack && resizeObserverRef.current) {
        resizeObserverRef.current.unobserve(element)
      }
    }
  }, [elementRef, onResizeHandler, track])

  useEffect(() => {
    // cleanup function
    return () => {
      onResizeHandler?.cancel()
    }
  }, [onResizeHandler])
}

export default useOnResizeObserver
