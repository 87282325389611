import Blink from '@components/Blink'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { StoryProps } from './types'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import StoryContent from './StoryContent'
import useViewportTracking from '@hooks/useViewportTracking'
import { triggerAureusImpression } from '@utils/aureus'
import { useQueryClient } from '@tanstack/react-query'

const StyledWrapper = styled.li`
  min-width: 0;
  list-style-type: none;
`

const StyledBlink = styled(Blink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  text-decoration: none;
`

const StyledOrder = styled.div`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
      typography: {
        headings: {
          xxxlarge: { bundledCSS: xxxLargeHeadingCSS },
        },
      },
    },
  }) => css`
    ${xxxLargeHeadingCSS};
    color: ${blickRed};
    min-width: 28px;
  `}
`

const Story: FunctionComponent<StoryProps> = ({
  title,
  catchword = '',
  order,
  link,
  isPlus,
  aureusOfferId,
  commentCount,
  isMostRead,
}) => {
  const queryClient = useQueryClient()
  const [impressionOccured, setImpressionOccured] = useState(false)
  const onClick = useCallback<TrackingFnType>(
    () => ({
      event: isMostRead ? 'select_content' : 'element_click',
      ...(isMostRead
        ? {
            placement: 'article-recommendations-most-read',
            link_id: link?.href,
          }
        : {
            element: 'most-commented',
            link_url: link?.href,
          }),
    }),
    [link?.href, isMostRead]
  )

  const trackOnClick = useTracking(onClick)
  const handleClick = useCallback(() => {
    trackOnClick()
  }, [trackOnClick])

  const onStoryImpression = useCallback(() => {
    setImpressionOccured(true)
  }, [])

  useEffect(() => {
    if (aureusOfferId && impressionOccured) {
      triggerAureusImpression({
        aureusOfferId,
        queryClient,
      })
    }
  }, [impressionOccured, onStoryImpression, aureusOfferId, queryClient])

  const viewportRef = useViewportTracking({
    onImpression: onStoryImpression,
    track: true,
  })

  return (
    <StyledWrapper ref={viewportRef}>
      <StyledBlink
        {...link}
        aureusOfferId={aureusOfferId}
        onClick={handleClick}>
        <StyledOrder>{order}</StyledOrder>
        <StoryContent
          title={title}
          catchword={catchword}
          isPlus={isPlus}
          commentCount={commentCount}
        />
      </StyledBlink>
    </StyledWrapper>
  )
}

export default Story
