import { useRef, useState, useCallback, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'

export type UseIsRiAdTagManagerScriptLoadSuccess = () => boolean

const useIsRiAdTagManagerScriptLoadSuccess: UseIsRiAdTagManagerScriptLoadSuccess =
  () => {
    const queryClient = useQueryClient()
    const queryCache = queryClient.getQueryCache()
    const unsubscribeFnRef = useRef<() => void>(undefined)

    const [isScriptLoadSuccess, setIsScriptLoadSuccess] = useState<boolean>(
      () => !!queryClient.getQueryData<boolean>(['RiAdTagManagerScriptLoaded'])
    )

    const updateIsScriptLoadSuccess = useCallback(
      (args: any) => {
        if (
          args?.action?.type === 'invalidate' &&
          args.query.queryKey[0] === 'RiAdTagManagerScriptLoaded'
        ) {
          setIsScriptLoadSuccess(
            !!queryClient.getQueryData<boolean>(['RiAdTagManagerScriptLoaded'])
          )
        }
      },
      [queryClient]
    )

    useEffect(() => {
      setIsScriptLoadSuccess(
        !!queryClient.getQueryData<boolean>(['RiAdTagManagerScriptLoaded'])
      )
      unsubscribeFnRef.current = queryCache.subscribe(updateIsScriptLoadSuccess)

      return () => {
        if (unsubscribeFnRef.current) {
          unsubscribeFnRef.current()
        }
      }
    }, [updateIsScriptLoadSuccess, queryCache, queryClient])

    return isScriptLoadSuccess
  }

export default useIsRiAdTagManagerScriptLoadSuccess
