import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { Link } from '@utils/cook/types'
import { JSONWidgetType as TeaserStandardHybridJSONWidgetType } from '@widgets/TeaserStandardHybrid'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import SectionHeader from '@components/SectionHeader'
import JSONRenderer from '@components/JSONRenderer'
import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'

interface TripletModuleAPIProps {
  title: string
  accentColor: string
  link?: Link
  items?: TeaserStandardHybridJSONWidgetType[]
}

const Wrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: grid;
    grid-gap: ${spacing16};
  `}
`
const ItemsWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing32, spacing16 },
    },
  }) => css`
    display: grid;
    grid-gap: ${spacing16};
    ${desktopCSS(css`
      grid-template-columns: repeat(3, 1fr);
      grid-gap: ${spacing32};
    `)}
  `}
`

const ItemWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
      color: {
        tertiary: { grey400 },
      },
    },
  }) => css`
    ${mobileAndTabletCSS(css`
      padding-bottom: ${spacing16};
      border-bottom: 1px solid ${grey400};

      &:last-child {
        margin-bottom: 0;
        border: none;
      }
    `)}
  `}
`

const TripletModule: FunctionComponent<TripletModuleAPIProps> = ({
  title,
  link,
  items,
  accentColor,
}) => {
  const hasItems = !!items
  return (
    <Wrapper>
      <SectionHeader
        title={title}
        accentColor={accentColor}
        link={link}
        isSubheader={true}
      />
      <ItemsWrapper>
        {hasItems &&
          items.map((item) => (
            <ItemWrapper key={item.articleId}>
              <JSONRenderer>{item}</JSONRenderer>
            </ItemWrapper>
          ))}
      </ItemsWrapper>
    </Wrapper>
  )
}

const widget = {
  kind: ['triplet-module'],
  component: TripletModule,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
