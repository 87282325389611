import styled, { css } from 'styled-components'
import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'
import Blink from '@components/Blink'

export interface StyledTeaserStandardVerticalWrapperProps {
  isHybridTeaser: boolean
  isSpecialABRender: boolean
}

const StyledTeaserStandardVerticalWrapper = styled(
  Blink
)<StyledTeaserStandardVerticalWrapperProps>`
  ${({ isHybridTeaser, isSpecialABRender }) => css`
    display: block;
    appearance: none;
    text-decoration: none;

    ${isHybridTeaser &&
    isSpecialABRender &&
    css`
      ${desktopCSS(css`
        display: none;
      `)}
    `};

    ${isHybridTeaser &&
    !isSpecialABRender &&
    css`
      ${mobileAndTabletCSS(css`
        display: none;
      `)};
    `}
  `}
`

export default StyledTeaserStandardVerticalWrapper
