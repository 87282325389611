import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { printCSS } from '@utils/style'

const maxHeight = 80

interface FadeOverlayProps {
  isExpanded: boolean
  hasInvertedColors?: boolean
}

const StyledFadeOverlay = styled.div<FadeOverlayProps>`
  ${({
    isExpanded,
    theme: {
      color: {
        primary: { primary01, primary02 },
      },
    },
    hasInvertedColors,
  }) => css`
    position: absolute;
    bottom: 100%;
    left: 0;
    height: ${maxHeight}px;
    width: 100%;
    background: linear-gradient(
      180deg,
      ${hasInvertedColors ? primary01 : primary02}00 0%,
      ${hasInvertedColors ? primary01 : primary02} 100%
    );

    ${printCSS(css`
      background: unset;
    `)}
    ${isExpanded &&
    css`
      display: none;
    `}
  `}
`

const FadeOverlay: FunctionComponent<FadeOverlayProps> = ({
  isExpanded,
  hasInvertedColors,
}) => (
  <StyledFadeOverlay
    isExpanded={isExpanded}
    hasInvertedColors={hasInvertedColors}
  />
)

export default FadeOverlay
