import { RefObject, useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import usePageMetadata from '@hooks/usePageMetadata'
import { isVideoOnlyArticle } from '@utils/cook'
import {
  ViewportDimensions,
  ViewportTypeValues,
} from '@hooks/useViewport/types'
import { VideoElementTrackingParams } from '@widgets/Video/types'

type UseScrollToCliffhanger = (
  trackedOnClick: (
    extraData: Pick<VideoElementTrackingParams, 'buttonText'>
  ) => void
) => () => void

const useScrollToCliffhanger: UseScrollToCliffhanger = (trackedOnClick) => {
  const queryClient = useQueryClient()
  const { targetContentType } = usePageMetadata()
  const isVideoOnly = isVideoOnlyArticle(targetContentType)

  const calculateOffset = useCallback(() => {
    const viewportType = queryClient.getQueryData<ViewportTypeValues>([
      'viewportType',
    ])
    const viewportDimensions = queryClient.getQueryData<ViewportDimensions>([
      'viewportDimensions',
    ])
    return !viewportDimensions || !isVideoOnly || viewportType !== 'mobile'
      ? 0
      : (viewportDimensions.width * 9) / 16
  }, [queryClient, isVideoOnly])

  return useCallback(() => {
    const cliffhangerRef = queryClient.getQueryData<
      RefObject<HTMLDivElement | null>
    >(['cliffhanger-widget-ref'])
    const cliffhangerRefTop =
      cliffhangerRef?.current?.getBoundingClientRect?.()?.top

    if (cliffhangerRefTop) {
      try {
        trackedOnClick({ buttonText: 'blickPlus' })
        window.scrollTo({
          behavior: 'smooth',
          //62px - header first row height, 48px - header second row height
          top: cliffhangerRefTop - calculateOffset() - 62 - 48 + window.scrollY,
        })
      } catch (err) {
        console.error(err)
      }
    }
  }, [queryClient, trackedOnClick, calculateOffset])
}

export default useScrollToCliffhanger
