import { RefObject, useEffect, useMemo, useRef } from 'react'

type setNodeHeightType = (value: number | string) => void

export type UseHeightTransitionType = ({
  getIsVisible,
  collapsedHeight,
}: {
  getIsVisible: () => boolean
  collapsedHeight?: number
}) => {
  componentRef: RefObject<HTMLDivElement | null>
}

const useHeightTransition: UseHeightTransitionType = ({
  getIsVisible,
  collapsedHeight = 0,
}) => {
  const componentRef = useRef<null | HTMLDivElement>(null)

  const setNodeHeight: setNodeHeightType = (value: string | number) => {
    if (componentRef.current) {
      componentRef.current.style.height =
        typeof value === 'number' ? `${value}px` : value
    }
  }

  useEffect(() => {
    const scrollHeight = componentRef?.current?.scrollHeight
    setNodeHeight(scrollHeight || collapsedHeight)
    /* this setTimeout in order to set the height to auto after the animation, if the text was collapsed before
       and if the text was expanded, we need setTimeout to fix the animation, since it does not work with auto height*/
    setTimeout(
      () => setNodeHeight(getIsVisible() ? 'auto' : collapsedHeight),
      getIsVisible() ? 700 : 0
    )
  }, [getIsVisible, collapsedHeight])

  useEffect(() => {
    if (componentRef.current) {
      componentRef.current.style.maxHeight = 'unset'
      const scrollHeight = componentRef.current?.scrollHeight
      const textTooLong = scrollHeight ? scrollHeight > collapsedHeight : false
      setNodeHeight(textTooLong ? collapsedHeight : scrollHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef])

  const heightTransitionFunctions = useMemo(
    () => ({ componentRef }),
    [componentRef]
  )

  return heightTransitionFunctions
}

export default useHeightTransition
