import { css } from 'styled-components'
import type { CSSProp } from 'styled-components'
import { getDeviceType } from '@utils/deviceType'

const layout = {
  mobile: { maxWidth: 767.9 },
  tablet: {
    minWidth: 768,
    maxWidth: 993.9,
  },
  desktop: {
    minWidth: 994,
  },
  header: {
    desktop: {
      offsetFirstRow: 80,
      offsetSecondRow: 49,
      totalOffset: 129,
    },
    mobileAndTablet: {
      offsetFirstRow: 48,
      offsetSecondRow: 49,
      totalOffset: 97,
    },
  },
} as const

const breakpoints = {
  mobile: `(max-width: ${layout.mobile.maxWidth}px)`,
  mobileLandscape: `(max-width: ${layout.tablet.maxWidth}px) and (max-height: ${layout.mobile.maxWidth}px) and (orientation: landscape)`,
  tablet: `(min-width: ${layout.tablet.minWidth}px) and (max-width: ${layout.tablet.maxWidth}px)`,
  mobileAndTablet: `(max-width: ${layout.tablet.maxWidth}px)`,
  desktop: `(min-width: ${layout.desktop.minWidth}px)`,
} as const

const mobileCSS = (styles: CSSProp) => css`
  @media ${breakpoints.mobile} {
    ${styles}
  }
`

const mobileLandscapeCSS = (styles: CSSProp) => css`
  @media ${breakpoints.mobileLandscape} {
    ${styles}
  }
`

const tabletCSS = (styles: CSSProp) => css`
  @media ${breakpoints.tablet} {
    ${styles}
  }
`

const mobileAndTabletCSS = (styles: CSSProp) => css`
  @media ${breakpoints.mobileAndTablet} {
    ${styles}
  }
`

const desktopCSS = (styles: CSSProp) => css`
  @media ${breakpoints.desktop} {
    ${styles}
  }
`

/* 1593px is after talking to Ringier Advertising */
const desktopLeftAlignedCSS = (styles: CSSProp) => css`
  @media screen and (max-width: 1593px) {
    ${styles}
  }
`

/* 1594px is after talking to Ringier Advertising */
const desktopCenteredContentCSS = (styles: CSSProp) => css`
  @media screen and (min-width: 1594px) {
    ${styles}
  }
`

const landscapeOrientationCSS = (styles: CSSProp) => css`
  @media (orientation: landscape) {
    ${styles}
  }
`

const getCurrentViewportType = (): 'desktop' | 'tablet' | 'mobile' | '' =>
  typeof window === 'undefined'
    ? ''
    : window.matchMedia(breakpoints.desktop).matches
      ? 'desktop'
      : window.matchMedia(breakpoints.tablet).matches
        ? 'tablet'
        : 'mobile'

const isMobileUserAgent = () => {
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
  const isAndroid = !!userAgent.match(/Android/i)
  const isOpera = !!userAgent.match(/Opera Mini/i)
  const isWindows = !!userAgent.match(/IEMobile/i)
  const isIos = getDeviceType() === 'ios'
  return isAndroid || isIos || isOpera || isWindows
}

export {
  layout,
  breakpoints,
  mobileCSS,
  mobileLandscapeCSS,
  tabletCSS,
  mobileAndTabletCSS,
  desktopCSS,
  desktopLeftAlignedCSS,
  desktopCenteredContentCSS,
  landscapeOrientationCSS,
  getCurrentViewportType,
  isMobileUserAgent,
}
