import { FunctionComponent, ReactNode, useContext } from 'react'
import styled, { css } from 'styled-components'
import contextArticleTextbox, {
  ArticleTextboxContextType,
} from '@contexts/articleTextbox'
import { desktopCSS } from '@measures/responsive'

const { ArticleTextboxContext } = contextArticleTextbox

type StyledWrapperProps = Pick<ArticleTextboxContextType, 'isPromo'>

const StyledWrapper = styled.div<StyledWrapperProps>`
  ${({
    theme: {
      spacing: { spacing2, spacing4, spacing24, spacing16, spacing32 },
      color: {
        primary: { blickRed },
      },
    },
    isPromo,
  }) => {
    return css`
      margin: ${spacing32} 0;
      ${!isPromo &&
      css`
        padding: 0 ${spacing16};
        border-left: ${spacing2} solid ${blickRed};
        ${desktopCSS(css`
          padding: 0 ${spacing24};
          border-left: ${spacing4} solid ${blickRed};
        `)};
      `};
    `
  }}
`

const Wrapper: FunctionComponent<{ children?: ReactNode }> = ({ children }) => {
  const { isPromo } = useContext(ArticleTextboxContext)
  return <StyledWrapper isPromo={isPromo}>{children}</StyledWrapper>
}

export default Wrapper
