import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import EmbeddedContent from '@components/EmbeddedContent'
import config from '@config'
import { desktopCSS, mobileCSS, mobileAndTabletCSS } from '@measures/responsive'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { HeimspielLiveCenterProps } from './types'
import { layout } from '@measures/responsive'
import useCMPCookieCategories from '@hooks/useCMPCookieCategories'

const {
  header: {
    desktop: { totalOffset: headerTotalOffsetDesktop },
    mobileAndTablet: { totalOffset: headerTotalOffsetMobileAndTablet },
  },
} = layout

const {
  heimspiel: { heimspielLiveCenterStaticPageUrl },
} = config

const StyledEmbeddedContent = styled(EmbeddedContent)`
  ${({
    theme: {
      measures: { outerPadding },
    },
  }) => css`
    ${desktopCSS(css`
      min-height: calc(100vh - ${headerTotalOffsetDesktop}px);
    `)}

    ${mobileAndTabletCSS(css`
      min-height: calc(100vh - ${headerTotalOffsetMobileAndTablet}px);
    `)}
    ${mobileCSS(css`
      margin-left: -${outerPadding.mobile};
      margin-right: -${outerPadding.mobile};
      width: auto;
    `)}
  `}
`

const HeimspielLiveCenter: FunctionComponent<HeimspielLiveCenterProps> = ({
  encodedPathParam,
}) => {
  const allCategoriesEnabled = useCMPCookieCategories(
    'allCategoriesEnabledChanged'
  )

  if (allCategoriesEnabled === undefined) {
    return null
  }

  const url = new URL(heimspielLiveCenterStaticPageUrl)

  if (encodedPathParam) {
    url.searchParams.append('path', decodeURIComponent(encodedPathParam))
  }

  url.searchParams.set(
    'is_third_party_content_allowed',
    `${allCategoriesEnabled}`
  )

  return <StyledEmbeddedContent url={url.href} bypassCMP={true} />
}

const widget = {
  kind: ['widget', 'heimspiel', 'livecenter'],
  component: HeimspielLiveCenter,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
