import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import { useQueryClient } from '@tanstack/react-query'
import playerIntl from './config/playerIntl'

import config from '@config'
import {
  AdsManagerParam,
  GeoBlockedErrorParam,
} from './types/jwplayer-extended'
import { initRecommendationsControls } from './elements/recommendations'
import { isMobileUserAgent } from '@measures/responsive'
import { addReplayButton, removeReplayButton } from './utils/controls'
import ReactJWPlayer from './lib'
import {
  controlbar,
  display,
  captions,
  overlay,
  playlistNavigationButtons,
  live,
  ads,
  wrapper,
} from './styles'

import {
  JWPlayer,
  BlickTVInputProps,
  GraphicsObject,
  OverlayEventParam,
} from './types'

import { initLiveStreamLogic } from './elements/live'
import { initCustomFullScreenOverlay } from './elements/overlay'
import { initCaptionsControls } from './elements/captions'
import { initFocusedScrubbingMode } from './elements/focusedScrubbingMode'
import { initPlaylistVideoNavigationControls } from './elements/playlistVideoNavigation'

import TextTracks from './components/TextTracks'
import ErrorOverlay from './components/Errors'
import { geoBlockedError } from './components/Errors/utils'
import UnmuteCTAButton from './components/UnmuteCTAButton'

import { getPlaylistUrl } from './utils/index'
import {
  transformPlaylistItem,
  isOverlay,
  setOverlay,
  removeUserInactiveClass,
} from './utils/player'
import usePlayerEvents from '@hooks/useVideoPlayerEvents'
import { logVideoPlayer } from './utils/logger'
import TrailerVideoOverlay from '@components/Video/Trailer/TrailerVideoOverlay'
import useIsVideoGeoblocked from '@hooks/useIsVideoGeoblocked'
import useBodyScroll from '@hooks/useBodyScroll'

const { jwplayer: jwplayerConfig } = config

const StyledPlayerWrapper = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) => css`
    position: relative;
    height: 100%;

    .jw-svg-icon-replay {
      visibility: ${isMobile ? 'visible' : 'hidden'};
    }

    .jw-icon-display {
      visibility: ${isMobile ? 'visible' : 'hidden'};
    }
  `}
`

const StyledPlayer = styled(ReactJWPlayer)<any>`
  .jwplayer {
    ${wrapper}
    ${controlbar}
    ${display}
    ${playlistNavigationButtons}
    ${captions}
    ${overlay}
    ${live}
    ${ads}
  }
`

const BlickTVPlayer = forwardRef<HTMLDivElement, BlickTVInputProps>(
  (
    {
      daiAssetKey,
      daiAdTagParameters,
      jwVideoId,
      liveFallbackSource,
      isAdDisabled,
      adTagURL,
      autoplay,
      // optional props
      posterURL,
      allowFullscreen = true,
      hasPlaylist,
      isBlickTV,
      isLiveStreamVideo,
      hasNextPlaylistItem,
      hasPreviousPlaylistItem,
      mountPlayer = true,
      isVideoPosterClicked,
      onHeadlineChange,
      onPlay,
      onPlaySuccess,
      onPlayPrevious,
      onProgress,
      onAdRequest,
      onAdStarted,
      onAdPlay,
      onAdSkipped,
      onAdEnded,
      onError,
      onEnd,
      onQuartileCompletion,
      onEnterOverlay,
      onExitOverlay,
      onMute,
      onUnmute,
      onCaptionsEnabled,
      onCaptionsDisabled,
      onSeek,
      onPip,
      onVolumeChange,
      onUnmuteCTAButtonClick,
      onUnmuteCTAButtonImpression,
      onRecommendationsOverlay,
      onReady,
      onCast,
      isVideoRecommendationsEnabled,
      shouldShowTrailer,
      title,
      geoblocking,
      onThumbnailData,
      ...jwPlayerProps
    },
    ref
  ) => {
    const queryClient = useQueryClient()
    const areRecommendationsAvailableRef = useRef(false)
    const [setupFinished, setSetupFinished] = useState(false)
    const playerRef = useRef<JWPlayer>(undefined)
    const playerInMemoryRef = useRef<boolean>(false)
    const [playerError, setPlayerError] = useState<
      jwplayer.ErrorParam | undefined
    >()
    const isBodyScrollLocked = useRef<boolean>(false)
    const { lockBodyScroll, unlockBodyScroll } = useBodyScroll()

    useEffect(() => {
      return () => {
        if (isBodyScrollLocked.current) {
          unlockBodyScroll()
          isBodyScrollLocked.current = false
        }
      }
    }, [unlockBodyScroll])

    const trackingInfo = useMemo(
      () => ({
        videoId: jwVideoId,
        videoTitle: title || '',
      }),
      [title, jwVideoId]
    )
    const isDaiEnabled = !!(daiAssetKey && daiAdTagParameters)

    const isVideoGeoblocked = useIsVideoGeoblocked(geoblocking)
    const isMobile = isMobileUserAgent()

    const onReloadHandler = useCallback(() => {
      window.location.reload()
    }, [])

    const graphicsCueChangeHandler = useCallback(
      (graphics?: GraphicsObject) => {
        if (graphics?.headline) {
          onHeadlineChange?.(graphics.headline)
        }
      },
      [onHeadlineChange]
    )

    const errorHandler = useCallback(
      (error: jwplayer.ErrorParam) => {
        if (playerRef.current) {
          if (isOverlay(playerRef.current)) {
            setOverlay(playerRef.current, false)
          }
        }
        setPlayerError(error)

        onError?.({ error })
      },
      [onError]
    )

    const fullscreenHandler = useCallback(
      (isOverlay: boolean) => {
        if (isOverlay) {
          lockBodyScroll()
          isBodyScrollLocked.current = true
        } else {
          unlockBodyScroll()
          isBodyScrollLocked.current = false
        }
      },
      [lockBodyScroll, unlockBodyScroll]
    )

    const geoBlockedErrorHandler = useCallback(
      ({ isGeoBlocked }: GeoBlockedErrorParam) => {
        if (isGeoBlocked) {
          if (playerRef.current && isOverlay(playerRef.current)) {
            setOverlay(playerRef.current, false)
          }

          setPlayerError(geoBlockedError)
        } else {
          setPlayerError(undefined)
        }
      },
      []
    )

    const onAll = useCallback(
      (event: string) => {
        logVideoPlayer({ queryClient }, 'event', event, jwVideoId)
      },
      [jwVideoId, queryClient]
    )

    useEffect(() => {
      const player = playerRef.current

      if (player && setupFinished) {
        if (!isBlickTV && isVideoRecommendationsEnabled?.()) {
          initRecommendationsControls(
            player,
            onRecommendationsOverlay,
            areRecommendationsAvailableRef,
            hasPlaylist,
            hasNextPlaylistItem,
            shouldShowTrailer
          )
        }

        initLiveStreamLogic(player, isDaiEnabled)
        initCaptionsControls(player, { onCaptionsEnabled, onCaptionsDisabled })
        initFocusedScrubbingMode(player)
        if (allowFullscreen) {
          initCustomFullScreenOverlay(player)
        }
        if (hasPlaylist) {
          initPlaylistVideoNavigationControls(
            player,
            hasNextPlaylistItem,
            hasPreviousPlaylistItem,
            onEnd,
            onPlayPrevious
          )
        }

        player.setPlaylistItemCallback?.((item) => {
          return new Promise((resolve) => {
            /** Resolve the promise to resume loading and playback
             * with a new item that adjusts the motion thumbnails
             * and/or poster image
             */

            resolve(transformPlaylistItem(item, posterURL))
          })
        })

        player.on('firstFrame', () => {
          onThumbnailData?.()
        })

        player.on('adsManager', (e: AdsManagerParam) => {
          /**
           * removes some additional elements from the ad
           * like attribution / countdown (ex. Anzeige (0:09) text)
           */
          e.adsManager?.updateAdsRenderingSettings({ uiElements: [] })
        })

        player.on('complete', () => {
          if (!isMobileUserAgent() && hasPlaylist === false) {
            addReplayButton(
              player,
              onRecommendationsOverlay,
              isVideoRecommendationsEnabled
            )
          }
        })

        player.on('beforePlay', () => {
          if (!isMobileUserAgent() && hasPlaylist === false) {
            removeReplayButton(player)
          }
        })

        player.on('pipLeave', () => {
          // we need to remove the player when it leaves the pip mode (if it was unmounted)
          if (playerInMemoryRef.current) {
            playerRef.current?.remove()
          }
        })

        player.on('displayClick', () => {
          if (playerRef.current) {
            removeUserInactiveClass(playerRef.current)
          }
        })

        player?.on('overlay', (param: OverlayEventParam) => {
          fullscreenHandler(!!param?.overlay)
        })

        player.on('geoBlockedError', geoBlockedErrorHandler)

        if (!autoplay && isVideoPosterClicked) {
          player.play()
        }

        onReady?.(player)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setupFinished])

    usePlayerEvents({
      player: setupFinished ? playerRef.current : undefined,
      onAll,
      onAdPlay,
      onAdRequest,
      onAdStarted,
      onAdSkipped,
      onAdEnded,
      onVolumeChange,
      onMute,
      onUnmute,
      onPlaySuccess,
      onPlay,
      onSeek,
      onProgress,
      onEnd,
      onEnterOverlay,
      onExitOverlay,
      onRecommendationsOverlay,
      onQuartileCompletion,
      onPip,
      onError: errorHandler,
      onSetupError: errorHandler,
      onCast,
    })

    /**
     * dispose of the player on unmount
     */
    useEffect(() => {
      return () => {
        // disposing of the player on unmount will stop player from sending
        // pulling requests (eg. for Google DAI) and other async logic
        try {
          // if the player is in PiP mode, we need to keep it alive
          if (!playerRef.current?.getPip?.()) {
            playerRef.current?.remove()
          }
          playerInMemoryRef.current = true
        } catch (err) {
          console.error(err)
        }
      }
    }, [])

    const handleSetupComplete = useCallback((playerInstance: JWPlayer) => {
      playerRef.current = playerInstance

      playerRef.current.isPlayerInMemory = (): boolean =>
        playerInMemoryRef.current

      setSetupFinished(true)
    }, [])

    const getPlaylist = useCallback(
      () =>
        getPlaylistUrl(
          jwVideoId,
          isDaiEnabled,
          daiAssetKey,
          liveFallbackSource
        ),
      [jwVideoId, isDaiEnabled, daiAssetKey, liveFallbackSource]
    )

    const advertisingParams = useCallback(() => {
      if (!isAdDisabled) {
        if (isDaiEnabled) {
          return { client: 'dai', adTagParameters: daiAdTagParameters }
        }
        if (adTagURL) {
          return {
            client: 'googima',
            tag: adTagURL,
            maxRedirects: 10,
            vpaidcontrols: true,
          }
        }
        return {}
      }

      return {}
    }, [isAdDisabled, isDaiEnabled, adTagURL, daiAdTagParameters])

    const autostartParam = useCallback(
      () => (autoplay ? 'viewable' : 'false'),
      [autoplay]
    )

    const unmuteCTAButtonClickHandler = useCallback(() => {
      playerRef.current?.setMute(false)
      onUnmuteCTAButtonClick?.()
    }, [onUnmuteCTAButtonClick])

    return (
      <StyledPlayerWrapper isMobile={isMobile} ref={ref}>
        {playerError ? (
          <ErrorOverlay
            errorCode={isVideoGeoblocked ? 232403 : playerError?.code}
            onReload={onReloadHandler}
          />
        ) : null}
        {shouldShowTrailer ? (
          <TrailerVideoOverlay
            trackingInfo={trackingInfo}
            player={playerRef.current}
            onRecommendationOverlay={onRecommendationsOverlay}
          />
        ) : null}
        {mountPlayer ? (
          <StyledPlayer
            /** necessary player params */
            className="jw-blick-player"
            onSetup={handleSetupComplete}
            playlist={getPlaylist()}
            autostart={autostartParam()}
            /** player components internationalisation */
            intl={playerIntl}
            /** advertising params */
            advertising={advertisingParams()}
            /** optional player params  - defaults */
            {...jwplayerConfig.staticPlayerParams}
            /** optional player params - overrides */
            cast={isLiveStreamVideo ? {} : undefined}
            {...jwPlayerProps}
            pipIcon={isLiveStreamVideo ? 'enabled' : 'disabled'}
            allowFullscreen={allowFullscreen}
          />
        ) : null}
        {setupFinished && playerRef.current ? (
          <>
            <TextTracks
              player={playerRef.current}
              onGraphicsCueChange={graphicsCueChangeHandler}
            />
            {jwPlayerProps.mute ? (
              <UnmuteCTAButton
                player={playerRef.current}
                onClick={unmuteCTAButtonClickHandler}
                onImpression={onUnmuteCTAButtonImpression}
              />
            ) : null}
          </>
        ) : null}
      </StyledPlayerWrapper>
    )
  }
)

BlickTVPlayer.displayName = 'BlickTVPlayer'

export default memo(BlickTVPlayer)
