import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { ImageRatios } from './types'
import { desktopCSS, tabletCSS, mobileCSS } from '@measures/responsive'

export interface PlaceholderProps {
  ratios: ImageRatios
  disablePlaceholder?: boolean
  isLoaded: boolean
  children?: ReactNode
  className?: string
  hasValidSourceDesktop: boolean
  hasValidSourceTablet: boolean
  hasValidSourceMobile: boolean
}

type StyledPlaceholderProps = Omit<PlaceholderProps, 'children'>

const StyledPlaceholder = styled.div<StyledPlaceholderProps>`
  ${({
    theme: {
      color: {
        tertiary: { grey200 },
      },
    },
    disablePlaceholder,
    ratios,
    isLoaded,
    hasValidSourceDesktop,
    hasValidSourceTablet,
    hasValidSourceMobile,
  }) => {
    const [ratioWidthDesktop, ratioHeightDesktop] = ratios['desktop']
      .split('_')
      .map((ratio) => parseInt(ratio, 10))

    const [ratioWidthTablet, ratioHeightTablet] = ratios['tablet']
      .split('_')
      .map((ratio) => parseInt(ratio, 10))

    const [ratioWidthMobile, ratioHeightMobile] = ratios['mobile']
      .split('_')
      .map((ratio) => parseInt(ratio, 10))

    return css`
      position: relative;
      width: 100%;
      height: 0;

      ${desktopCSS(css`
        background-color: ${isLoaded && hasValidSourceDesktop
          ? 'transparent'
          : grey200};
      `)};

      ${tabletCSS(css`
        background-color: ${isLoaded && hasValidSourceTablet
          ? 'transparent'
          : grey200};
      `)};

      ${mobileCSS(css`
        background-color: ${isLoaded && hasValidSourceMobile
          ? 'transparent'
          : grey200};
      `)};

      ${!disablePlaceholder &&
      css`
        ${desktopCSS(css`
          padding-bottom: ${(ratioHeightDesktop * 100) / ratioWidthDesktop}%;
        `)}

        ${tabletCSS(css`
          padding-bottom: ${(ratioHeightTablet * 100) / ratioWidthTablet}%;
        `)}

        ${mobileCSS(css`
          padding-bottom: ${(ratioHeightMobile * 100) / ratioWidthMobile}%;
        `)}
      `}
    `
  }}
`

const Placeholder: FunctionComponent<PlaceholderProps> = ({
  ratios,
  isLoaded,
  children,
  disablePlaceholder,
  className,
  hasValidSourceDesktop,
  hasValidSourceTablet,
  hasValidSourceMobile,
}) => {
  return (
    <StyledPlaceholder
      ratios={ratios}
      isLoaded={isLoaded}
      disablePlaceholder={disablePlaceholder}
      className={className}
      hasValidSourceDesktop={hasValidSourceDesktop}
      hasValidSourceTablet={hasValidSourceTablet}
      hasValidSourceMobile={hasValidSourceMobile}>
      {children}
    </StyledPlaceholder>
  )
}

export default Placeholder
