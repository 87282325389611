import config from '@config'
import { PageData } from '@hooks/usePageMetadata'
import { getCurrentViewportType } from '@measures/responsive'
import { QueryClient } from '@tanstack/react-query'
import { getURLParam } from '@utils/urlParams'

export type ReturnIfDefined = (args: {
  key: string
  value: unknown
  deriveTrackingValue?: (value: unknown) => string | undefined
}) => Record<string, string>

const {
  publication: { publication },
  tracking: { metadataFields },
  urlParams: { clientOnlyURLParams },
} = config

const derivePageTrackingValue: Parameters<ReturnIfDefined>[0]['deriveTrackingValue'] =
  (value) => {
    if (typeof value === 'string') {
      if (value.includes('.html')) {
        const lastSegmentIndex = value.lastIndexOf('/') + 1
        const lastDashIndex = value.lastIndexOf('-') + 1
        const titleSegment = value.substring(lastSegmentIndex, lastDashIndex)

        return value.replace(titleSegment, '')
      } else {
        return value
      }
    }
  }

const deriveUrlTitleTrackingValue: Parameters<ReturnIfDefined>[0]['deriveTrackingValue'] =
  (value) => {
    if (typeof value === 'string' && value.includes('.html')) {
      const lastSegmentIndex = value.lastIndexOf('/') + 1
      const lastDashIndex = value.lastIndexOf('-') + 1
      const titleSegment = value.substring(lastSegmentIndex, lastDashIndex)

      return parseInt(titleSegment, 10) < 100
        ? titleSegment.substring(0, titleSegment.length - 1) // remove the last dash as it doesn't belong to the title
        : titleSegment.substring(0, 100) // Firebase doesn't allow event parameters with more than 100 characters
    }
  }

const returnIfDefined: ReturnIfDefined = ({
  key,
  value,
  deriveTrackingValue = (value) =>
    typeof value !== 'string' && typeof value !== 'undefined'
      ? value?.toString()
      : value,
}) => {
  const derivedValue = deriveTrackingValue(value)

  if (typeof derivedValue === 'undefined') {
    return {}
  }

  return { [key]: derivedValue }
}

const getDefaultTrackingData = (
  queryClient: QueryClient
): Record<string, unknown> => {
  const pageMetadata = queryClient.getQueryData<PageData>(['page'])?.metadata

  if (typeof pageMetadata === 'undefined') {
    return {}
  }

  return {
    platform_region: publication === 'romandie' ? 'Blick_Romandie' : 'Blick',
    requestSource: `blick_web_${getCurrentViewportType() === 'desktop' ? 'desktop' : 'mobile'}`,

    ...clientOnlyURLParams.reduce(
      (acc, urlParam) => {
        if ('includeInDataLayer' in urlParam && urlParam.includeInDataLayer) {
          const urlParamValue = getURLParam(urlParam.name, queryClient)
          return {
            ...acc,
            ...returnIfDefined({
              key: urlParam.name,
              value: urlParamValue,
            }),
          }
        } else {
          return acc
        }
      },
      {} as Record<string, number | boolean | string | unknown[]>
    ),

    ...metadataFields.reduce(
      (acc, keyName) => {
        return {
          ...acc,
          ...returnIfDefined({
            key: keyName,
            value: pageMetadata[keyName],
          }),
        }
      },
      {} as Record<string, number | boolean | string | unknown[]>
    ),

    ...returnIfDefined({
      key: 'page',
      value: pageMetadata.url,
      deriveTrackingValue: derivePageTrackingValue,
    }),

    ...returnIfDefined({
      key: 'urlTitle',
      value: pageMetadata.url,
      deriveTrackingValue: deriveUrlTitleTrackingValue,
    }),

    ...returnIfDefined({
      key: 'content_origin',
      value: pageMetadata.contentOrigin,
    }),

    ...returnIfDefined({
      key: 'analyticsRessort',
      value: pageMetadata.ressort,
    }),
  }
}

const stripHtml = (html = '') => html.replace(/(<([^>]+)>)/gi, '')

export { getDefaultTrackingData, returnIfDefined, stripHtml }
