import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import config from '@config'
import EmbeddedContent from '@components/EmbeddedContent'

const {
  usersnap: { widgetUrl },
} = config

interface UsersnapProps {
  id: string
  custom?: { [key: string]: any }
}

const Usersnap: FunctionComponent<UsersnapProps> = ({ id, custom }) => {
  const url = new URL(widgetUrl)
  url.searchParams.append('usersnapId', id)
  url.searchParams.append('custom', JSON.stringify(custom))

  return (
    <EmbeddedContent
      url={url.toString()}
      id={id}
      maxHeight={500}
      maxWidth={510}
      idFieldName="usersnapId"
      heightFieldName="usersnapHeight"
    />
  )
}

const widget = {
  kind: ['widget', 'tool', 'usersnap'],
  component: Usersnap,
} as const satisfies CookWidget
export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
