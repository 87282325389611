import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'

import useInViewChange from '@hooks/useInViewChange'
import useVideoSourceFetching from '@hooks/useVideoSourceFetching'
import ToiBackgroundLiveStreamVideo from '@components/Video/VideoPlayer/ToiBackgroundLiveStreamVideo'
import config from '@config'

const {
  video: { transparentPoster },
} = config

const INVIEWPORT_TRIGGER_DELAY = 500
const INVIEWPORT_TRIGGER_THRESHOLD = 0.75
const VIDEO_REPLAY_TIMEOUT = 5000

type TeaserBackgroundVideoProps = {
  videoId: string
  isLiveStream?: boolean
  onPlay?: () => void
  onStop?: () => void
}

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const commonVideoStyles = css`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
    },
  }) => css`
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: ${primary01Color};
  `}
`

const StyledVideo = styled.video`
  ${commonVideoStyles}
  object-fit: cover;
`

const StyledToiBackgroundLiveStreamVideo = styled(ToiBackgroundLiveStreamVideo)`
  ${commonVideoStyles}
`

const TeaserBackgroundVideo: FunctionComponent<TeaserBackgroundVideoProps> = ({
  videoId,
  isLiveStream = false,
  onPlay,
  onStop,
}) => {
  const nextPlayTimeoutRef = useRef<ReturnType<typeof setTimeout>>(undefined)

  const [isInViewport, setIsInViewport] = useState<boolean | null>(null)
  const [isVideoStopped, setIsVideoStopped] = useState<boolean>(false)

  const { fetchedSourceUrl } = useVideoSourceFetching({
    videoId,
    enabled: !!isInViewport,
    width: 640,
  })

  const shouldPlayVideo = isInViewport && fetchedSourceUrl && !isVideoStopped

  const onInViewChangeHandler = useCallback((inView: any) => {
    setIsInViewport(inView)
  }, [])

  const viewportRef = useInViewChange({
    track: true,
    threshold: INVIEWPORT_TRIGGER_THRESHOLD,
    delay: INVIEWPORT_TRIGGER_DELAY,
    onInViewChange: onInViewChangeHandler,
  })

  useEffect(() => {
    if (!shouldPlayVideo) {
      onStop?.()
    }
  }, [shouldPlayVideo, onStop])

  const onVideoPlay = useCallback(() => {
    onPlay?.()
  }, [onPlay])

  const playVideoWithDelay = useCallback(() => {
    if (nextPlayTimeoutRef.current) {
      clearTimeout(nextPlayTimeoutRef.current)
      nextPlayTimeoutRef.current = undefined
    }

    nextPlayTimeoutRef.current = setTimeout(() => {
      setIsVideoStopped(false)
      nextPlayTimeoutRef.current = undefined
    }, VIDEO_REPLAY_TIMEOUT)
  }, [])

  const onVideoEnded = useCallback(() => {
    setIsVideoStopped(true)
    playVideoWithDelay()
  }, [playVideoWithDelay])

  const onError = useCallback(() => {
    setIsVideoStopped(true)
  }, [])

  useEffect(() => {
    if (!isInViewport) {
      clearTimeout(nextPlayTimeoutRef.current)
      setIsVideoStopped(false)
    }
  }, [isInViewport])

  useEffect(() => {
    return () => {
      if (nextPlayTimeoutRef.current) {
        clearTimeout(nextPlayTimeoutRef.current)
      }
    }
  }, [])

  return (
    <VideoWrapper ref={viewportRef}>
      {shouldPlayVideo ? (
        isLiveStream ? (
          <StyledToiBackgroundLiveStreamVideo
            src={fetchedSourceUrl}
            onPlay={onVideoPlay}
            onError={onError}
          />
        ) : (
          <StyledVideo
            muted
            autoPlay
            playsInline
            x-webkit-airplay="deny"
            src={fetchedSourceUrl}
            onPlay={onVideoPlay}
            onEnded={onVideoEnded}
            poster={transparentPoster}
          />
        )
      ) : null}
    </VideoWrapper>
  )
}

export default TeaserBackgroundVideo
