import { FunctionComponent } from 'react'
import usePageMetadata from '@hooks/usePageMetadata'

import { VideoWidgetAPIProps } from '@widgets/Video/types'
import VideoTitle from './VideoTitle'

import VideoContainer from '@components/Video/VideoPlayer/VideoContainer'
import VideoPlayerWrapper from '@components/Video/VideoPlayer/VideoPlayerWrapper'

const VideoWidgetPlaceholder: FunctionComponent<VideoWidgetAPIProps> = (
  props
) => {
  const pageMetadata = usePageMetadata()

  const {
    catchword: catchwordArticle,
    title: titleArticle,
    targetContentType,
  } = pageMetadata

  const { catchword, isMainElement, title, isInScoreboardContent } = props

  const isSameName = catchword === catchwordArticle && title === titleArticle
  const showTitle =
    (!isSameName || !isMainElement) &&
    (!isMainElement || targetContentType !== 'video')

  return (
    <VideoContainer isInScoreboardContent={isInScoreboardContent}>
      <VideoPlayerWrapper>
        <div></div>
      </VideoPlayerWrapper>
      {showTitle ? <VideoTitle /> : null}
    </VideoContainer>
  )
}

export default VideoWidgetPlaceholder
