import { FunctionComponent, useContext, useMemo } from 'react'
import Blink from '@components/Blink'
import styled, { css } from 'styled-components'
import { ActionLinkProps } from '@widgets/ArticleTextbox/types'
import contextArticleTextbox, {
  ArticleTextboxContextType,
} from '@contexts/articleTextbox'
import PrimaryCTABlink from '@components/Buttons/PrimaryCTABlink'
import { classifyUrl } from '@components/Blink/utils'

const { ArticleTextboxContext } = contextArticleTextbox

type ContainerProps = Pick<ArticleTextboxContextType, 'isPromo'> &
  Pick<ActionLinkProps, 'verticallyCentered'>

const Container = styled.div<ContainerProps>`
  ${({ isPromo, verticallyCentered }) =>
    isPromo &&
    !verticallyCentered &&
    css`
      width: 100%;
      display: flex;
      justify-content: center;
    `}
  ${({
    isPromo,
    verticallyCentered,
    theme: {
      spacing: { spacing20 },
    },
  }) =>
    isPromo &&
    verticallyCentered &&
    /* Negative left margin is needed to horizontally center the button, as the container has a padding of 20px  */
    css`
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      margin-left: -${spacing20};
      padding-bottom: ${spacing20};
    `}
`

const StyledBlink = styled(Blink)`
  ${({
    theme: {
      color: {
        primary: { blickRed },
        secondary: { darkRed },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadCSS },
        },
      },
      spacing: { spacing16 },
    },
  }) => css`
    margin-top: ${spacing16};
    display: flex;
    align-items: center;
    cursor: pointer;
    ${small1SubheadCSS};

    color: ${blickRed};
    text-decoration: underline;

    &:hover {
      color: ${darkRed};
    }
  `}
`

const StyledPrimaryCTABlink = styled(PrimaryCTABlink)`
  ${({
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    margin-top: ${spacing24};
  `}
`

const ActionLink: FunctionComponent<ActionLinkProps> = ({
  blinkProps,
  children,
  verticallyCentered,
}) => {
  const { isPromo } = useContext(ArticleTextboxContext)
  const { isOutbound } = useMemo(
    () => classifyUrl(blinkProps.href),
    [blinkProps.href]
  )

  return (
    <Container verticallyCentered={verticallyCentered} isPromo={isPromo}>
      {isPromo ? (
        <StyledPrimaryCTABlink
          size="large"
          {...(isOutbound ? { iconName: 'arrow-up-right-from-square' } : {})}
          {...blinkProps}>
          {children}
        </StyledPrimaryCTABlink>
      ) : (
        <StyledBlink {...blinkProps}>{children}</StyledBlink>
      )}
    </Container>
  )
}

export default ActionLink
