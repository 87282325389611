import config from '@config'
import { breakpoints } from '@measures/responsive'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'

interface TeamLogoProps {
  url: string
  name: string
  className?: string
  imageWidth: number
}

interface StyledTeamLogoProps {
  hasError: boolean
}

type StyledPictureProps = Pick<TeamLogoProps, 'imageWidth'>

const sourceMediaQueryDesktop = breakpoints.desktop
const sourceMediaQueryTablet = breakpoints.tablet
const sourceMediaQueryMobileRegular =
  '(min-width: 375px) and (max-width: 767.9px)'
const sourceMediaQueryMobileSmall = '(max-width: 374.9px)'

const {
  backend: { imageUrl: backendImageUrl },
} = config

const StyledTeamLogo = styled.div<StyledTeamLogoProps>`
  ${({ hasError }) =>
    hasError &&
    css`
      display: none;
    `};
  line-height: 0;
`

const StyledPicture = styled.picture<StyledPictureProps>`
  ${({ imageWidth }) => css`
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    > img {
      width: auto;
      height: 100%;
      max-width: ${imageWidth}px;
      max-height: ${imageWidth}px;
    }
  `}
`

const TeamLogo: FunctionComponent<TeamLogoProps> = ({
  url,
  name,
  className,
  imageWidth,
}) => {
  const [error, setError] = useState<boolean>(false)
  const imageRef = useRef<HTMLImageElement>(null)

  const onError = useCallback(() => {
    setError(true)
  }, [])

  useEffect(() => {
    const currentImage = imageRef.current
    currentImage?.addEventListener('error', onError)
    return () => {
      currentImage?.removeEventListener('error', onError)
    }
  }, [onError])

  const basePictureSource = `${backendImageUrl}${url}?ratio=free`
  const pictureSourceDesktop = `${basePictureSource}&imwidth=${imageWidth}`
  const pictureSourceTablet = `${basePictureSource}&imwidth=${imageWidth}`
  const pictureSourceMobileRegular = `${basePictureSource}&imwidth=${imageWidth}`
  const pictureSourceMobileSmall = `${basePictureSource}&imwidth=${imageWidth}`

  return (
    <StyledTeamLogo className={className} hasError={error}>
      <StyledPicture imageWidth={imageWidth}>
        <source
          media={sourceMediaQueryDesktop}
          srcSet={`${pictureSourceDesktop}&imdensity=1 1x, ${pictureSourceDesktop}&imdensity=2 2x, ${pictureSourceDesktop}&imdensity=3 3x`}
        />
        <source
          media={sourceMediaQueryTablet}
          srcSet={`${pictureSourceTablet}&imdensity=1 1x, ${pictureSourceTablet}&imdensity=2 2x, ${pictureSourceTablet}&imdensity=3 3x`}
        />
        <source
          media={sourceMediaQueryMobileRegular}
          srcSet={`${pictureSourceMobileRegular}&imdensity=1 1x, ${pictureSourceMobileRegular}&imdensity=2 2x, ${pictureSourceMobileRegular}&imdensity=3 3x`}
        />
        <source
          media={sourceMediaQueryMobileSmall}
          srcSet={`${pictureSourceMobileSmall}&imdensity=1 1x, ${pictureSourceMobileSmall}&imdensity=2 2x, ${pictureSourceMobileSmall}&imdensity=3 3x`}
        />
        {/* eslint-disable-next-line @next/next/no-img-element  */}
        <img ref={imageRef} alt={name} width={1} height={1} loading="lazy" />
      </StyledPicture>
    </StyledTeamLogo>
  )
}

export default TeamLogo
