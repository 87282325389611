import { FunctionComponent, ReactNode, useContext } from 'react'
import styled, { css } from 'styled-components'
import contextArticleTextbox, {
  ArticleTextboxContextType,
} from '@contexts/articleTextbox'
import { desktopCSS } from '@measures/responsive'

const { ArticleTextboxContext } = contextArticleTextbox

type StyledContentWrapperProps = Pick<
  ArticleTextboxContextType,
  'isExpanded' | 'isPromo'
>

const StyledContentWrapper = styled.div<StyledContentWrapperProps>`
  ${({
    theme: {
      color: {
        tertiary: { grey500 },
      },
      spacing: { spacing4, spacing16, spacing24, spacing20, spacing32 },
    },
    isExpanded,
    isPromo,
  }) => css`
    overflow: hidden;
    ${isPromo &&
    css`
      padding: ${spacing20} ${spacing16};
      ${desktopCSS(css`
        padding: ${spacing32} ${spacing24};
      `)}
      border-radius: ${spacing4};
      border: 1px solid ${grey500};
    `}
    ${!isExpanded &&
    css`
      height: 115px;
    `}
  `}
`

const ContentWrapper: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  const { isExpanded, isPromo } = useContext(ArticleTextboxContext)

  return (
    <StyledContentWrapper isExpanded={isExpanded} isPromo={isPromo}>
      {children}
    </StyledContentWrapper>
  )
}

export default ContentWrapper
