import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

const StyledFadedExpandableTextWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    position: relative;
    padding-top: ${spacing16};
    display: flex;
    justify-content: center;
  `}
`

const FadedExpandableTextWrapper: FunctionComponent<{
  children?: ReactNode
}> = ({ children }) => {
  return (
    <StyledFadedExpandableTextWrapper>
      {children}
    </StyledFadedExpandableTextWrapper>
  )
}

export default FadedExpandableTextWrapper
