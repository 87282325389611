import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

export type UseIsHeaderSecondRowCollapsed = () => boolean

export type IsHeaderSecondRowCollapsedType = boolean | null

const useIsHeaderSecondRowCollapsed: UseIsHeaderSecondRowCollapsed = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>(undefined)

  const [isHeaderSecondRowCollapsed, setIsHeaderSecondRowCollapsed] =
    useState<boolean>(
      () =>
        !!queryClient.getQueryData<IsHeaderSecondRowCollapsedType>([
          'is-header-second-row-collapsed',
        ])
    )

  const updateIsHeaderSecondRowCollapsed = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === 'is-header-second-row-collapsed'
      ) {
        setIsHeaderSecondRowCollapsed(
          !!queryClient.getQueryData<IsHeaderSecondRowCollapsedType>([
            'is-header-second-row-collapsed',
          ])
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    setIsHeaderSecondRowCollapsed(
      !!queryClient.getQueryData<IsHeaderSecondRowCollapsedType>([
        'is-header-second-row-collapsed',
      ])
    )

    unsubscribeFnRef.current = queryCache.subscribe(
      updateIsHeaderSecondRowCollapsed
    )

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, queryClient, updateIsHeaderSecondRowCollapsed])

  return isHeaderSecondRowCollapsed
}

export default useIsHeaderSecondRowCollapsed
