import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { useQueryClient } from '@tanstack/react-query'
import styled, { css } from 'styled-components'
import { printCSS } from '@utils/style'
import { layout } from '@measures/responsive'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { stripHtml } from '@hooks/useTracking/utils'
import useViewportTracking from '@hooks/useViewportTracking'

const {
  header: {
    desktop: { totalOffset: headerTotalOffsetDesktop },
    mobileAndTablet: { totalOffset: headerTotalOffsetMobileAndTablet },
  },
} = layout

const StyledCommentingWrapper = styled.div`
  position: relative;

  ${desktopCSS(css`
    scroll-margin-top: ${headerTotalOffsetDesktop}px;
  `)}

  ${mobileAndTabletCSS(css`
    scroll-margin-top: ${headerTotalOffsetMobileAndTablet}px;
  `)}
  
  ${printCSS(css`
    display: none;
  `)}
`

const CommentingWrapper: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(undefined)
  const queryClient = useQueryClient()

  const onImpression = useCallback<TrackingFnType>(({ pageMetadata }) => {
    const { id, title } = pageMetadata
    return {
      event: 'article_impression_comment',
      eventCategory: 'article',
      eventLabel: `${id}:${stripHtml(title)}`,
      eventAction: 'impression_comment',
    }
  }, [])

  const trackedOnImpression = useTracking(onImpression)

  const viewportRef = useViewportTracking({
    onImpression: trackedOnImpression,
    track: true,
  })

  const wrapperRefFn = useCallback(
    (node: HTMLDivElement) => {
      wrapperRef.current = node
      viewportRef(node)
    },
    [viewportRef]
  )

  useEffect(() => {
    queryClient.setQueryData(['commenting-widget-ref'], wrapperRef)
    return () => {
      queryClient.removeQueries({
        queryKey: ['commenting-widget-ref'],
        exact: true,
      })
    }
  }, [queryClient, wrapperRef])

  return (
    <StyledCommentingWrapper ref={wrapperRefFn}>
      {children}
    </StyledCommentingWrapper>
  )
}

export { StyledCommentingWrapper }

export default CommentingWrapper
