import dynamic from 'next/dynamic'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import useIsInHerotellingPage from '@hooks/useIsInHerotellingPage'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

interface TitleProps {
  title: string
}

type StyledTitleProps = {
  isInHerotellingPage: boolean
  hasInvertedColors: boolean
}

const HTMLView = dynamic(() => import('@components/HTMLView'))

const StyledTitle = styled.span<StyledTitleProps>`
  ${({
    theme: {
      color: {
        primary: { primary01, primary02 },
      },
      spacing: { spacing8 },
      typography: {
        headings: {
          large: { bundledCSS: largeHeadingCSS },
          xxxlarge: { bundledCSS: xxxlargeHeadingCSS },
        },
      },
    },
    isInHerotellingPage,
    hasInvertedColors,
  }) => css`
    ${largeHeadingCSS};
    text-align: left;
    margin-top: ${spacing8};
    color: ${hasInvertedColors || isInHerotellingPage ? primary02 : primary01};
    display: block;
    overflow-wrap: anywhere;
    word-break: break-word;
    ${isInHerotellingPage && 'text-align: inherit'};
    ${desktopCSS(css`
      ${xxxlargeHeadingCSS};
    `)}
  `}
`

const Title: FunctionComponent<TitleProps> = ({ title }) => {
  const isInHerotellingPage = useIsInHerotellingPage()
  const hasInvertedColors = useHasInvertedColors()

  const allowedHtmlTags = isInHerotellingPage
    ? ['sup', 'sub', 'span', 'h1']
    : ['sup', 'sub', 'span']

  if (!title) {
    return null
  }

  return (
    <StyledTitle
      isInHerotellingPage={isInHerotellingPage}
      hasInvertedColors={hasInvertedColors}>
      <HTMLView content={title} allowedHtmlTags={allowedHtmlTags} />
    </StyledTitle>
  )
}

export default Title
