import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

type UseIsPianoSDKLoaded = () => boolean

const useIsPianoSDKLoaded: UseIsPianoSDKLoaded = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>(undefined)

  const [isPianoSDKLoaded, setIsPianoSDKLoaded] = useState<boolean>(
    () => !!queryClient.getQueryData<boolean>(['isPianoSDKLoaded'])
  )

  const updateIsPianoSDKLoaded = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === 'isPianoSDKLoaded'
      ) {
        setIsPianoSDKLoaded(
          !!queryClient.getQueryData<boolean>(['isPianoSDKLoaded'])
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(updateIsPianoSDKLoaded)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [updateIsPianoSDKLoaded, queryCache])

  return isPianoSDKLoaded
}

export default useIsPianoSDKLoaded
