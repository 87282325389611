import { ImageRatios } from '@components/ResponsiveImageV2/types'

const IMAGE_RATIOS: ImageRatios = {
  desktop: '3_2',
  tablet: '3_2',
  mobile: '3_2',
} as const

const IMAGE_WIDTHS = {
  desktop: 964,
  tablet: 664,
  mobile: 'viewport-width',
} as const

export { IMAGE_RATIOS, IMAGE_WIDTHS }
