import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import { Image, Link, TargetContentType, TeaserVideo } from '@utils/cook/types'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { desktopCSS } from '@measures/responsive'

import Blink from '@components/Blink'
import VideoLabel from '@components/VideoLabel'
import Catchword from '@components/TeaserToiVideo/Catchword'
import Title from '@components/TeaserToiVideo/Title'
import BlickPlusLogo from '@components/TeaserToiVideo/BlickPlusLogo'
import ImageComponent from '@components/TeaserToiVideo/Image'
import TeaserAnimatedPreview from '@components/TeaserAnimatedPreview'
import useAnimatedPreview from '@hooks/useAnimatedPreview'

export type TeaserToiVideoAPIProps = {
  accentColor: string
  title: string
  catchword?: string
  labelText?: string
  video?: TeaserVideo
  image: Image<'16_9'>
  link: Link
  section: {
    name: string
    uniqueName: string
  }
  contentOrigin?: string
  track?: boolean
  isPlus?: boolean
  articleId?: string
  targetContentType: TargetContentType
}

const StyledTeaserToiVideo = styled(Blink)`
  display: block;
  appearance: none;
  text-decoration: none;
`

const GridWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  grid-template-columns: minmax(0, 1fr);
  align-items: flex-end;
`

const TextStack = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: flex-end;
  justify-items: flex-start;
`

const GradientStack = styled.div`
  ${({
    theme: {
      spacing: { spacing12, spacing24 },
      color: {
        primary: { primary01: primary01Color },
      },
    },
  }) => css`
    box-sizing: border-box;
    width: 100%;
    display: grid;
    position: relative;
    grid-template-columns: minmax(0, 1fr);
    align-items: flex-start;
    justify-items: flex-start;
    padding: 0 ${spacing12} ${spacing12} ${spacing12};

    ${desktopCSS(css`
      padding: 0 ${spacing24} ${spacing24} ${spacing24};
    `)}
    &:before {
      content: '';
      position: absolute;
      top: -48px;
      left: 0;
      width: 100%;
      height: calc(100% + 48px);

      ${desktopCSS(css`
        top: -80px;
        height: calc(100% + 80px);
      `)}

      background: linear-gradient(4deg, ${primary01Color}99 50%, ${primary01Color}00 90%)
    }
  `}
`

const StyledVideoLabel = styled(VideoLabel)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    margin-bottom: ${spacing8};
  `}
`

const TeaserToiVideo: FunctionComponent<TeaserToiVideoAPIProps> = (props) => {
  const { title, link, image, targetContentType, catchword, isPlus, video } =
    props

  const showCatchword = !!isPlus || !!catchword

  const ref = useAnimatedPreview({
    video,
    articleUrl: link.href,
  })

  return (
    <StyledTeaserToiVideo {...link} ref={ref}>
      <ImageComponent {...image}>
        <GridWrapper>
          <TextStack>
            {!!video && (
              <TeaserAnimatedPreview video={video} articleUrl={link.href} />
            )}
            <GradientStack>
              {!!video && (
                <StyledVideoLabel
                  duration={video.duration}
                  targetContentType={targetContentType}
                />
              )}
              {showCatchword && (
                <Catchword>
                  {isPlus && <BlickPlusLogo />}
                  {catchword}
                </Catchword>
              )}
              <Title>{title}</Title>
            </GradientStack>
          </TextStack>
        </GridWrapper>
      </ImageComponent>
    </StyledTeaserToiVideo>
  )
}

const widget = {
  kind: ['teaser-toi-video'],
  component: TeaserToiVideo,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
