import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { SwiperClass, ArrowsVisibilityType } from '@hooks/useSwiper'
import useHasInvertedColors from '@hooks/useHasInvertedColors'
import IconButton from '@components/Buttons/IconButton'

export interface NavigationArrowsProps {
  swiperRef: React.RefObject<SwiperClass | null>
  arrowsVisibility?: ArrowsVisibilityType
  className?: string
  onClick?: (prevOrNext: 'prev' | 'next') => void
}

const StyledPlaceholder = styled.div`
  min-height: 32px;
`

const StyledWrapper = styled.div`
  display: flex;
`

const StyledIconButton = styled(IconButton)<{ disabled: boolean }>`
  ${({ disabled }) => css`
    opacity: ${disabled ? 0.2 : 1};
    pointer-events: ${disabled ? 'none' : 'auto'};
  `}
`

const NavigationArrows: FunctionComponent<NavigationArrowsProps> = ({
  swiperRef,
  arrowsVisibility = 'next',
  className,
  onClick,
}) => {
  const theme = useTheme()
  const hasInvertedColors = useHasInvertedColors()

  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState(() =>
    ['none', 'next'].includes(arrowsVisibility)
  )
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState(() =>
    ['none', 'prev'].includes(arrowsVisibility)
  )

  useEffect(() => {
    setIsLeftArrowDisabled(['none', 'next'].includes(arrowsVisibility))
    setIsRightArrowDisabled(['none', 'prev'].includes(arrowsVisibility))
  }, [arrowsVisibility])

  const handleOnLeftArrowClick = useCallback(() => {
    swiperRef.current?.slideToDirection('prev')
    onClick?.('prev')
  }, [onClick, swiperRef])

  const handleOnRightArrowClick = useCallback(() => {
    swiperRef.current?.slideToDirection('next')
    onClick?.('next')
  }, [onClick, swiperRef])

  return (
    <StyledPlaceholder className={className}>
      <StyledWrapper>
        <StyledIconButton
          iconName="arrow-left"
          iconSize={32}
          iconColor={
            theme.color.primary[hasInvertedColors ? 'primary02' : 'primary01']
          }
          buttonSize={32}
          onClick={handleOnLeftArrowClick}
          ariaLabel="left arrow"
          disabled={isLeftArrowDisabled}
        />
        <StyledIconButton
          iconName="arrow-right"
          iconSize={32}
          iconColor={
            theme.color.primary[hasInvertedColors ? 'primary02' : 'primary01']
          }
          buttonSize={32}
          onClick={handleOnRightArrowClick}
          ariaLabel="left arrow"
          disabled={isRightArrowDisabled}
        />
      </StyledWrapper>
    </StyledPlaceholder>
  )
}

export default NavigationArrows
