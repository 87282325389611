import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { ImageRatios, ImageWidths } from '@components/ResponsiveImageV2/types'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Image } from '@utils/cook/types'

type HeadlineHeroImageProps = Omit<Image<'1_1' | '3_4'>, 'width' | 'height'>

const imageRatios: ImageRatios = {
  desktop: '1_1',
  tablet: '1_1',
  mobile: '3_4',
} as const

const imageWidths: ImageWidths = {
  desktop: 960,
  tablet: 768,
  mobile: 'viewport-width',
} as const

const StyledResponsiveImageV2 = styled(ResponsiveImageV2)`
  grid-area: hhi;
`

const HeadlineHeroImage: FunctionComponent<HeadlineHeroImageProps> = ({
  src,
  alt,
  crops,
}) => (
  <StyledResponsiveImageV2
    src={src}
    alt={alt}
    ratios={imageRatios}
    crops={crops ?? {}}
    widths={imageWidths}
    loading="eager"
  />
)

export default HeadlineHeroImage
