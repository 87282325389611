import usePageMetadata, { PageMetadata } from '@hooks/usePageMetadata'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import styled, { css } from 'styled-components'
import { ToplistAPIProps, StoryAPIProps } from './types'
import { getToplist, isValidToplistStories } from './utils'
import Story from './Story'
import { desktopCSS } from '@measures/responsive'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import { printCSS } from '@utils/style'

type StyledWrapperProps = Pick<ToplistAPIProps, 'hideOnDesktop'>

const StyledWrapper = styled.div<StyledWrapperProps>`
  ${({ hideOnDesktop }) => {
    return css`
      width: 100%;
      ${desktopCSS(css`
        min-height: 264px;
      `)}
      ${hideOnDesktop &&
      desktopCSS(css`
        display: none;
      `)}
      ${printCSS(css`
        display: none;
      `)}
    `
  }}
`

const Title = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingCSS },
        },
      },
      color: {
        primary: { primary01, blickRed },
      },
      spacing: { spacing8 },
    },
  }) => {
    return css`
      ${smallHeadingCSS};
      color: ${primary01};
      text-align: left;
      border-left: 2px solid ${blickRed};
      padding-left: ${spacing8};
      text-transform: uppercase;
    `
  }}
`

const ChildrenContainer = styled.ol`
  ${({
    theme: {
      spacing: { spacing20, spacing16 },
    },
  }) => {
    return css`
      margin: 0;
      display: grid;
      padding-top: ${spacing20};
      box-sizing: border-box;
      width: 100%;
      padding-inline-start: 0;
      grid-template-rows: repeat(3, minmax(0, 1fr));
      grid-row-gap: ${spacing16};
    `
  }}
`

const Toplist: FunctionComponent<ToplistAPIProps> = ({
  title,
  type,
  hideOnDesktop,
}) => {
  const pageMetadata = usePageMetadata()
  const isMostRead = type === 'most-read'

  const id = (pageMetadata as PageMetadata)?.id

  const { data: apiToplistData } = useQuery<StoryAPIProps[]>({
    queryKey: ['stories', id, type],
    queryFn: () => getToplist(id as string, type),
    enabled: !!id,
  })

  const finalStories = useMemo(
    () => (isValidToplistStories(apiToplistData) ? apiToplistData : []),
    [apiToplistData]
  )

  const onImpressionTracking = useCallback<TrackingFnType>(
    () => ({
      event: isMostRead ? 'content_impression' : 'element_impression',
      ...(isMostRead
        ? {
            placement: 'article-recommendations-most-read',
            link_id: pageMetadata.url,
          }
        : { element: 'most-commented' }),
    }),
    [pageMetadata.url, isMostRead]
  )

  const onImpression = useTracking(onImpressionTracking)

  const viewportRef = useViewportTracking({
    onImpression,
    track: true,
  })

  return (
    <StyledWrapper hideOnDesktop={hideOnDesktop} ref={viewportRef}>
      {finalStories?.length > 0 && (
        <>
          <Title>{title}</Title>
          <ChildrenContainer>
            {finalStories.map((story, index) => {
              const {
                articleId,
                title,
                catchword,
                link,
                isPlus,
                aureusOfferId,
                commentCount,
              } = story
              return (
                <Story
                  key={articleId}
                  order={index + 1}
                  title={title}
                  catchword={catchword}
                  articleId={articleId}
                  link={link}
                  isPlus={isPlus}
                  aureusOfferId={aureusOfferId}
                  commentCount={commentCount}
                  isMostRead={isMostRead}
                />
              )
            })}
          </ChildrenContainer>
        </>
      )}
    </StyledWrapper>
  )
}

const widget = {
  kind: ['toplist'],
  component: Toplist,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
