import { ImageWidths } from '@components/ResponsiveImageV2/types'

const IMAGE_HEIGHT = 140
const IMAGE_HEIGHT_HERO = 109

const getImageWidths = (imageSize: number): ImageWidths => ({
  desktop: 134,
  tablet: imageSize,
  mobile: imageSize,
})

export { IMAGE_HEIGHT, IMAGE_HEIGHT_HERO, getImageWidths }
