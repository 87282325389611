import { useCallback } from 'react'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { PageData, PageMetadata } from '@hooks/usePageMetadata'
import { getDefaultTrackingData, returnIfDefined } from './utils'

export type TrackingFnType<T = void> = (args: {
  pageMetadata: PageMetadata
  extraData: T
  defaultTrackingData: Record<string, unknown>
  queryClient: QueryClient
}) =>
  | {
      event: string
      [key: string]: unknown
    }
  | undefined

export type UseTracking = <T = void>(
  trackingFn: TrackingFnType<T>
) => (extraData: T) => void

const useTracking: UseTracking = (trackingFn) => {
  const queryClient = useQueryClient()

  const enhancedTrackingFunction = useCallback<
    (extraData: Parameters<typeof trackingFn>[0]['extraData']) => void
  >(
    (extraData) => {
      const defaultTrackingData = getDefaultTrackingData(queryClient)

      const customTrackingData = trackingFn({
        pageMetadata: queryClient.getQueryData<PageData>(['page'])
          ?.metadata as PageMetadata,
        extraData,
        defaultTrackingData,
        queryClient,
      })

      if (customTrackingData) {
        const processedCustomTrackingData = Object.entries(
          customTrackingData
        ).reduce((acc, [key, value]) => {
          return { ...acc, ...returnIfDefined({ key, value }) }
        }, {})

        window.eventQueueDataLayer.push({
          ...defaultTrackingData,
          ...processedCustomTrackingData,
        })
      }
    },
    [queryClient, trackingFn]
  )

  return enhancedTrackingFunction
}

export default useTracking
