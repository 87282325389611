import { FunctionComponent, ReactNode } from 'react'
import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { ImageRatios, ImageWidths } from '@components/ResponsiveImageV2/types'
import { TeaserToiCommercialLayoutTemplateProps } from '@widgets/TeaserToiCommercial'

export type ImageProps = TeaserToiCommercialLayoutTemplateProps & {
  children?: ReactNode
  className?: string
}

export const RATIOS_BIG = {
  desktop: '1_1',
  tablet: '3_4',
  mobile: '3_4',
} as const satisfies ImageRatios

export const RATIOS_COMPACT = {
  desktop: '3_2',
  tablet: '1_1',
  mobile: '1_1',
} as const satisfies ImageRatios

export const WIDTHS = {
  desktop: 632,
  tablet: 632,
  mobile: 'viewport-width',
} as const satisfies ImageWidths

const Image: FunctionComponent<ImageProps> = ({
  image,
  layoutTemplate,
  children,
  className,
}) => {
  const { src, alt, crops } = image

  return (
    <ResponsiveImageV2
      src={src}
      alt={alt}
      crops={crops}
      ratios={layoutTemplate === 'big' ? RATIOS_BIG : RATIOS_COMPACT}
      widths={WIDTHS}
      className={className}>
      {children}
    </ResponsiveImageV2>
  )
}

export default Image
