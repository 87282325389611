import { FunctionComponent, RefObject, useCallback } from 'react'
import translate from '@i18n'
import styled, { css } from 'styled-components'
import useIsUserLoggedIn from '@hooks/useIsUserLoggedIn'
import useAuthentication from '@hooks/useAuthentication'
import { useQueryClient } from '@tanstack/react-query'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'

const BlickPlusInfoBoxContent = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey100 },
      },
      spacing: { spacing24 },
    },
  }) => css`
    background-color: ${grey100};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${spacing24};
  `}
`
const Wrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing24 },
    },
  }) => css`
    margin-top: ${spacing24};
    position: relative;
  `}
`

const BlickPlusInfoBoxTitle = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          xlarge: { bundledCSS: headingXLargeCSS },
        },
      },
      spacing: { spacing8 },
    },
  }) => css`
    ${headingXLargeCSS};
    align-self: flex-start;
    margin-bottom: ${spacing8};
  `}
`

const BlickPlusInfoBoxTextContent = styled.div`
  ${({
    theme: {
      typography: {
        bodycopy: {
          medium2: { bundledCSS: bodycopyMedium2CSS },
        },
      },
      spacing: { spacing24 },
    },
  }) => css`
    ${bodycopyMedium2CSS};
    align-self: flex-start;
    margin-bottom: ${spacing24};
  `}
`

const LoginHint = styled.div`
  ${({
    theme: {
      typography: {
        headings: {
          xsmall: { bundledCSS: headingXSmallCSS },
        },
      },
      spacing: { spacing24 },
    },
  }) => css`
    display: flex;
    align-items: center;
    margin-top: ${spacing24};
    ${headingXSmallCSS}
  `}
`

const LoginHintText = styled.span``

const LoginLinkButton = styled.button`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
      typography: {
        headings: {
          xsmall: { bundledCSS: headingXSmallCSS },
        },
      },
    },
  }) => css`
    ${headingXSmallCSS};
    border: 0;
    background-color: transparent;
    cursor: pointer;
    color: ${blickRed};
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  `}
`

const BlickPlusInfoBox: FunctionComponent = () => {
  const loggedIn = useIsUserLoggedIn()
  const { login } = useAuthentication()
  const onLoginClick = useCallback(() => login({ prefix: 'PLUS' }), [login])
  const queryClient = useQueryClient()

  const trackingOnClick = useCallback<TrackingFnType>(
    () => ({
      event: 'plus_comment_click',
      button_label: translate('commenting.blickPlusInfoBox.button'),
    }),
    []
  )

  const handleTrack = useTracking(trackingOnClick)

  const scrollToCliffhanger = useCallback(() => {
    handleTrack()
    const cliffhangerRef = queryClient.getQueryData<
      RefObject<HTMLDivElement | null>
    >(['cliffhanger-widget-ref'])

    if (!cliffhangerRef?.current) {
      return
    }

    try {
      window.scrollTo({
        top: cliffhangerRef.current?.offsetTop,
        left: 0,
        behavior: 'smooth',
      })
    } catch {
      window.scrollTo(0, cliffhangerRef.current?.offsetTop)
    }
  }, [queryClient, handleTrack])

  return (
    <Wrapper>
      <BlickPlusInfoBoxContent>
        <BlickPlusInfoBoxTitle>
          {translate('commenting.blickPlusInfoBox.title')}
        </BlickPlusInfoBoxTitle>
        <BlickPlusInfoBoxTextContent>
          {translate('commenting.blickPlusInfoBox.text')}
        </BlickPlusInfoBoxTextContent>
        <PrimaryCTAButton size="large" onClick={scrollToCliffhanger}>
          {translate('commenting.blickPlusInfoBox.button')}
        </PrimaryCTAButton>
        {!loggedIn && (
          <LoginHint>
            <LoginHintText
              dangerouslySetInnerHTML={{
                __html: translate('commenting.blickPlusInfoBox.loginHint'),
              }}
            />
            <LoginLinkButton onClick={onLoginClick}>
              {translate('commenting.blickPlusInfoBox.loginLinkButton')}
            </LoginLinkButton>
          </LoginHint>
        )}
      </BlickPlusInfoBoxContent>
    </Wrapper>
  )
}

export default BlickPlusInfoBox
