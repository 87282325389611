import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { ResponsiveImageV2Props } from '.'
import styled, { css } from 'styled-components'
import Source from './Source'
import config from '@config'
import { enrichImageUrlWithRatioAndCrop } from './utils'
import { desktopCSS, mobileCSS, tabletCSS } from '@measures/responsive'

export type ExistingProps = Omit<
  ResponsiveImageV2Props,
  'isLazy' | 'Placeholder'
> & {
  Placeholder: Required<ResponsiveImageV2Props>['Placeholder']
}

interface StyledPictureProps {
  hasError: boolean
  hasValidSourceDesktop: boolean
  hasValidSourceTablet: boolean
  hasValidSourceMobile: boolean
}

const {
  backend: { imageUrl: imageBaseUrl },
  image: {
    viewports: {
      desktop: desktopImageConf,
      tablet: tabletImageConf,
      mobile: mobileImageConf,
    },
  },
} = config

const StyledPicture = styled.picture<StyledPictureProps>`
  ${({
    hasError,
    hasValidSourceDesktop,
    hasValidSourceTablet,
    hasValidSourceMobile,
  }) => css`
    ${!hasValidSourceDesktop &&
    desktopCSS(css`
      display: none;
    `)};

    ${!hasValidSourceTablet &&
    tabletCSS(css`
      display: none;
    `)};

    ${!hasValidSourceMobile &&
    mobileCSS(css`
      display: none;
    `)};

    ${hasError &&
    css`
      ${!hasValidSourceDesktop &&
      desktopCSS(css`
        display: none;
      `)};

      ${!hasValidSourceTablet &&
      tabletCSS(css`
        display: none;
      `)};

      ${!hasValidSourceMobile &&
      mobileCSS(css`
        display: none;
      `)};
    `};

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  `};
`

const Existing: FunctionComponent<ExistingProps> = ({
  src,
  alt,
  ratios,
  crops,
  widths,
  loading,
  Placeholder,
  disablePlaceholder,
  className,
  children,
}) => {
  const [loadingState, setLoadingState] = useState<'' | 'loaded' | 'errored'>(
    ''
  )
  const imageUrl = `${imageBaseUrl}${src}`

  const onError = useCallback(() => {
    setLoadingState('errored')
  }, [])

  const onLoad = useCallback(() => {
    setLoadingState('loaded')
  }, [])

  const sourceDesktop = useMemo(() => {
    const desktopRatio = ratios['desktop']
    const desktopCrop = crops[desktopRatio]
    const desktopWidth = widths['desktop']

    if (!desktopCrop) {
      return null
    }

    return (
      <Source
        media={desktopImageConf.mediaQuery}
        url={enrichImageUrlWithRatioAndCrop(
          imageUrl,
          desktopRatio,
          desktopCrop
        )}
        width={desktopWidth}
        onError={onError}
        onLoad={onLoad}
      />
    )
  }, [ratios, crops, widths, imageUrl, onError, onLoad])

  const sourceTablet = useMemo(() => {
    const tabletRatio = ratios['tablet']
    const tabletCrop = crops[tabletRatio]
    const tabletWidth = widths['tablet']

    if (!tabletCrop) {
      return null
    }

    return (
      <Source
        media={tabletImageConf.mediaQuery}
        url={enrichImageUrlWithRatioAndCrop(imageUrl, tabletRatio, tabletCrop)}
        width={tabletWidth}
        onError={onError}
        onLoad={onLoad}
      />
    )
  }, [ratios, crops, widths, imageUrl, onError, onLoad])

  const sourceMobile = useMemo(() => {
    const mobileRatio = ratios['mobile']
    const mobileCrop = crops[mobileRatio]
    const mobileWidth = widths['mobile']

    if (!mobileCrop) {
      return null
    }

    const imageUrlWithRatioAndCrop = enrichImageUrlWithRatioAndCrop(
      imageUrl,
      mobileRatio,
      mobileCrop
    )

    if (mobileWidth !== 'viewport-width') {
      return (
        <Source
          media={mobileImageConf.mediaQuery}
          url={imageUrlWithRatioAndCrop}
          width={mobileWidth}
          onError={onError}
          onLoad={onLoad}
        />
      )
    }

    return (
      <>
        {mobileImageConf.viewportWidthMediaQueries.map(
          ({ mediaQuery, imWidth }) => (
            <Source
              key={mediaQuery}
              media={mediaQuery}
              url={imageUrlWithRatioAndCrop}
              width={imWidth}
            />
          )
        )}
      </>
    )
  }, [ratios, crops, widths, imageUrl, onError, onLoad])

  return (
    <Placeholder
      className={className}
      ratios={ratios}
      isLoaded={loadingState === 'loaded'}
      hasValidSourceDesktop={!!sourceDesktop}
      hasValidSourceTablet={!!sourceTablet}
      hasValidSourceMobile={!!sourceMobile}
      disablePlaceholder={disablePlaceholder}>
      <StyledPicture
        hasError={loadingState === 'errored'}
        hasValidSourceDesktop={!!sourceDesktop}
        hasValidSourceTablet={!!sourceTablet}
        hasValidSourceMobile={!!sourceMobile}>
        {sourceDesktop}
        {sourceTablet}
        {sourceMobile}

        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img alt={alt} loading={loading} />
      </StyledPicture>
      {children}
    </Placeholder>
  )
}

export default Existing
