import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import Name from '@widgets/Author/Common/AuthorsWithoutImages/Name'
import { isLink } from '@widgets/Author/utils'
import { SingleAuthorProps } from '../types'
import BrandLogo from '@widgets/Author/Common/BrandLogo'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

const NameAndRoleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`

const Role = styled.span`
  ${({
    theme: {
      color: {
        tertiary: { grey800: tertiaryGrey800Color },
      },
      typography: {
        subheads: {
          xsmall2: { bundledCSS: xsmall2SubheadsCSS },
        },
      },
      spacing: { spacing4 },
    },
  }) => css`
    ${xsmall2SubheadsCSS};
    color: ${tertiaryGrey800Color};
    margin-top: ${spacing4};
  `}
`

const SingleAuthor: FunctionComponent<SingleAuthorProps> = ({
  name,
  link,
  role,
  type,
  contentOriginImage,
  contentOrigin,
}) => {
  const hasInvertedColors = useHasInvertedColors()

  const isClickable = isLink(link)
  const shouldShowRole = !!role
  const shouldWrap = type === 'basic'
  return (
    <NameAndRoleContainer>
      <Name
        isClickable={isClickable}
        shouldWrap={shouldWrap}
        hasInvertedColors={hasInvertedColors}>
        {name}
      </Name>
      {shouldShowRole && <Role>{role}</Role>}
      {!!contentOriginImage && (
        <BrandLogo contentOrigin={contentOrigin} {...contentOriginImage} />
      )}
    </NameAndRoleContainer>
  )
}

export default SingleAuthor
