import usePageMetadata from '@hooks/usePageMetadata'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  ArticleSchemaImage,
  getAuthorsId,
  getImagesId,
  getPreparedAuthors,
  getPreparedImages,
} from '@utils/schema'
import config from '@config'
import PersonSchema from '@components/Schema/PersonSchema'
import ImageObjectSchema from '@components/Schema/ImageObjectSchema'
import SchemaHTMLElement from '@components/SchemaHTMLElement'

const {
  backend: { baseUrl },
  schema: { organizationId, articleSchemaUrl, videoSchemaUrl },
} = config

type ArticleBodyObject = {
  id: string
  text: string
}

export interface ArticleSchemaData {
  articleBody: ArticleBodyObject[]
  description: string
}

export interface FilteredArticleSchemaImage extends ArticleSchemaImage {
  url: string
}

const ArticleSchema: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const unsubscribeFnRef = useRef<() => void>(undefined)
  const queryCache = queryClient.getQueryCache()
  const [description, setDescription] = useState<string>('')
  const pageMetadata = usePageMetadata()

  const updateArticleSchema = useCallback(
    (args: any) => {
      if (
        args.query.queryKey[0] === 'articleSchemaData' &&
        args.type === 'updated'
      ) {
        const data = queryClient.getQueryData<ArticleSchemaData>([
          'articleSchemaData',
        ])

        if (data?.description) {
          setDescription(data.description)
        }
      }
    },
    [queryClient]
  )

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(updateArticleSchema)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, updateArticleSchema])

  const {
    htmlTitle,
    title,
    url,
    authors,
    lastModifiedDate,
    publishedDate,
    schema,
    isPlus,
    id: articleId,
    adChannel,
    hasVideo,
  } = pageMetadata
  const dateModified = lastModifiedDate
    ? new Date(lastModifiedDate).toISOString()
    : ''
  const datePublished = publishedDate
    ? new Date(publishedDate).toISOString()
    : ''

  const preparedAuthors = useMemo(() => getPreparedAuthors(authors), [authors])

  const authorsId = useMemo(
    () => getAuthorsId(preparedAuthors),
    [preparedAuthors]
  )

  const imageSet = useMemo(
    () => getPreparedImages(schema?.image),
    [schema?.image]
  )

  const imageIds = useMemo(() => getImagesId(imageSet), [imageSet])
  const teaserVideoId = schema?.video?.videoId

  return (
    <>
      {!!preparedAuthors.length &&
        preparedAuthors?.map((author) => (
          <PersonSchema key={author.name} {...author} />
        ))}
      {!!imageSet.length &&
        imageSet.map((image) => (
          <ImageObjectSchema key={image.url} {...image} />
        ))}
      <SchemaHTMLElement
        scriptKey="article"
        schemaObject={{
          '@type': 'NewsArticle',
          id: `${articleSchemaUrl}${articleId}`,
          headline: htmlTitle || title,
          description,
          isPartOf: {
            '@id': `${baseUrl}${url}`,
          },
          mainEntityOfPage: {
            '@id': `${baseUrl}${url}`,
          },
          dateModified,
          datePublished,
          articleSection: adChannel,
          ...(authorsId.length ? { author: authorsId } : {}),
          publisher: {
            '@id': organizationId,
          },
          ...(imageIds.length ? { image: imageIds } : {}),
          isAccessibleForFree: isPlus ? 'False' : 'True',
          ...(hasVideo && teaserVideoId
            ? { video: [{ '@id': `${videoSchemaUrl}${teaserVideoId}` }] }
            : {}),
          ...(isPlus
            ? {
                hasPart: {
                  '@type': 'WebPageElement',
                  isAccessibleForFree: 'False',
                  cssSelector: '.paywallContent',
                },
              }
            : {}),
        }}
      />
    </>
  )
}

export default ArticleSchema
