import { ConfigProps } from '@config/types'

const config = ({}: ConfigProps) =>
  ({
    clientOnlyURLParamsCommonKey: 'url-param',
    clientOnlyURLParams: [
      { name: 'mlp' },
      { name: 'q' },
      { name: 'token' },
      { name: 'sharingPlus' },
      { name: 'blickmk', includeInDataLayer: true },
      { name: 'ifbanner', includeInDataLayer: true },
      { name: 'eaq' },
    ],
  }) as const satisfies Record<string, unknown>

export default config
