import useExecuteOnClientNavigation from '@hooks/useExecuteOnClientNavigation'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useRef, useState } from 'react'

const useChatbotVisible = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>(undefined)

  const [chatbotVisible, setChatbotVisible] = useState(() =>
    queryClient.getQueryData<boolean>(['chatbotVisible'])
  )

  const resetChatbotVisible = useCallback(() => {
    setChatbotVisible(!!queryClient.getQueryData<boolean>(['chatbotVisible']))
  }, [queryClient])

  const updateChatbotVisible = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === 'chatbotVisible'
      ) {
        setChatbotVisible(
          !!queryClient.getQueryData<boolean>(['chatbotVisible'])
        )
      }
    },
    [queryClient]
  )

  useExecuteOnClientNavigation(resetChatbotVisible)

  useEffect(() => {
    setChatbotVisible(!!queryClient.getQueryData<boolean>(['chatbotVisible']))

    unsubscribeFnRef.current = queryCache.subscribe(updateChatbotVisible)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, updateChatbotVisible, queryClient])

  return chatbotVisible
}

export default useChatbotVisible
