import { RefObject, useEffect, useMemo } from 'react'
import debounce from 'lodash.debounce'

type useOnScrollType = (props: {
  track?: boolean
  delay?: number
  elementRef?: RefObject<Element | typeof window | null>
  onScroll?: () => void
}) => void

const useOnScroll: useOnScrollType = ({
  track = true,
  delay = 100,
  elementRef,
  onScroll,
}) => {
  const onScrollHandler = useMemo(
    () => (track && onScroll ? debounce(onScroll, delay) : undefined),
    [track, onScroll, delay]
  )

  useEffect(() => {
    // cleanup function
    return () => {
      onScrollHandler?.cancel()
    }
  }, [onScrollHandler])

  useEffect(() => {
    const element = elementRef?.current
    const shouldTrack = track && element && !!onScrollHandler

    if (shouldTrack) {
      element.addEventListener('scroll', onScrollHandler)
    }
    return () => {
      if (shouldTrack) {
        element.removeEventListener('scroll', onScrollHandler)
      }
    }
  }, [onScrollHandler, elementRef, track])
}

export default useOnScroll
