import { RefObject, useCallback, useEffect } from 'react'
import { getArrowsVisibility, getCurrentIndex } from './utils'
import { ArrowsVisibilityType } from './types'
import useOnScroll from '@hooks/useOnScroll'
import useOnResizeObserver from '@hooks/useOnResizeObserver'

export type UseSwiperArrowsType = (props: {
  enabled?: boolean
  vertical?: boolean
  itemsContainerRef?: RefObject<HTMLDivElement | null>
  itemsCount?: number
  onArrowsVisibility?: (
    arrow: ArrowsVisibilityType,
    currentIndex: number
  ) => void
}) => { triggerArrowsVisibility: () => void }

export type { ArrowsVisibilityType } from './types'

const useSwiperArrows: UseSwiperArrowsType = ({
  enabled = true,
  vertical = false,
  itemsContainerRef,
  itemsCount,
  onArrowsVisibility,
}) => {
  const isEnabled = enabled && !!onArrowsVisibility

  const triggerArrowsVisibility = useCallback(() => {
    if (itemsContainerRef?.current && onArrowsVisibility) {
      onArrowsVisibility(
        getArrowsVisibility(itemsContainerRef.current, vertical),
        getCurrentIndex('next', vertical, itemsContainerRef.current)
      )
    }
  }, [itemsContainerRef, onArrowsVisibility, vertical])

  useOnScroll({
    track: isEnabled,
    elementRef: itemsContainerRef,
    onScroll: triggerArrowsVisibility,
  })

  useOnResizeObserver({
    track: isEnabled,
    elementRef: itemsContainerRef,
    onResize: triggerArrowsVisibility,
  })

  useEffect(() => {
    if (itemsCount) {
      triggerArrowsVisibility()
    }
  }, [itemsCount, triggerArrowsVisibility])

  return { triggerArrowsVisibility }
}

export default useSwiperArrows
