import { FunctionComponent, ReactNode } from 'react'
import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { ImageRatios, ImageWidths } from '@components/ResponsiveImageV2/types'
import { TeaserBiteAPIProps } from '@widgets/TeaserBite'

export type ImageProps = TeaserBiteAPIProps['image'] & {
  className?: string
  children?: ReactNode
}

export const RATIOS = {
  mobile: '9_16',
  tablet: '9_16',
  desktop: '9_16',
} as const satisfies ImageRatios

export const WIDTHS = {
  desktop: 217,
  tablet: 217,
  mobile: 137,
} as const satisfies ImageWidths

const Image: FunctionComponent<ImageProps> = ({
  src,
  alt,
  crops,
  className,
  children,
}) => {
  return (
    <ResponsiveImageV2
      src={src}
      alt={alt}
      crops={crops}
      ratios={RATIOS}
      widths={WIDTHS}
      className={className}>
      {children}
    </ResponsiveImageV2>
  )
}

export default Image
