import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import usePageMetadata, { PageMetadata } from '@hooks/usePageMetadata'
import { useCallback, useEffect, useRef, useState } from 'react'
import { APICommentingData } from '@widgets/Commenting/types'

const useCommentsNumber = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const pageMetadata = usePageMetadata()

  const getCommentsNumberFromCache = useCallback<
    (articleId: PageMetadata['articleId']) => number
  >(
    (articleId) => {
      const data = queryClient.getQueryData<InfiniteData<APICommentingData>>([
        'commentingData',
        articleId,
      ])

      return data?.pages?.[0]?.number_of_comments ?? 0
    },
    [queryClient]
  )

  const { id: articleId } = pageMetadata
  const [commentsNumber, setCommentsNumber] = useState<number>(() =>
    getCommentsNumberFromCache(articleId)
  )
  const unsubscribeFnRef = useRef<() => void>(undefined)

  const updateCommentsNumberFromCache = useCallback<
    (articleId: PageMetadata['articleId']) => void
  >(
    (articleId) => {
      const numberOfComments = getCommentsNumberFromCache(articleId)
      setCommentsNumber(numberOfComments)
    },
    [getCommentsNumberFromCache]
  )

  const updateCommentsNumberData = useCallback<
    (articleId: PageMetadata['articleId']) => (args: any) => void
  >(
    (articleId) => (args: any) => {
      if (
        args.type === 'updated' &&
        args.query.queryKey?.[0] === 'commentingData' &&
        args.query.queryKey?.[1] === articleId
      ) {
        updateCommentsNumberFromCache(articleId)
      }
    },
    [updateCommentsNumberFromCache]
  )

  useEffect(() => {
    updateCommentsNumberFromCache(articleId)

    unsubscribeFnRef.current = queryCache.subscribe(
      updateCommentsNumberData(articleId)
    )

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [
    articleId,
    queryCache,
    updateCommentsNumberData,
    updateCommentsNumberFromCache,
  ])

  return commentsNumber
}

export default useCommentsNumber
