import { useQueryClient } from '@tanstack/react-query'
import { useState, useCallback, useEffect, useRef } from 'react'
import { VideoRefType } from '@widgets/Video/types'

type UseVideoRefType = (args: { widgetId: string }) => VideoRefType | undefined

const getVideoRefByWidgetId = (
  videoRefs: VideoRefType[] | undefined,
  widgetId: string
): VideoRefType | undefined =>
  videoRefs?.find((videoRef) => videoRef.widgetId === widgetId)

const useVideoRef: UseVideoRefType = ({ widgetId }) => {
  const queryClient = useQueryClient()
  const unsubscribeFnRef = useRef<() => void>(undefined)
  const queryCache = queryClient.getQueryCache()

  const [videoRef, setVideoRef] = useState<VideoRefType | undefined>(() =>
    getVideoRefByWidgetId(
      queryClient.getQueryData<VideoRefType[]>(['video-refs']),
      widgetId
    )
  )

  const updateVideoRefValue = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'video-refs'
      ) {
        setVideoRef(() =>
          getVideoRefByWidgetId(
            queryClient.getQueryData<VideoRefType[]>(['video-refs']),
            widgetId
          )
        )
      }
    },
    [queryClient, widgetId]
  )

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(updateVideoRefValue)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, updateVideoRefValue])

  return videoRef
}

export default useVideoRef
