import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import config from '@config'

import ReactJWPlayer from '@components/Video/VideoPlayer/JwLibrary/lib'
import { JWPlayer } from '@components/Video/VideoPlayer/JwLibrary/types'
import ErrorOverlay from '@components/Video/VideoPlayer/JwLibrary/components/Errors'

const { jwplayer: jwplayerConfig } = config

type BlickBitesVideoPlayerProps = {
  widgetId: string
  videoId?: string
  className?: string
  onInit?: (player: JWPlayer) => void
  onRemove?: () => void
  onError?: (error: jwplayer.ErrorParam) => void
}

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const StyledReactJWPlayer = styled(ReactJWPlayer)`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
    },
  }) => css`
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${primary01Color};

    display: grid;

    && .jw-preview {
      display: none;
    }

    && .jw-title {
      display: none;
    }
  `}
`

const BlickBitesVideoPlayer: FunctionComponent<BlickBitesVideoPlayerProps> = ({
  widgetId,
  className,
  videoId,
  onInit,
  onRemove,
  onError,
}) => {
  const [setupFinished, setSetupFinished] = useState(false)
  const playerRef = useRef<JWPlayer>(undefined)

  const [playerError, setPlayerError] = useState<
    jwplayer.ErrorParam | undefined
  >()

  const [initialPlaylist, setInitialPlaylist] = useState<string | undefined>(
    () => (videoId ? `${jwplayerConfig.baseUrl.media}${videoId}` : undefined)
  )

  const errorHandler = useCallback(
    (error: jwplayer.ErrorParam) => {
      setPlayerError(error)

      onError?.(error)
    },
    [onError]
  )

  const onReloadHandler = useCallback(() => {
    window.location.reload()
  }, [])

  const onSetup = useCallback(
    (playerInstance: JWPlayer) => {
      playerRef.current = playerInstance

      setSetupFinished(true)
      onInit?.(playerInstance)
    },
    [onInit]
  )

  useEffect(() => {
    const player = playerRef.current

    if (!setupFinished || !player) {
      return
    }

    player.on('error', errorHandler)
    player.on('setupError', errorHandler)

    return () => {
      player.off('error', errorHandler)
      player.off('setupError', errorHandler)
    }
  }, [setupFinished, errorHandler])

  const onPlaylistItem = useCallback(() => {
    const player = playerRef.current

    if (player) {
      // disable internal JWPlayer captions
      player.setCurrentCaptions(0)

      // autoplay video when playlist is loaded
      player.play()
    }
  }, [])

  useEffect(() => {
    const player = playerRef.current

    if (!setupFinished || !player) {
      return
    }

    player.on('playlistItem', onPlaylistItem)

    return () => {
      player.off('playlistItem', onPlaylistItem)
    }
  }, [setupFinished, onPlaylistItem])

  useEffect(() => {
    const player = playerRef.current

    if (!setupFinished || !player || !onRemove) {
      return
    }

    player.on('remove', onRemove)

    return () => {
      player.off('remove', onRemove)
    }
  }, [setupFinished, onRemove])

  useEffect(() => {
    if (videoId) {
      setInitialPlaylist(
        (initialPlaylist) =>
          initialPlaylist || `${jwplayerConfig.baseUrl.media}${videoId}`
      )
    }
  }, [videoId])

  useEffect(() => {
    const playlist = playerRef.current?.getConfig().playlist
    const newPlaylist = `${jwplayerConfig.baseUrl.media}${videoId}`

    // load new playlist if player setup is finished new playlist is different from the initial one
    if (setupFinished && playerRef.current && newPlaylist !== playlist) {
      setPlayerError(undefined)
      playerRef.current?.setConfig({ playlist: newPlaylist })
      playerRef.current.load(newPlaylist)
    }
  }, [setupFinished, videoId])

  useEffect(() => {
    return () => {
      playerRef.current?.remove()
    }
  }, [])

  useEffect(() => {
    if (setupFinished && playerRef.current) {
      playerRef.current.setPlaylistItemCallback?.((item) => {
        return new Promise((resolve) => {
          resolve({
            ...item,
            image: undefined,
            images: [],
            link: undefined,
          })
        })
      })
    }
  }, [setupFinished])

  useEffect(() => {
    const player = playerRef.current

    if (setupFinished && player) {
      player.setCurrentCaptions(0)
    }
  }, [setupFinished])

  if (!initialPlaylist) {
    return null
  }

  return (
    <VideoWrapper>
      {playerError ? (
        <ErrorOverlay
          errorCode={playerError?.code} // isVideoGeoblocked ? 232403 : playerError?.code
          onReload={onReloadHandler}
        />
      ) : null}
      <StyledReactJWPlayer
        preload="auto"
        widgetId={widgetId}
        mute={true}
        controls={false}
        className={className}
        playlist={initialPlaylist}
        onSetup={onSetup}
        aspectratio="9:16"
        stretching="uniform"
        width="100%"
        displaytitle={false}
        pipIcon="disabled"
        allowFullscreen={false}
        renderCaptionsNatively={false}
        repeat={true}
        autoPause={{
          viewability: false,
        }}
        autostart={true}
      />
    </VideoWrapper>
  )
}

export default memo(BlickBitesVideoPlayer)
