import config from '@config'
import { AvailableImageCrops, Image } from '@utils/cook/types'
import { CookWidgetJSON } from '@widgets/types'

export interface CueLivePostIcon {
  image: Required<Pick<Image<AvailableImageCrops>, 'src' | 'width' | 'height'>>
  label: string
}

type CueLiveEvent = {
  action?: 'goal'
  kind?: string
}

export type CueLivePost = {
  id: number
  type?: undefined
  eventId: number
  eTag?: string
  values: CookWidgetJSON[]
  publishedAt: number
  icon?: CueLivePostIcon
  event?: CueLiveEvent
}

export type CueLiveAdPost = {
  id: string
  type: 'ad'
  publishedAt: number
}

export type CueLiveTeaserPost = Pick<
  CueLivePost,
  'id' | 'eventId' | 'publishedAt'
> & { title: string }

export type CueLiveSchemaPost = CueLiveTeaserPost & { body: string }

export type CueLiveData = {
  sticky: CueLivePost[]
  entries: CueLivePost[]
  previousCursor?: string
  afterCursor?: string
  newest: string
  oldest: string
  refreshRate: number
}

export type CueLiveTeaserData = Pick<CueLiveData, 'refreshRate'> & {
  entries: CueLiveTeaserPost[]
}

export type CueLiveSchemaData = Pick<CueLiveData, 'refreshRate'> & {
  entries: CueLiveSchemaPost[]
}

export type FetchCueLive = (
  cueLiveId: string,
  options?: {
    limit?: number
    before?: string
    after?: string
  },
  pageUrl?: string
) => Promise<CueLiveData>

export type FetchCueLiveTeaser = (
  cueLiveId: string
) => Promise<CueLiveTeaserData>

export type FetchCueLiveSchemaData = (
  cueLiveId: string
) => Promise<CueLiveSchemaData>

const {
  backend: { cueLiveEventBaseUrl, cueLiveTeaserEventBaseUrl, baseUrl },
} = config

const isInServerBundle = typeof window === 'undefined'

const fetchCueLive: FetchCueLive = async (
  cueLiveId,
  options = { limit: 10 },
  pageUrl = undefined
) => {
  const requestUrl = new URL(`${cueLiveEventBaseUrl}${cueLiveId}`)

  Object.entries(options ?? {}).forEach(([key, value]) =>
    requestUrl.searchParams.append(key, `${value}`)
  )

  return (
    await fetch(
      requestUrl,
      isInServerBundle
        ? { headers: { 'User-Agent': 'next', Referer: `${baseUrl}${pageUrl}` } }
        : {}
    )
  ).json()
}

const fetchCueLiveTeaser: FetchCueLiveTeaser = async (cueLiveId) => {
  const requestUrl = new URL(`${cueLiveTeaserEventBaseUrl}${cueLiveId}`)

  return (
    await fetch(
      requestUrl,
      isInServerBundle ? { headers: { 'User-Agent': 'next' } } : {}
    )
  ).json()
}

const fetchCueLiveSchemaData: FetchCueLiveSchemaData = async (cueLiveId) => {
  const requestUrl = new URL(
    `${cueLiveTeaserEventBaseUrl}${cueLiveId}?limit=10&includeBody=true`
  )

  return (
    await fetch(
      requestUrl,
      isInServerBundle ? { headers: { 'User-Agent': 'next' } } : {}
    )
  ).json()
}

export { fetchCueLive, fetchCueLiveTeaser, fetchCueLiveSchemaData }
