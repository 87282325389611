import { FunctionComponent } from 'react'
import HybridTeaserContext from '@contexts/hybridTeaser'
import JSONRenderer from '@components/JSONRenderer'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { TeaserStandardHorizontalAPIProps } from '@widgets/TeaserStandardHorizontal'
import { TeaserStandardVerticalAPIProps } from '@widgets/TeaserStandardVertical'
import useShowTeaserCatchwordVariant from '@hooks/useShowTeaserCatchwordVariant'

export type SpecialHybridProps = {
  render?: 'vertical' | 'horizontal' | 'vertical-mobile'
  abTestCandidate?: boolean
}

export type TeaserStandardHybridAPIProps = TeaserStandardVerticalAPIProps &
  TeaserStandardHorizontalAPIProps &
  SpecialHybridProps

const TeaserStandardHybrid: FunctionComponent<TeaserStandardHybridAPIProps> = ({
  render,
  abTestCandidate,
  ...apiProps
}) => {
  const showTeaserCatchwordVariant = useShowTeaserCatchwordVariant()

  const teaserStandardVerticalProps = {
    ...apiProps,
    kind: ['teaser-standard-vertical'],
  }

  const teaserStandardHorizontalProps = {
    ...apiProps,
    kind: ['teaser-standard-horizontal'],
    ...(abTestCandidate && !showTeaserCatchwordVariant
      ? { abTestCandidate }
      : {}),
    ...(abTestCandidate && showTeaserCatchwordVariant
      ? { isSpecialABRender: true, kind: ['teaser-standard-vertical'] }
      : {}),
  }

  return (
    <HybridTeaserContext.Provider value={true}>
      <JSONRenderer>
        {render === 'vertical'
          ? teaserStandardVerticalProps
          : render === 'horizontal'
            ? teaserStandardHorizontalProps
            : [teaserStandardVerticalProps, teaserStandardHorizontalProps]}
      </JSONRenderer>
    </HybridTeaserContext.Provider>
  )
}

const widget = {
  kind: ['teaser-standard-hybrid'],
  component: TeaserStandardHybrid,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
