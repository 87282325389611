import { FunctionComponent, ReactNode } from 'react'
import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { ImageRatios, ImageWidths } from '@components/ResponsiveImageV2/types'
import { TeaserToiVideoAPIProps } from '@widgets/TeaserToiVideo'

export type ImageProps = TeaserToiVideoAPIProps['image'] & {
  children?: ReactNode
  className?: string
}

export const RATIOS = {
  desktop: '16_9',
  tablet: '16_9',
  mobile: '16_9',
} as const satisfies ImageRatios

export const WIDTHS = {
  desktop: 358,
  tablet: 200,
  mobile: 200,
} as const satisfies ImageWidths

const Image: FunctionComponent<ImageProps> = ({
  src,
  alt,
  crops,
  children,
  className,
}) => (
  <ResponsiveImageV2
    src={src}
    alt={alt}
    crops={crops}
    ratios={RATIOS}
    widths={WIDTHS}
    className={className}>
    {children}
  </ResponsiveImageV2>
)

export default Image
