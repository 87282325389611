import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

export type HasJWPlayerScriptLoadedType = boolean

export type UseHasJWPlayerScriptLoaded = () => HasJWPlayerScriptLoadedType

const useHasJWPlayerScriptLoaded: UseHasJWPlayerScriptLoaded = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>(undefined)

  const [hasJWPlayerScriptLoaded, setHasJWPlayerScriptLoaded] =
    useState<boolean>(
      () =>
        !!queryClient.getQueryData<HasJWPlayerScriptLoadedType>([
          'has-jwplayer-script-loaded',
        ])
    )

  const updateHasJWPlayerScriptLoaded = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === 'has-jwplayer-script-loaded'
      ) {
        setHasJWPlayerScriptLoaded(
          !!queryClient.getQueryData<HasJWPlayerScriptLoadedType>([
            'has-jwplayer-script-loaded',
          ])
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    setHasJWPlayerScriptLoaded(
      !!queryClient.getQueryData<HasJWPlayerScriptLoadedType>([
        'has-jwplayer-script-loaded',
      ])
    )

    unsubscribeFnRef.current = queryCache.subscribe(
      updateHasJWPlayerScriptLoaded
    )

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, queryClient, updateHasJWPlayerScriptLoaded])

  useEffect(() => {
    queryClient
      .getQueryData<{
        load?: () => void
      }>(['trigger-jwplayer-script-loading-func'])
      ?.load?.()
  }, [queryClient])

  return hasJWPlayerScriptLoaded
}

export default useHasJWPlayerScriptLoaded
