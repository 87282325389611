import config from '@config'
import usePageMetadata, {
  Breadcrumbs,
  PageData,
  PageMetadata,
} from '@hooks/usePageMetadata'
import {
  areAdSlotsLoaded,
  deepCopy,
  getPageType,
  loadSlots,
  logAd,
  updateAreAdSlotsLoaded,
} from '@utils/ads'
import { getCookieValue } from '@utils/cookie'
import Script from 'next/script'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { User } from '@hooks/useUser'
import useSubscriptionStatus, {
  SubscriptionStatus,
} from '@hooks/useSubscriptionStatus'
import { getCurrentViewportType } from '@measures/responsive'
import useViewportType from '@hooks/useViewport/useViewportType'
import nanoid from '@utils/random'
import useIsPianoSDKLoaded from '@hooks/useIsPianoSDKLoaded'
import useHasPianoSDKErrored from '@hooks/useHasPianoSDKErrored'

const {
  ads: {
    sdk: {
      fallbackChannel,
      articleIdKeyName: adSdkArticleIdKeyName,
      channelMapping,
      publisherId,
    },
  },
} = config

const getChannel = (breadcrumbs: Breadcrumbs = []): string => {
  // If breadcrumbs are missing, return fallback channel
  if (!(breadcrumbs?.length > 0)) {
    return fallbackChannel
  } else if (breadcrumbs.length === 1) {
    // Catch if we are on home section
    return breadcrumbs[0].title
  } else {
    // Calculate the channel
    return [...breadcrumbs].reverse().reduce((acc, breadcrumb) => {
      const channel =
        channelMapping[breadcrumb.title as keyof typeof channelMapping]
      return channel && acc === fallbackChannel ? channel : acc
    }, fallbackChannel as string)
  }
}

const initAds = ({
  pageMetadata,
  isUserLoggedIn,
  currentSubscriptionStatus,
  userEmail,
  queryClient,
}: {
  pageMetadata: PageMetadata
  isUserLoggedIn: boolean
  currentSubscriptionStatus: SubscriptionStatus
  userEmail: string | undefined
  queryClient: QueryClient
}) => {
  const {
    id,
    breadcrumbs,
    type,
    sectionIds,
    adChannel,
    syndication,
    targetContentType: contentType,
  } = pageMetadata
  const shouldAdmForce = getCookieValue('BLICKFORCEADS')

  let olid = 0

  if (currentSubscriptionStatus === 'subscribed') {
    olid = 3
  } else if (isUserLoggedIn) {
    olid = 2
  }

  const initObj = {
    platform: getCurrentViewportType() !== 'desktop' ? 'MobileWeb' : 'Desktop',
    channel: adChannel ?? getChannel(breadcrumbs),
    targeting: {
      pagetype: getPageType(type),
      ...(id ? { [adSdkArticleIdKeyName]: id } : {}),
      ...(contentType ? { contenttype: contentType } : {}),
      ...(syndication ? { syndication } : {}),
      subsectionid: sectionIds,
      ...(shouldAdmForce ? { admforce: 'qa' } : {}),
      olid,
    },
    ...(userEmail ? { oneId: userEmail } : {}),
  }

  const deepCopiedInitObj = deepCopy(initObj)

  window.admTagMan.q.push((admTagMan) => {
    admTagMan.init(initObj)
  })

  logAd(
    { queryClient },
    `SDK %cInitialized\n${' '.repeat(5)}%cconfig:`,
    'font-weight: bold;',
    '',
    deepCopiedInitObj
  )
}

const AdManager: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const { subscriptionStatus, getCurrentSubscriptionStatus } =
    useSubscriptionStatus()
  const [isAdSDKLoaded, setIsAdSDKLoaded] = useState<boolean>(false)
  const viewportType = useViewportType()
  const [adViewport, setAdViewport] = useState<
    'desktop' | 'tablet' | 'mobile' | ''
  >('')

  const pageMetadata = usePageMetadata()

  const onAdSDKLoaded = useCallback(() => {
    logAd({ queryClient }, '%cSDK LOADED', 'font-weight: bold; color: #2E8B57;')
    queryClient.setQueryData(['RiAdTagManagerScriptLoaded'], true)
    queryClient.invalidateQueries({
      queryKey: ['RiAdTagManagerScriptLoaded'],
      exact: true,
    })
    setIsAdSDKLoaded(true)
  }, [queryClient])

  const onAdSDKErrored = useCallback(() => {
    logAd(
      { queryClient, method: 'warn' },
      '%cSDK FAILED TO LOAD: Script fetch error',
      'font-weight: bold; color: #B22222;'
    )
    queryClient.setQueryData(['RiAdTagManagerScriptError'], true)
    queryClient.invalidateQueries({
      queryKey: ['RiAdTagManagerScriptError'],
      exact: true,
    })
  }, [queryClient])

  const triggerAdRegistration = useCallback(() => {
    const randomCode = nanoid()
    const isHerotelling =
      (
        queryClient.getQueryData<PageData>(['page'])
          ?.pageMetadata as PageMetadata
      )?.targetContentSubType === 'hero'

    logAd(
      { queryClient },
      `Initialization Code Generated: %c${randomCode}\n${' '.repeat(5)}%cNow all the ad slots present will (re-)register.`,
      'font-weight: bold;',
      'font-weight: bold; color: #FFD700;'
    )

    queryClient.setQueryData(['RiAdTagManagerInitCode'], randomCode)
    queryClient.invalidateQueries({
      queryKey: ['RiAdTagManagerInitCode'],
      exact: true,
    })

    if (isHerotelling) {
      loadSlots(queryClient)
      logAd(
        { queryClient },
        `%cForced loaded slots immediately because of Herotelling\n${' '.repeat(5)}%cSlots that were registered until this point will now be batch loaded.\n${' '.repeat(5)}%cAds registered after this point in time will have to be loaded manually.`,
        'font-weight: bold; color: #FFD700;',
        '',
        'font-weight: bold; color: #FFA500;'
      )
    } else {
      setTimeout(() => {
        if (!areAdSlotsLoaded(queryClient)) {
          loadSlots(queryClient)
          logAd(
            { queryClient },
            `%cForced loaded slots due to timeout\n${' '.repeat(5)}%cSlots that were registered until this point will now be batch loaded.\n${' '.repeat(5)}%cAds registered after this point in time will have to be loaded manually.`,
            'font-weight: bold; color: #FFD700;',
            '',
            'font-weight: bold; color: #FFA500;'
          )
        }
      }, 300)
    }
  }, [queryClient])

  const resetAreAdSlotsLoaded = useCallback<
    (pageMetadataValue: PageMetadata) => void
  >(
    (pageMetadataValue) => {
      const user = queryClient.getQueryData<User>(['user'])
      const isUserLoggedIn = !!user
      const currentSubscriptionStatus = getCurrentSubscriptionStatus()

      updateAreAdSlotsLoaded(queryClient, false)
      initAds({
        pageMetadata: pageMetadataValue,
        isUserLoggedIn,
        currentSubscriptionStatus,
        userEmail: user?.email,
        queryClient,
      })
      triggerAdRegistration()
    },
    [getCurrentSubscriptionStatus, queryClient, triggerAdRegistration]
  )

  useEffect(() => {
    setAdViewport(getCurrentViewportType())

    //! We need the 'viewportType` dependency so that we re-render
    //! the component (even if it's not explicitly used)
  }, [viewportType])

  useEffect(() => {
    if (adViewport && isAdSDKLoaded && subscriptionStatus) {
      resetAreAdSlotsLoaded(pageMetadata)
    }
  }, [
    adViewport,
    pageMetadata,
    resetAreAdSlotsLoaded,
    isAdSDKLoaded,
    subscriptionStatus,
  ])

  const atmEnv = queryClient.getQueryData<string>(['atmEnv']) || ''
  const atmVersion = queryClient.getQueryData<string>(['atmVersion']) || ''

  if (!atmEnv) {
    logAd(
      { queryClient, method: 'warn' },
      `%cSDK FAILED TO LOAD: Missing atmEnv`,
      'font-weight: bold; color: #B22222;'
    )
  }

  if (!atmVersion) {
    logAd(
      { queryClient, method: 'warn' },
      `%cSDK FAILED TO LOAD: Missing atmVersion`,
      'font-weight: bold; color: #B22222;'
    )
  }

  return [useHasPianoSDKErrored(), useIsPianoSDKLoaded()].some(Boolean) &&
    atmEnv &&
    atmVersion ? (
    <Script
      id="adm-script"
      strategy="afterInteractive"
      defer={true}
      src={`https://cdn.ringier-advertising.ch/${atmEnv}/tagmanager/${publisherId}/${atmVersion}/atm.js`}
      onLoad={onAdSDKLoaded}
      onError={onAdSDKErrored}
    />
  ) : null
}

export default AdManager
