import { ConfigProps } from '@config/types'

const config = ({ publication }: ConfigProps) => {
  const GA4CookieNameSegment =
    publication === 'romandie' ? 'NKTZ1Z6QY0' : '0E04YP6LZS'

  return {
    gtmId: 'GTM-T3MW3GR',
    GACookieName: '_ga',
    GA4CookieNameSegment,
    GA4CookieName: `_ga_${GA4CookieNameSegment}`,
    //! The persistentTrackingFields are fields should *not* be flushed when not explicitly used.
    persistentTrackingFields: ['experimentVariant'],
    metadataFields: [
      'articleFeatures',
      'author',
      'context',
      'contextId',
      'creator',
      'firstPublishedDate',
      'hasLivescore',
      'hasLivestream',
      'hasLiveticker',
      'hasPlaylist',
      'id',
      'lastModifiedDate',
      'sectionUniqueNames',
      'tags',
      'teaserTitle',
      'thirdPartyId',
      'title',
      'isPlus',
      'targetContentType',
    ],
    sectionsToBeTrackedOnHomePage:
      publication === 'romandie'
        ? ([] as const)
        : ([
            'videos',
            'sport',
            'people',
            'sport videos',
            'spiele & rätsel',
            'wettbewerbe',
          ] as const),
  } as const satisfies Record<string, unknown>
}

export default config
