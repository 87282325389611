import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'

interface ListicleProps {
  titleNumber?: string
  titleText: string
}

const StyledWrapper = styled.div`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
      spacing: { spacing2, spacing4, spacing16, spacing24 },
    },
  }) => css`
    display: grid;
    grid-template-rows: auto auto;
    border-left: ${spacing2} solid ${blickRedColor};
    padding-left: ${spacing16};
    ${desktopCSS(css`
      border-left: ${spacing4} solid ${blickRedColor};
      padding-left: ${spacing24};
    `)}
  `}
`

const StyledText = styled.h3`
  ${({
    theme: {
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingCSS },
        },
      },
    },
  }) => css`
    ${smallHeadingCSS};
    text-transform: uppercase;
    align-self: flex-end;
  `}
`

const StyledNumber = styled.span`
  ${({
    theme: {
      color: {
        primary: { blickRed: blickRedColor },
      },
      typography: {
        headings: {
          xxlarge: { bundledCSS: xxlargeHeadingCSS },
        },
      },
    },
  }) => css`
    ${xxlargeHeadingCSS};
    color: ${blickRedColor};
    align-self: flex-start;
  `}
`

const Listicle: FunctionComponent<ListicleProps> = ({
  titleNumber,
  titleText,
}) => {
  return (
    <StyledWrapper>
      {!!titleNumber && <StyledNumber>{titleNumber}</StyledNumber>}
      <StyledText>{titleText}</StyledText>
    </StyledWrapper>
  )
}

const widget = {
  kind: ['widget', 'listicle-title'],
  component: Listicle,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
