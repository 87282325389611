import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

type UseCMPHasUserInteracted = () => boolean

const useCMPHasUserInteracted: UseCMPHasUserInteracted = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()

  const [CMPHasUserInteracted, setCMPHasUserInteracted] = useState<boolean>(
    () => {
      return !!queryClient.getQueryData<boolean>(['cmp-has-user-interacted'])
    }
  )

  const unsubscribeFnRef = useRef<() => void>(undefined)

  const updateCMPHasUserInteracted = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'cmp-has-user-interacted'
      ) {
        const CMPHasUserInteracted = !!queryClient.getQueryData<boolean>([
          'cmp-has-user-interacted',
        ])

        if (CMPHasUserInteracted) {
          setCMPHasUserInteracted(true)
        }
      }
    },
    [queryClient]
  )

  useEffect(() => {
    const CMPHasUserInteracted = !!queryClient.getQueryData<boolean>([
      'cmp-has-user-interacted',
    ])
    if (CMPHasUserInteracted) {
      setCMPHasUserInteracted(true)
    }

    unsubscribeFnRef.current = queryCache.subscribe(updateCMPHasUserInteracted)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [updateCMPHasUserInteracted, queryCache, queryClient])

  return CMPHasUserInteracted
}

export default useCMPHasUserInteracted
