import { RefObject } from 'react'
import { JWPlayer, BlickTVInputProps } from '../types'
import {
  removeRecommendationsUnavailableButton,
  removeActivateRecommendationsButton,
  addDeactivateRecommendationsButton,
  removeDeactivateRecommendationsButton,
  addRecommendationsUnavailableButton,
  addActivateRecommendationsButton,
} from '../utils/controls'
import { RecommendationsParam } from '../types/jwplayer-extended'

/**
 * Init the custom recommendation controls
 * @param jwplayer JWPlayer
 */
export const initRecommendationsControls = (
  player: JWPlayer,
  onRecommendationsOverlay: BlickTVInputProps['onRecommendationsOverlay'],
  areRecommendationsAvailableRef: RefObject<boolean>,
  hasPlaylist?: boolean,
  hasNextVideoInPlaylist?: BlickTVInputProps['hasNextPlaylistItem'],
  shouldShowTrailer?: boolean
): void => {
  addRecommendationsUnavailableButton(player)

  player.on('complete', () => {
    if (
      player &&
      areRecommendationsAvailableRef.current &&
      (!hasPlaylist || !hasNextVideoInPlaylist)
    ) {
      addDeactivateRecommendationsButton(player, onRecommendationsOverlay)
      if (!shouldShowTrailer) {
        onRecommendationsOverlay?.({
          showRecommendations: true,
          reason: 'end',
        })
      }
    }
  })

  player.on('fullscreen', () => {
    if (player && areRecommendationsAvailableRef.current) {
      addActivateRecommendationsButton(player, onRecommendationsOverlay)
      onRecommendationsOverlay?.({
        showRecommendations: false,
      })
    }
  })

  player.on('adPlay', () => {
    // show controls when ad is playing
    // they might be hidden because of recommendations overlay
    // (when `replay` - the ad will be played before `play` event)
    player.setControls(true)
  })

  player.on('recommendations', ({ state }: RecommendationsParam) => {
    switch (state) {
      case 'shown': {
        removeRecommendationsUnavailableButton(player)
        removeActivateRecommendationsButton(player)
        addDeactivateRecommendationsButton(player, onRecommendationsOverlay)
        break
      }
      case 'hidden': {
        removeRecommendationsUnavailableButton(player)
        removeDeactivateRecommendationsButton(player)
        addActivateRecommendationsButton(player, onRecommendationsOverlay)
        break
      }
      case 'available': {
        areRecommendationsAvailableRef.current = true
        removeRecommendationsUnavailableButton(player)
        removeDeactivateRecommendationsButton(player)
        addActivateRecommendationsButton(player, onRecommendationsOverlay)
        break
      }
      case 'unavailable': {
        areRecommendationsAvailableRef.current = false
        removeActivateRecommendationsButton(player)
        removeDeactivateRecommendationsButton(player)

        addRecommendationsUnavailableButton(player)
        break
      }
    }
  })
}
