import { FunctionComponent, useContext } from 'react'
import styled, { css } from 'styled-components'
import { TitleProps } from '@widgets/ArticleTextbox/types'
import contextArticleTextbox, {
  ArticleTextboxContextType,
} from '@contexts/articleTextbox'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

const { ArticleTextboxContext } = contextArticleTextbox

type StyledTitleProps = Pick<ArticleTextboxContextType, 'isPromo'> & {
  hasInvertedColors: boolean
}

const StyledTitle = styled.div<StyledTitleProps>`
  ${({
    theme: {
      color: {
        primary: { primary01, primary02 },
      },
      typography: {
        headings: {
          small: { bundledCSS: smallHeadingCSS },
        },
      },
      spacing: { spacing24, spacing16 },
    },
    isPromo,
    hasInvertedColors,
  }) => css`
    text-align: left;

    color: ${hasInvertedColors ? primary02 : primary01};
    margin-bottom: ${spacing16};
    ${isPromo &&
    css`
      margin-bottom: ${spacing24};
    `};
    ${smallHeadingCSS}
  `}
`

const Title: FunctionComponent<TitleProps> = ({ children }) => {
  const { isPromo } = useContext(ArticleTextboxContext)
  const hasInvertedColors = useHasInvertedColors()

  return (
    <StyledTitle
      isPromo={isPromo}
      hasInvertedColors={hasInvertedColors}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    />
  )
}

export default Title
