import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import usePageMetadata from './usePageMetadata'
import useBodyScroll from '@hooks/useBodyScroll'

type UseBurgerMenuVisibilityType = () => boolean

interface BurgerMenuVisibilityOptions {
  isVisible: boolean
  resetScrollPosition: boolean
}

const useBurgerMenuVisibility: UseBurgerMenuVisibilityType = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>(undefined)
  const pageMetadata = usePageMetadata()
  const { lockBodyScroll, unlockBodyScroll } = useBodyScroll()

  const [burgerMenuVisible, setBurgerMenuVisible] = useState(
    () =>
      !!queryClient.getQueryData<BurgerMenuVisibilityOptions>([
        'burgerMenuVisibility',
      ])?.isVisible
  )

  const updateBurgerMenuVisibility = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === 'burgerMenuVisibility'
      ) {
        setBurgerMenuVisible(
          !!queryClient.getQueryData<BurgerMenuVisibilityOptions>([
            'burgerMenuVisibility',
          ])?.isVisible
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    if (burgerMenuVisible) {
      lockBodyScroll()
    } else {
      const resetScrollPosition =
        queryClient.getQueryData<BurgerMenuVisibilityOptions>([
          'burgerMenuVisibility',
        ])?.resetScrollPosition
      unlockBodyScroll(resetScrollPosition)
    }
  }, [lockBodyScroll, unlockBodyScroll, burgerMenuVisible, queryClient])

  useEffect(() => {
    setBurgerMenuVisible(
      !!queryClient.getQueryData<BurgerMenuVisibilityOptions>([
        'burgerMenuVisibility',
      ])?.isVisible
    )
  }, [pageMetadata, queryClient])

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(updateBurgerMenuVisibility)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, updateBurgerMenuVisibility])

  return burgerMenuVisible
}

export default useBurgerMenuVisibility
