import { FunctionComponent, memo, ElementType, ReactNode } from 'react'
import isEqualReact from 'fast-deep-equal/es6/react'
import DefaultPlaceholder, { PlaceholderProps } from './Placeholder'
import { ImageRatios, ImageWidths } from './types'
import { AvailableImageCrops, Crops } from '@utils/cook/types'
import config from '@config'
import Missing from './Missing'
import Existing from './Existing'

const {
  image: { missingImageSrc },
} = config

export interface ResponsiveImageV2Props {
  src: string
  alt: string
  ratios: ImageRatios
  crops: Partial<Crops<AvailableImageCrops>>
  widths: ImageWidths
  loading?: HTMLImageElement['loading']
  disablePlaceholder?: boolean
  isLazy?: boolean
  Placeholder?: ElementType<PlaceholderProps>
  className?: string
  children?: ReactNode
}

const ResponsiveImageV2: FunctionComponent<ResponsiveImageV2Props> = (
  props
) => {
  const {
    loading,
    isLazy = true,
    Placeholder = DefaultPlaceholder,
    children,
    ...restOfProps
  } = props

  if (restOfProps.src === missingImageSrc) {
    return (
      <Missing
        ratios={restOfProps.ratios}
        Placeholder={Placeholder}
        className={restOfProps.className}>
        {children}
      </Missing>
    )
  }

  const loadingAttr = loading ?? (isLazy ? 'lazy' : undefined)

  return (
    <Existing loading={loadingAttr} Placeholder={Placeholder} {...restOfProps}>
      {children}
    </Existing>
  )
}

const MemoizedResponsiveImageV2 = memo(
  ResponsiveImageV2,
  (
    { src: prevSrc, children: prevChildren },
    { src: nextSrc, children: nextChildren }
  ) => prevSrc === nextSrc && isEqualReact(prevChildren, nextChildren)
)

MemoizedResponsiveImageV2.displayName = 'MemoizedResponsiveImageV2'

export default MemoizedResponsiveImageV2
