import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { ViewportOrientationValues } from './types'

const useViewportOrientation = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>(undefined)

  const [viewportOrientation, setViewportOrientation] =
    useState<ViewportOrientationValues>(
      queryClient.getQueryData<ViewportOrientationValues>([
        'viewportOrientation',
      ]) as ViewportOrientationValues
    )

  const updateOrientation = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === 'viewportOrientation'
      ) {
        setViewportOrientation(
          queryClient.getQueryData<ViewportOrientationValues>([
            'viewportOrientation',
          ]) as ViewportOrientationValues
        )
      }
    },
    [queryClient]
  )

  useEffect(() => {
    setViewportOrientation(
      queryClient.getQueryData<ViewportOrientationValues>([
        'viewportOrientation',
      ]) as ViewportOrientationValues
    )
    unsubscribeFnRef.current = queryCache.subscribe(updateOrientation)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, queryClient, updateOrientation])

  return viewportOrientation
}

export default useViewportOrientation
