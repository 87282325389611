import {
  FunctionComponent,
  useState,
  SyntheticEvent,
  useCallback,
  useEffect,
} from 'react'
import styled, { css, useTheme } from 'styled-components'

import IconButton from '@components/Buttons/IconButton'
import { ArrowsVisibilityType, SwiperClass } from '@hooks/useSwiper'

export interface SwiperArrowsProps {
  swiperRef: React.RefObject<SwiperClass | null>
  arrowsVisibility?: ArrowsVisibilityType
  className?: string
}

const ArrowWrapper = styled.div<{
  direction: 'left' | 'right'
  isHidden: boolean
}>`
  ${({
    theme: {
      color: {
        primary: { primary01: primary01Color },
      },
    },
    direction,
    isHidden,
  }) => css`
    width: 160px;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;

    ${direction === 'left' &&
    css`
      left: 0;
      justify-content: flex-start;
      padding-left: 24px;
      background: linear-gradient(
        270deg,
        rgba(17, 17, 17, 0) 0%,
        ${primary01Color} 100%
      );
    `}

    ${direction === 'right' &&
    css`
      right: 0;
      justify-content: flex-end;
      padding-right: 24px;
      background: linear-gradient(
        270deg,
        ${primary01Color} 0%,
        rgba(17, 17, 17, 0) 100%
      );
    `}

    ${isHidden &&
    css`
      display: none;
    `}
  `}
`
const StyledWrapper = styled.div``

const SwiperArrows: FunctionComponent<SwiperArrowsProps> = ({
  className,
  arrowsVisibility = 'next',
  swiperRef,
}) => {
  const theme = useTheme()

  const [isLeftArrowHidden, setLeftArrowHidden] = useState<boolean>(() =>
    ['none', 'next'].includes(arrowsVisibility)
  )
  const [isRightArrowHidden, setRightArrowHidden] = useState<boolean>(() =>
    ['none', 'prev'].includes(arrowsVisibility)
  )

  useEffect(() => {
    setLeftArrowHidden(['none', 'next'].includes(arrowsVisibility))
    setRightArrowHidden(['none', 'prev'].includes(arrowsVisibility))
  }, [arrowsVisibility])

  const onLeftArrowClick = useCallback(
    (e?: SyntheticEvent) => {
      e?.preventDefault()
      swiperRef.current?.slideToDirection('prev')
    },
    [swiperRef]
  )

  const onRightArrownClick = useCallback(
    (e?: SyntheticEvent) => {
      e?.preventDefault()
      swiperRef.current?.slideToDirection('next')
    },
    [swiperRef]
  )

  return (
    <StyledWrapper className={className}>
      <ArrowWrapper direction="left" isHidden={isLeftArrowHidden}>
        <IconButton
          iconName="chevron-left"
          iconSize={32}
          iconColor={theme.color.primary.primary02}
          buttonSize={32}
          onClick={onLeftArrowClick}
          ariaLabel="left arrow"
        />
      </ArrowWrapper>
      <ArrowWrapper direction="right" isHidden={isRightArrowHidden}>
        <IconButton
          iconName="chevron-right"
          iconSize={32}
          iconColor={theme.color.primary.primary02}
          buttonSize={32}
          onClick={onRightArrownClick}
          ariaLabel="right arrow"
        />
      </ArrowWrapper>
    </StyledWrapper>
  )
}

export default SwiperArrows
