import { FunctionComponent } from 'react'
import styled from 'styled-components'
import Catchword from '@components/HeadlineLead/Catchword'
import Title from '@components/HeadlineLead/Title'

interface TitleCatchwordProps {
  children: string
  catchword?: string
}

const Wrapper = styled.h1`
  display: flex;
  flex-direction: column;
  margin: 0;
`

const isValidHeadlineProps = (title: any): title is string => !!title

const HeadlineWrapper: FunctionComponent<TitleCatchwordProps> = ({
  children,
  catchword,
}) => {
  if (!isValidHeadlineProps(children)) {
    return null
  }
  return (
    <>
      <Wrapper>
        {catchword && <Catchword catchword={catchword + ' '} />}
        <Title title={children} />
      </Wrapper>
    </>
  )
}

export default HeadlineWrapper
