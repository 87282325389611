import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { mobileCSS } from '@measures/responsive'
import useHasInvertedColors from '@hooks/useHasInvertedColors'

const Wrapper = styled.div<{ hasInvertedColors: boolean }>`
  ${({
    theme: {
      color: {
        tertiary: { grey800, grey400 },
      },
      typography: {
        bodycopy: {
          xxsmall: { bundledCSS: xxsmallBodycopyCSS },
        },
      },
    },
    hasInvertedColors,
  }) => css`
    ${xxsmallBodycopyCSS};

    color: ${hasInvertedColors ? grey400 : grey800};
    margin: 0 8px;

    /* On mobile, dates must be always displayed in 2 lines, without any divider */
    ${mobileCSS(css`
      display: none;
    `)}
  `}
`

const Divider: FunctionComponent = () => {
  const hasInvertedColors = useHasInvertedColors()

  return <Wrapper hasInvertedColors={hasInvertedColors}>|</Wrapper>
}

export default Divider
