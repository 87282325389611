import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { CueLiveAdPost, CueLivePost } from '@utils/cueLive'
import Post from './Post'
import AdPost from './AdPost'

interface PostsContainerProps {
  isPinned?: boolean
  accentColor?: string
  children: (CueLivePost | CueLiveAdPost)[]
}

const StyledPostsContainer = styled.div``

const PostsContainer: FunctionComponent<PostsContainerProps> = ({
  isPinned,
  accentColor,
  children,
}) => {
  if (!(children.length > 0)) {
    return null
  }

  return (
    <StyledPostsContainer>
      {children.map((entry) =>
        entry.type === 'ad' ? (
          <AdPost key={entry.id} id={entry.id} accentColor={accentColor} />
        ) : (
          <Post
            key={entry.id}
            id={entry.id}
            publishedAt={entry.publishedAt}
            icon={entry.icon}
            event={entry.event}
            isPinned={isPinned}
            accentColor={accentColor}>
            {entry.values}
          </Post>
        )
      )}
    </StyledPostsContainer>
  )
}

export default PostsContainer
