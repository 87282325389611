import { useCallback, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import config from '@config'
import {
  getVideoInPlaylist,
  hasVideoInPlaylist,
} from '@widgets/Video/playlist/utils'
import useLivestreamGeoBlocking from '@hooks/useLivestreamGeoBlocking'
import {
  UseVideoPlayerPropsInput,
  UseVideoPlayerPropsOutput,
} from '@widgets/Video/types'
import {
  JWPlayer,
  RecommendationsOverlayParam,
} from '@components/Video/VideoPlayer/JwLibrary/types'
import useVideoAds from './useVideoAds'
import usePlaylist from './useVideoPlaylist'
import useVideoTracking from './useVideoTracking'
import useIsInViewport from './useVideoIsInViewport'
import useAutoPause from './useVideoAutoPause'
import useVideoMetadataFetching from './useVideoMetadataFetching'
import useVideoOverlay from './useVideoOverlay'
import useVideoRef from './useVideoRef'
import useVideoRefs from './useVideoRefs'
import {
  VIDEO_TYPES,
  getPlayerType,
  getPosterImageUrl,
  isLiveStream,
  shouldAutoplay,
} from '@widgets/Video/utils'
import usePageMetadata from '@hooks/usePageMetadata'
import { RecommendationsQueryData } from '@hooks/useRecommendations'
import { useQueryClient } from '@tanstack/react-query'
import { isVideoRecommendationsEnabled } from '@widgets/Video/recommendations/utils'
import { ViewportOrientationValues } from '@hooks/useViewport/types'
import useViewportOrientation from '@hooks/useViewport/useViewportOrientation'
import useCMPCookieCategories from '@hooks/useCMPCookieCategories'
import useSubscriptionStatus from '@hooks/useSubscriptionStatus'
import {
  getJWPlayer,
  setPlayerRecommendationsAvailable,
} from '@utils/videoPlayer'

const {
  backend: { imageUrl },
} = config

const useVideoPlayerProps = ({
  isBlickTV,
  geoblocking,
  articleId,
  isAdDisabled,
  syndication,
  videoTaxonomy,
  image,
  position,
  videoId,
  jwVideoId,
  duration,
  title,
  isInVideoOnlyArticle,
  hasPlaylist,
  isMainElement,
  daiAssetKeyJW: daiAssetKey,
  isInScoreboardContent,
  allowFullscreen,
  shouldShowTrailer,
  isAnimatedPreviewDisabled,
  onThumbnailData,
  widgetId,
}: UseVideoPlayerPropsInput): UseVideoPlayerPropsOutput => {
  const pageMetadata = usePageMetadata()
  const [isPlayerReady, setIsPlayerReady] = useState<boolean>(false)
  const [initialPosition, setInitialPosition] = useState<number>(0)
  const [initialPlaying, setInitialPlaying] = useState<boolean>(false)
  const wasImpressionTracked = useRef<boolean>(false)
  const previousOrientationRef = useRef<ViewportOrientationValues>(undefined)
  const isCastingRef = useRef<boolean>(false)
  const viewportOrientationContext = useViewportOrientation()
  const router = useRouter()
  const playlistVideosData = usePlaylist()
  const [isOverlay, setIsOverlay] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const { isInViewport, viewportRef } = useIsInViewport({ isOverlay })
  const { subscriptionStatus } = useSubscriptionStatus()
  const { C0003: areFunctionalCookiesEnabled } = useCMPCookieCategories(
    'someCategoriesChanged',
    ['C0003']
  )

  const { fetchedPosterUrl, fetchedSourceUrl, fetchedMotionThumbnailUrl } =
    useVideoMetadataFetching({
      jwVideoId,
    })

  const {
    isOverlay: videoQueryIsOverlay,
    showOverlay,
    hideOverlay,
  } = useVideoOverlay()

  const currentVideoRef = useVideoRef({ widgetId })

  const { addVideoPlayer, removeVideoPlayer, activateVideoPlayer } =
    useVideoRefs()

  const [showRecommendationsOverlay, setShowRecommendationsOverlay] =
    useState<RecommendationsOverlayParam>({
      showRecommendations: false,
      reason: undefined,
    })
  const liveFallbackSource = daiAssetKey ? fetchedSourceUrl : undefined
  const isLiveStreamVideo = isLiveStream(duration)
  const isLiveStreamVideoWithoutDAI = isLiveStreamVideo && !daiAssetKey
  const autoplay = shouldAutoplay(duration, isInVideoOnlyArticle)
  const startMuted = autoplay

  const videoType = isBlickTV
    ? VIDEO_TYPES.BLICKTV
    : isLiveStreamVideo
      ? VIDEO_TYPES.LIVE
      : VIDEO_TYPES.VOD
  const playerType = getPlayerType({ isBlickTV, duration })
  const posterURL = getPosterImageUrl(imageUrl, image)
  const disabledVideoAds = isAdDisabled || isLiveStreamVideoWithoutDAI

  const shouldFetchLiveFallbackSource = !!daiAssetKey && !fetchedSourceUrl
  const shouldFetchPosterUrl = !isBlickTV && !autoplay && !fetchedPosterUrl
  const playlistData = {
    items: playlistVideosData?.items,
    currentId: pageMetadata?.id,
  }
  const hasNextPlaylistItem = hasVideoInPlaylist({
    ...playlistData,
    direction: 'next',
    startFromTheFirst: true,
  })

  const hasPreviousPlaylistItem = hasVideoInPlaylist({
    ...playlistData,
    direction: 'previous',
  })

  const trackingCallbacks = useVideoTracking({
    isBlickTV,
    autoplay,
    position,
    widgetId,
    playerType,
    videoId,
    jwVideoId,
    title,
    duration,
    isInScoreboardContent,
    isInViewport,
    startMuted,
  })

  useLivestreamGeoBlocking({
    enabled: isBlickTV || isLiveStreamVideo,
    isPlayerReady: isPlayerReady,
    widgetId,
  })

  const { isAdsReady, adContainerId, adTagURL, daiAdTagParameters } =
    useVideoAds({
      disabledVideoAds,
      daiAssetKey,
      syndication,
      videoTaxonomy,
      duration,
      videoId: jwVideoId,
      adSound: !startMuted,
      autostart: autoplay,
    })

  useAutoPause({
    enabled: isBlickTV && isPlayerReady,
    isInViewport,
    widgetId,
    isCastingRef,
  })

  // the player will be mounted only:
  const mountPlayer =
    // - when we have jwVideoId
    !!jwVideoId &&
    // - when ads are disabled or ready
    (disabledVideoAds || isAdsReady) &&
    // - when fetchedSourceUrl is ready (in case of DAI ad)
    !shouldFetchLiveFallbackSource &&
    // - when fetchedPosterUrl is ready
    !shouldFetchPosterUrl &&
    // - when functional cookies are ready
    areFunctionalCookiesEnabled !== undefined

  useEffect(() => {
    return () => {
      if (videoQueryIsOverlay) {
        hideOverlay()
      }
    }
  }, [hideOverlay, videoQueryIsOverlay])

  useEffect(() => {
    return () => {
      document.body.style.overflowY = 'auto'
    }
  }, [])

  useEffect(() => {
    if (
      jwVideoId &&
      isInViewport &&
      !wasImpressionTracked.current &&
      subscriptionStatus !== undefined
    ) {
      trackingCallbacks?.onImpression?.()
      wasImpressionTracked.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInViewport, jwVideoId, subscriptionStatus])

  useEffect(() => {
    if (
      isOverlay &&
      viewportOrientationContext !== previousOrientationRef.current
    ) {
      trackingCallbacks?.onOrientationChange?.(viewportOrientationContext)
    }
    previousOrientationRef.current = viewportOrientationContext
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportOrientationContext, isOverlay])

  useEffect(() => {
    addVideoPlayer({
      type: videoType,
      widgetId,
    })

    return () => {
      const jwPlayer = getJWPlayer(widgetId)
      // if the player is not in pip mode
      if (jwPlayer && !jwPlayer.getPip?.()) {
        // on unmount the player ref should be removed from the video context
        // (player disposing will be called inside the lib)
        removeVideoPlayer(widgetId)
      }
    }
  }, [addVideoPlayer, removeVideoPlayer, videoType, widgetId])

  const onVideoSuspendedContinue = useCallback(() => {
    if (currentVideoRef?.position) {
      setInitialPosition(currentVideoRef?.position)
    }

    setInitialPlaying(true)

    activateVideoPlayer(widgetId)
  }, [activateVideoPlayer, currentVideoRef?.position, widgetId])

  const onVideoSuspendedReplay = useCallback(() => {
    setInitialPosition(0)
    setInitialPlaying(true)

    activateVideoPlayer(widgetId)
  }, [activateVideoPlayer, widgetId])

  const onHeadlineChange = useCallback(
    (headline: string) => {
      if (isBlickTV && headline) {
        trackingCallbacks.setNewHeadline?.(headline)
      }
    },
    [isBlickTV, trackingCallbacks]
  )

  const onPlay = useCallback(() => {
    activateVideoPlayer(widgetId)
  }, [activateVideoPlayer, widgetId])

  const onCast = useCallback(
    (isCasting: boolean) => {
      isCastingRef.current = isCasting
      trackingCallbacks?.setCasting?.(isCasting)
      queryClient.setQueryData(['casting'], isCasting)
      queryClient.invalidateQueries({ queryKey: ['casting'], exact: true })
    },
    [trackingCallbacks, queryClient]
  )

  const onPlaySuccess = useCallback(() => {
    const jwPlayer = getJWPlayer(widgetId)

    trackingCallbacks?.onPlaySuccess?.()

    if (initialPosition) {
      jwPlayer?.skipAd?.()
      jwPlayer?.seek?.(initialPosition)
      setInitialPosition(0)
    }
  }, [initialPosition, trackingCallbacks, widgetId])

  const onEnd = useCallback(() => {
    trackingCallbacks?.onEnd?.()

    if (hasPlaylist && playlistVideosData) {
      const { items } = playlistVideosData
      const nextPlaylistItem =
        items && pageMetadata?.id
          ? getVideoInPlaylist({
              items,
              currentId: pageMetadata?.id,
              direction: 'next',
              startFromTheFirst: true,
            })
          : null

      if (nextPlaylistItem?.link?.href) {
        hideOverlay()
        router.push(nextPlaylistItem.link.href)
      }
    }
  }, [
    hasPlaylist,
    hideOverlay,
    pageMetadata?.id,
    playlistVideosData,
    router,
    trackingCallbacks,
  ])

  const onPlayPrevious = useCallback(() => {
    if (hasPlaylist && playlistVideosData) {
      const { items } = playlistVideosData
      const previousPlaylistItem =
        items && pageMetadata?.id
          ? getVideoInPlaylist({
              items,
              currentId: pageMetadata?.id,
              direction: 'previous',
            })
          : null

      if (previousPlaylistItem?.link?.href) {
        hideOverlay()
        router.push(previousPlaylistItem.link.href)
      }
    }
  }, [hasPlaylist, hideOverlay, pageMetadata?.id, playlistVideosData, router])

  const onRecommendationsOverlay = useCallback(
    (options: RecommendationsOverlayParam) => {
      setShowRecommendationsOverlay({
        showRecommendations: options.showRecommendations,
        reason: options.reason,
      })
    },
    []
  )

  const onReady = useCallback(
    (jwPlayerInstance: JWPlayer) => {
      if (!isBlickTV) {
        setPlayerRecommendationsAvailable?.(
          jwPlayerInstance,
          (queryClient.getQueryData<RecommendationsQueryData>([
            'recommendations',
            articleId,
          ])?.['embedded-videos']?.length as any) > 0
        )
      }

      setIsPlayerReady(true)

      if (initialPlaying) {
        jwPlayerInstance?.play?.()
        setInitialPlaying(false)
      }
    },
    [isBlickTV, initialPlaying, queryClient, articleId]
  )

  const onAdPlay = useCallback(() => {
    activateVideoPlayer(widgetId)
  }, [activateVideoPlayer, widgetId])

  const onEnterOverlay = useCallback(() => {
    showOverlay()
    setIsOverlay(true)
  }, [showOverlay])

  const onExitOverlay = useCallback(() => {
    hideOverlay()
    setIsOverlay(false)
  }, [hideOverlay])

  const playerCallbacks = {
    ...trackingCallbacks,
    onCast,
    onHeadlineChange,
    onPlay,
    onPlaySuccess,
    onPlayPrevious,
    onEnd,
    onRecommendationsOverlay,
    onAdPlay,
    onEnterOverlay,
    onExitOverlay,
    onReady,
  }

  return {
    viewportRef,
    isOverlay,
    showRecommendationsOverlay,
    // PLAYER COMPONENT PROPS
    fetchedPosterUrl,
    fetchedSourceUrl,
    fetchedMotionThumbnailUrl,
    adContainerId,
    isBlickTV,
    isLiveStreamVideo,
    geoblocking,
    hasNextPlaylistItem,
    hasPreviousPlaylistItem,
    image,
    isMainElement,
    mountPlayer,
    videoTitle: title,
    videoDuration: duration,
    // PLAYER PROPS
    videoId,
    jwVideoId,
    mute: startMuted,
    autoplay,
    isAdDisabled,
    // skip ads if the video has initial position
    adTagURL: initialPosition > 0 ? undefined : adTagURL,
    daiAdTagParameters,
    daiAssetKey,
    liveFallbackSource,
    allowFullscreen,
    shouldShowTrailer,
    hasPlaylist,
    doNotSaveCookies: !areFunctionalCookiesEnabled,
    ...playerCallbacks,
    // TODO: check those props
    posterURL,
    isVideoRecommendationsEnabled,
    isAnimatedPreviewDisabled,
    widgetId,
    isVideoSuspended: !!currentVideoRef?.suspended,
    onVideoSuspendedContinue,
    onVideoSuspendedReplay,
    onThumbnailData,
  }
}

export default useVideoPlayerProps
