import SvgIcon from '@components/SvgIcon'
import translate from '@i18n'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css, keyframes, useTheme } from 'styled-components'
import { ItemActionReactProps } from './types'
import { SvgIconName } from '@components/SvgIcon/types'

interface CountProps {
  transitionStatus: TransitionStatusType
}

const increaseSize = keyframes`
    0% {
        transform: scale(1.6);
    }
    100% {
        transform: scale(1);
    }
`

const StyledSvgIcon = styled(SvgIcon)<{
  transitionStatus: TransitionStatusType
}>`
  ${({ transitionStatus }) => css`
    ${transitionStatus === 'goingUpOld' &&
    css`
      animation: ${increaseSize} 0.25s linear;
    `}
  `}
`

interface WrapperProps {
  hasOwnReaction: boolean
}

const Wrapper = styled.div<WrapperProps>`
  ${({
    theme: {
      typography: {
        bodycopy: {
          xsmall: { bundledCSS: bodycopyXSmallCSS },
        },
      },
      color: {
        primary: { blickRed, primary01 },
      },
      spacing: { spacing12 },
    },
    hasOwnReaction,
  }) => css`
    display: flex;
    align-items: center;
    ${bodycopyXSmallCSS};
    height: 20px;
    margin-right: ${spacing12};
    color: ${hasOwnReaction ? blickRed : primary01};
  `}
`

const ReactionButton = styled.button`
  display: inline-block;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  line-height: 0;
  cursor: pointer;
`

const Count = styled.div<CountProps>`
  ${({
    transitionStatus,
    theme: {
      spacing: { spacing2 },
    },
  }) => css`
    flex: 1;
    margin-left: ${spacing2};
    transform: translateY(
      ${['goingUpOld', 'goingDownOld'].includes(transitionStatus)
        ? '-5px'
        : ['goingUpNew', 'goingDownNew'].includes(transitionStatus)
          ? '5px'
          : '0'}
    );
    transition: all 0.2s ease-in-out;
    opacity: ${transitionStatus !== 'initial' ? 0 : 1};
  `}
`

const iconMapping = {
  heart: 'like',
  bolt: 'dislike',
  happy: 'happy',
  sad: 'sad',
  critical: 'critical',
} as const satisfies Record<string, SvgIconName>

export type ReactionIconMapping = typeof iconMapping

export type ReactionIcon = keyof typeof iconMapping

type TransitionStatusType =
  | 'initial'
  | 'goingUpOld'
  | 'goingUpNew'
  | 'goingDownNew'
  | 'goingDownOld'

const ActionReact: FunctionComponent<ItemActionReactProps> = ({
  name,
  onAction,
  hasOwnReaction,
  children,
}) => {
  const mappedIcon = iconMapping[name]
  const theme = useTheme()
  const [transitionStatus, setTransitionStatus] =
    useState<TransitionStatusType>('initial')
  const firstTransitiontimeoutRef =
    useRef<ReturnType<typeof setTimeout>>(undefined)
  const secondTransitiontimeoutRef =
    useRef<ReturnType<typeof setTimeout>>(undefined)

  const reactionColor = hasOwnReaction
    ? theme.color.primary.blickRed
    : theme.color.primary.primary01

  useEffect(() => {
    return () => {
      if (firstTransitiontimeoutRef.current) {
        clearTimeout(firstTransitiontimeoutRef.current)
      }
      if (secondTransitiontimeoutRef.current) {
        clearTimeout(secondTransitiontimeoutRef.current)
      }
    }
  }, [])

  const handleClick = useCallback(() => {
    // If hasOwnReaction is false, it means user is adding a reaction (it will become true after the onAction has run successfully)
    const isAddingReaction = !hasOwnReaction
    setTransitionStatus(isAddingReaction ? 'goingUpOld' : 'goingDownNew')
    firstTransitiontimeoutRef.current = setTimeout(() => {
      onAction()
      setTransitionStatus(isAddingReaction ? 'goingUpNew' : 'goingDownOld')
      secondTransitiontimeoutRef.current = setTimeout(() => {
        setTransitionStatus('initial')
      }, 200)
    }, 200)
  }, [onAction, hasOwnReaction])

  return (
    <Wrapper hasOwnReaction={hasOwnReaction}>
      <ReactionButton
        type="button"
        onClick={handleClick}
        aria-label={translate(`commenting.reaction.${mappedIcon}`)}>
        <StyledSvgIcon
          iconName={mappedIcon}
          size={20}
          color={reactionColor}
          transitionStatus={transitionStatus}
        />
      </ReactionButton>
      <Count transitionStatus={transitionStatus}>{children}</Count>
    </Wrapper>
  )
}

export default ActionReact
