import { ImageCrop, ImageDensity, ImageRatio, ImageWidth } from './types'

export type EnrichImageUrlWithRatioAndCrop = (
  url: string,
  ratio: ImageRatio,
  crop: ImageCrop
) => string

export type EnrichImageUrlWithImageWidth = (
  url: string,
  imwidth: ImageWidth
) => string

export type EnrichImageUrlWithImageDensity = (
  url: string,
  imdensity: ImageDensity
) => string

const enrichImageUrlWithRatioAndCrop: EnrichImageUrlWithRatioAndCrop = (
  url,
  ratio,
  crop
) => {
  const pictureSource = new URL(url)

  pictureSource.searchParams.append('ratio', ratio)

  Object.entries(crop).forEach(([paramName, paramValue]) => {
    pictureSource.searchParams.set(paramName, paramValue.toString())
  })

  return pictureSource.toString()
}

const enrichImageUrlWithImageWidth: EnrichImageUrlWithImageWidth = (
  url,
  imwidth
) => {
  const pictureSource = new URL(url)

  pictureSource.searchParams.append('imwidth', imwidth.toString())

  return pictureSource.toString()
}

const enrichImageUrlWithImageDensity: EnrichImageUrlWithImageDensity = (
  url,
  imdensity
) => {
  const pictureSource = new URL(url)

  pictureSource.searchParams.append('imdensity', imdensity.toString())

  return pictureSource.toString()
}

export {
  enrichImageUrlWithRatioAndCrop,
  enrichImageUrlWithImageWidth,
  enrichImageUrlWithImageDensity,
}
