import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import SvgIcon from '@components/SvgIcon'
import { desktopCSS } from '@measures/responsive'

const StyledBlickPlusLogo = styled(SvgIcon)`
  ${({
    theme: {
      typography: {
        subheads: {
          xlarge: { fontSize: xlargeFontSize, lineHeight: xlargeLineHeight },
          large1: { fontSize: large1FontSize, lineHeight: large1LineHeight },
        },
      },
      spacing: { spacing8 },
    },
  }) => css`
    vertical-align: top;
    margin-right: ${spacing8};

    // catchword line height (in px)
    height: calc(${large1FontSize} * ${large1LineHeight});
    // catchword line height (in px) * blick plus logo aspect ratio
    width: calc(${large1FontSize} * ${large1LineHeight} * 54 / 40);

    ${desktopCSS(css`
      height: calc(${xlargeFontSize} * ${xlargeLineHeight});
      width: calc(${xlargeFontSize} * ${xlargeLineHeight} * 54 / 40);
    `)};
  `}
`

const BlickPlusLogo: FunctionComponent = () => {
  return <StyledBlickPlusLogo iconName="blick-plus-logo" size={18} />
}

export default BlickPlusLogo
