import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { ImageRatios, ImageWidths } from '@components/ResponsiveImageV2/types'
import { FunctionComponent, ReactNode } from 'react'
import { ListItemTeaserAPIProps } from './types'

export type TeaserImageProps = ListItemTeaserAPIProps['image'] & {
  className?: string
  children?: ReactNode
}

const RATIOS = {
  desktop: '3_2',
  tablet: '1_1',
  mobile: '1_1',
} as const satisfies ImageRatios

const WIDTHS = {
  desktop: 300,
  tablet: 171,
  mobile: 171,
} as const satisfies ImageWidths

const TeaserImage: FunctionComponent<TeaserImageProps> = ({
  src,
  alt,
  crops,
  children,
  className,
}) => (
  <ResponsiveImageV2
    className={className}
    src={src}
    alt={alt}
    crops={crops}
    ratios={RATIOS}
    widths={WIDTHS}>
    {children}
  </ResponsiveImageV2>
)

export default TeaserImage
