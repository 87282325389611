import styled, { css } from 'styled-components'
import Blink from '@components/Blink'
import config from '@config'
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useQueryClient } from '@tanstack/react-query'
import useABTestValue from '@hooks/useABTestValue'

const {
  abTest: { windowKey, localStorageUpdateKey, dashboardPath },
} = config

const getIsLocalStorageExperimentSet = (): boolean => {
  try {
    const featureFlagStorageInfo = (JSON.parse(
      localStorage.getItem(windowKey) as any
    ) ?? {}) as Record<string, unknown>

    return Object.keys(featureFlagStorageInfo).length > 0
  } catch (err) {
    //! nothing we can do!
    console.error(err)
  }

  return false
}

const StyledFeatureFlagIndicator = styled(Blink)<{ isCUIEnabled: boolean }>`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
    },
    isCUIEnabled,
  }) => css`
    position: fixed;
    z-index: 1000000;
    text-decoration: none;
    width: 40px;
    height: 40px;
    ${isCUIEnabled
      ? css`
          left: 70px;
        `
      : css`
          right: 16px;
        `};
    bottom: 16px;
    border-radius: 9px;
    font-size: 33px;
    line-height: 42px;
    text-align: center;
    overflow: hidden;
    background-color: ${blickRed};
    cursor: pointer;

    &:after {
      content: '🧪';
    }
  `}
`

const FeatureFlagIndicator: FunctionComponent = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const [isShown, setIsShown] = useState<boolean>(false)
  const isCUIEnabled = useABTestValue('showCUI') === 'show'

  const unsubscribeFnRef = useRef<() => void>(undefined)

  const updateIsShown = useCallback((args: any) => {
    if (
      args.query.queryKey[0] === windowKey &&
      args.query.queryKey[1] === localStorageUpdateKey
    ) {
      setIsShown(getIsLocalStorageExperimentSet())
    }
  }, [])

  useEffect(() => {
    setIsShown(getIsLocalStorageExperimentSet())
    unsubscribeFnRef.current = queryCache.subscribe(updateIsShown)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [updateIsShown, queryCache])

  return isShown ? (
    <StyledFeatureFlagIndicator
      href={dashboardPath}
      isCUIEnabled={isCUIEnabled}
    />
  ) : null
}

export default FeatureFlagIndicator
