import { desktopCSS } from '@measures/responsive'
import { Children, FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

export interface CatchwordProps {
  children: ReactNode
}

const StyledCatchword = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
      color: {
        primary: { primary02: primary02Color },
      },
      typography: {
        subheads: {
          xlarge: { bundledCSS: xlargeSubheadingCSS },
          large1: { bundledCSS: large1SubheadingCSS },
        },
      },
    },
  }) => {
    const textStyle = css`
      ${large1SubheadingCSS};
      ${desktopCSS(css`
        ${xlargeSubheadingCSS};
      `)};

      color: ${primary02Color};
    `

    //! We apply these rules multiple time to override
    //! browser and body styles applying on `strong` and `p` tags.
    return css`
      position: relative;
      margin-bottom: ${spacing8};
      ${textStyle};

      > strong,
      > p {
        ${textStyle};
      }

      > p {
        margin: 0;
      }
    `
  }}
`

const Wrapper = styled.div`
  width: 100%;

  > :nth-child(1) {
    float: left;
  }

  :after {
    content: '';
    display: table;
    clear: both;
  }
`

const Catchword: FunctionComponent<CatchwordProps> = ({ children }) => {
  const childrenAsArray = Children.toArray(children)

  if (childrenAsArray.length === 1 && typeof childrenAsArray[0] === 'string') {
    return (
      <StyledCatchword
        dangerouslySetInnerHTML={{ __html: childrenAsArray[0] }}
      />
    )
  }

  return (
    <Wrapper>
      {childrenAsArray[0]}
      <StyledCatchword
        dangerouslySetInnerHTML={{ __html: childrenAsArray[1] }}
      />
    </Wrapper>
  )
}

export default Catchword
