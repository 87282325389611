import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { SwiperClass, ArrowsVisibilityType } from '@hooks/useSwiper'
import IconButton from '@components/Buttons/IconButton'

export interface NavigationArrowsProps {
  swiperRef: React.RefObject<SwiperClass | null>
  arrowsVisibility?: ArrowsVisibilityType
  className?: string
  onClick?: (prevOrNext: 'prev' | 'next') => void
}

const StyledPlaceholder = styled.div`
  min-height: 80px;
`

const StyledWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing16 },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing16};
  `}
`

const StyledIconButton = styled(IconButton)<{ disabled: boolean }>`
  ${({
    theme: {
      color: {
        primary: { primary02: primary02Color },
      },
    },
    disabled,
  }) => css`
    border-radius: 50%;
    background: ${primary02Color};

    opacity: ${disabled ? 0 : 1};
    pointer-events: ${disabled ? 'none' : 'auto'};
  `}
`

const VerticalNavigationArrows: FunctionComponent<NavigationArrowsProps> = ({
  swiperRef,
  arrowsVisibility = 'next',
  className,
  onClick,
}) => {
  const theme = useTheme()

  const [isUpArrowDisabled, setIsUpArrowDisabled] = useState(() =>
    ['none', 'next'].includes(arrowsVisibility)
  )
  const [isDownArrowDisabled, setIsDownArrowDisabled] = useState(() =>
    ['none', 'prev'].includes(arrowsVisibility)
  )

  useEffect(() => {
    setIsUpArrowDisabled(['none', 'next'].includes(arrowsVisibility))
    setIsDownArrowDisabled(['none', 'prev'].includes(arrowsVisibility))
  }, [arrowsVisibility])

  const handleOnUpArrowClick = useCallback(() => {
    swiperRef.current?.slideToDirection('prev')
    onClick?.('prev')
  }, [onClick, swiperRef])

  const handleOnDownArrowClick = useCallback(() => {
    swiperRef.current?.slideToDirection('next')
    onClick?.('next')
  }, [onClick, swiperRef])

  return (
    <StyledPlaceholder className={className}>
      <StyledWrapper>
        <StyledIconButton
          disabled={isUpArrowDisabled}
          ariaLabel="Arrow up"
          buttonSize={40}
          onClick={handleOnUpArrowClick}
          iconName="arrow-up"
          iconSize={20}
          iconColor={theme.color.primary.primary01}
        />
        <StyledIconButton
          disabled={isDownArrowDisabled}
          ariaLabel="Arrow Down"
          buttonSize={40}
          onClick={handleOnDownArrowClick}
          iconName="arrow-down"
          iconSize={20}
          iconColor={theme.color.primary.primary01}
        />
      </StyledWrapper>
    </StyledPlaceholder>
  )
}

export default VerticalNavigationArrows
