import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react'
import { DirectionType, SwiperClass } from './types'
import { getChildElementOffsets, getNextIndex } from './utils'

export type { DirectionType, SwiperClass, ArrowsVisibilityType } from './types'

export type UseSwiperType = (props: {
  itemsContainerRef: RefObject<HTMLDivElement | null>
  // slide index which should be active initially
  initialSlide?: number
  // to slide by more than one item
  slidesPerGroup?: number
  vertical?: boolean
  onSlideChange?: (args: { nextIndex: number }) => void
}) => {
  slideTo: (nextIndex: number, disableSmooth?: boolean) => void
  slideToDirection: (direction: DirectionType) => void
  swiperRef: RefObject<SwiperClass>
}

const emptyFn = () => undefined

const useSwiper: UseSwiperType = ({
  itemsContainerRef,
  initialSlide = 0,
  slidesPerGroup = 1,
  vertical = false,
  onSlideChange,
}) => {
  const isSlideToFirstTimeCalled = useRef(true)
  const preventOnSlideChange = useRef(false)

  const swiperRef = useRef<SwiperClass>({
    activeIndex: initialSlide,
    slideTo: emptyFn,
    slideToDirection: emptyFn,
  })

  const setActiveIndex = useCallback((activeIndex: number) => {
    swiperRef.current.activeIndex = activeIndex
  }, [])

  const slideTo = useCallback(
    (nextIndex: number, disableSmooth?: boolean) => {
      if (!itemsContainerRef.current || nextIndex === -1) {
        return
      }

      const childElementOffsets = getChildElementOffsets(
        itemsContainerRef.current?.children,
        vertical
      )

      const scrollAmount = childElementOffsets[nextIndex]

      if (typeof scrollAmount !== 'undefined') {
        const scrollBehaviorValue =
          itemsContainerRef.current.style.scrollBehavior

        if (disableSmooth) {
          itemsContainerRef.current.style.scrollBehavior = 'initial'
        }

        if (vertical) {
          itemsContainerRef.current.scrollTop = scrollAmount
        } else {
          itemsContainerRef.current.scrollLeft = scrollAmount
        }

        if (disableSmooth) {
          // restore smooth value
          itemsContainerRef.current.style.scrollBehavior = scrollBehaviorValue
        }

        if (!preventOnSlideChange.current) {
          onSlideChange?.({ nextIndex })
        }

        preventOnSlideChange.current = false

        setActiveIndex(nextIndex)
      }
    },
    [itemsContainerRef, onSlideChange, setActiveIndex, vertical]
  )

  const slideToDirection = useCallback(
    (direction: DirectionType) => {
      const nextIndex = getNextIndex({
        direction,
        itemsContainerElement: itemsContainerRef.current,
        slidesPerGroup,
        vertical,
      })

      slideTo(nextIndex)
    },
    [itemsContainerRef, slideTo, slidesPerGroup, vertical]
  )

  useEffect(() => {
    swiperRef.current.slideTo = slideTo
  }, [slideTo])

  useEffect(() => {
    swiperRef.current.slideToDirection = slideToDirection
  }, [slideToDirection])

  //! When scrollSnapType is defined - the widget initially is scrolled to the middle or the last item
  useEffect(() => {
    if (itemsContainerRef.current) {
      const scrollSnapTypeValue =
        itemsContainerRef.current?.style.scrollSnapType || ''

      itemsContainerRef.current.style.scrollSnapType = 'none'

      const timeoutRef = setTimeout(() => {
        if (itemsContainerRef.current) {
          itemsContainerRef.current.style.scrollSnapType = scrollSnapTypeValue
        }
      }, 300)

      return () => {
        clearTimeout(timeoutRef)
      }
    }
  }, [itemsContainerRef])

  useEffect(() => {
    if (isSlideToFirstTimeCalled && initialSlide) {
      preventOnSlideChange.current = true

      //! when we slide to initialSlide we need to disable `scroll-behavior: smooth`
      //! to scroll immediately without animation (we don't need it initially)
      slideTo(initialSlide, true)
      isSlideToFirstTimeCalled.current = false
    }
  }, [initialSlide, itemsContainerRef, slideTo])

  return useMemo(
    () => ({
      slideTo,
      slideToDirection,
      swiperRef,
    }),
    [slideToDirection, slideTo, swiperRef]
  )
}

export default useSwiper
