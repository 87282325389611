import { ConfigProps } from '@config/types'

const config = ({}: ConfigProps) =>
  ({
    missingImageSrc: '/incoming/31574-v2-missing.png',
    densities: [1, 2, 3],
    viewports: {
      desktop: {
        mediaQuery: '(min-width: 994px)',
      },
      tablet: {
        mediaQuery: '(min-width: 768px) and (max-width: 993.9px)',
      },
      mobile: {
        mediaQuery: '(max-width: 767.9px)',
        viewportWidthMediaQueries: [
          {
            mediaQuery: '(max-width: 320.9px)',
            imWidth: 320,
          },
          {
            mediaQuery: '(min-width: 321px) and (max-width: 375.9px)',
            imWidth: 360,
          },
          {
            mediaQuery: '(min-width: 376px) and (max-width: 393.9px)',
            imWidth: 393,
          },
          {
            mediaQuery: '(min-width: 394px) and (max-width: 427.9px)',
            imWidth: 402,
          },
          {
            mediaQuery: '(min-width: 428px) and (max-width: 767.9px)',
            imWidth: 440,
          },
        ] satisfies { mediaQuery: string; imWidth: number }[],
      },
    },
  }) as const satisfies Record<string, unknown>

export default config
