import { FunctionComponent, useMemo } from 'react'
import config from '@config'
import { ImageWidth } from './types'
import {
  enrichImageUrlWithImageDensity,
  enrichImageUrlWithImageWidth,
} from './utils'

const {
  image: { densities },
} = config

export interface SourceProps {
  url: string
  media: string
  width: ImageWidth
  onLoad?: () => void
  onError?: () => void
}

const Source: FunctionComponent<SourceProps> = ({
  url,
  media,
  width,
  onLoad,
  onError,
}) => {
  const imageUrl = useMemo(
    () =>
      enrichImageUrlWithImageWidth(new URL(url).toString(), width).toString(),
    [url, width]
  )

  const srcSet = useMemo(
    () =>
      densities.reduce(
        (acc, density, index) =>
          `${acc}${index !== 0 ? ', ' : ''}${enrichImageUrlWithImageDensity(imageUrl, density)} ${density}x`,
        ''
      ),
    [imageUrl]
  )

  return (
    <source media={media} srcSet={srcSet} onLoad={onLoad} onError={onError} />
  )
}

export default Source
