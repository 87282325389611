import { useRef, useState, useCallback, useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'

export type UseIsCasting = () => boolean

const useIsCasting: UseIsCasting = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const unsubscribeFnRef = useRef<() => void>(undefined)

  const [isCasting, setIsCasting] = useState<boolean>(
    () => !!queryClient.getQueryData<boolean>(['casting'])
  )

  const updateIsCasting = useCallback(
    (args: any) => {
      if (
        ['invalidate'].includes(args?.action?.type) &&
        args.query.queryKey[0] === 'casting'
      ) {
        setIsCasting(!!queryClient.getQueryData<boolean>(['casting']))
      }
    },
    [queryClient]
  )

  useEffect(() => {
    setIsCasting(!!queryClient.getQueryData<boolean>(['casting']))

    unsubscribeFnRef.current = queryCache.subscribe(updateIsCasting)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, queryClient, updateIsCasting])

  return isCasting
}

export default useIsCasting
