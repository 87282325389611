import ResponsiveImageV2 from '@components/ResponsiveImageV2'
import { ImageWidths } from '@components/ResponsiveImageV2/types'
import { GalleryWidget } from '@widgets/Gallery/types'
import { FunctionComponent } from 'react'
import { getHeroImageRatios } from '../Common/SlideImage/utils'
import { Image } from '@utils/cook/types'

interface HerotellingSingleImageProps
  extends Omit<Image<'1_1' | '9_16'>, 'width' | 'height'> {
  imageWidth: GalleryWidget['imageWidth']
}

const imageWidths: ImageWidths = {
  desktop: 964,
  tablet: 664,
  mobile: 'viewport-width',
}

const HerotellingSingleImage: FunctionComponent<
  HerotellingSingleImageProps
> = ({ src, alt, crops, imageWidth }) => {
  return (
    <ResponsiveImageV2
      disablePlaceholder
      src={src}
      alt={alt}
      ratios={getHeroImageRatios(imageWidth)}
      crops={crops ?? {}}
      widths={imageWidths}
    />
  )
}

export default HerotellingSingleImage
