import { FunctionComponent } from 'react'
import { ResponsiveImageV2Props } from '.'

export type MissingProps = Pick<
  ResponsiveImageV2Props,
  'ratios' | 'className' | 'children'
> & {
  Placeholder: Required<ResponsiveImageV2Props>['Placeholder']
}

const Missing: FunctionComponent<MissingProps> = ({
  ratios,
  Placeholder,
  className,
  children,
}) => (
  <Placeholder
    ratios={ratios}
    isLoaded={false}
    className={className}
    hasValidSourceDesktop={false}
    hasValidSourceTablet={false}
    hasValidSourceMobile={false}>
    {children}
  </Placeholder>
)

export default Missing
