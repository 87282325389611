import { RefObject, useCallback, useEffect } from 'react'

const TOLERANCE_PIXEL_AMOUNT = 2

export type UseScrollArrowType = ({
  elementRef,
  setShowRightArrow,
  setShowLeftArrow,
}: {
  elementRef: RefObject<HTMLDivElement | null>
  setShowRightArrow: (show: boolean) => void
  setShowLeftArrow: (show: boolean) => void
}) => void

const useScrollArrow: UseScrollArrowType = ({
  elementRef,
  setShowRightArrow,
  setShowLeftArrow,
}) => {
  const onScroll = useCallback(() => {
    if (elementRef?.current) {
      const maxScrollLeft =
        elementRef.current.scrollWidth - elementRef.current.clientWidth

      if (
        elementRef.current.scrollLeft >
        maxScrollLeft - TOLERANCE_PIXEL_AMOUNT
      ) {
        setShowRightArrow(false)
        setShowLeftArrow(true)
      } else if (elementRef.current.scrollLeft < TOLERANCE_PIXEL_AMOUNT) {
        setShowRightArrow(true)
        setShowLeftArrow(false)
      } else {
        setShowRightArrow(true)
        setShowLeftArrow(true)
      }
    }
    return false
  }, [elementRef, setShowRightArrow, setShowLeftArrow])

  useEffect(() => {
    const scrollableElement = elementRef?.current
    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', onScroll)
    }
    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', onScroll)
      }
    }
  }, [onScroll, elementRef])
}

export default useScrollArrow
