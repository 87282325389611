import { useCallback, useEffect, useRef } from 'react'
import { JWPlayer } from '../../types'

export type UseOnVTTCueChange = ({
  player,
  vttCues,
  onCueChange,
}: {
  player: JWPlayer
  vttCues?: VTTCue[]
  onCueChange: (text?: string[]) => void
}) => void

const getActiveCues = (cues: VTTCue[], currentTime: number) =>
  cues.filter(
    (cue) =>
      currentTime >= cue.startTime &&
      (!cue.endTime || currentTime <= cue.endTime)
  )

const isTheSameCuesText = (cuesText?: string[], prevCuesText?: string[]) =>
  cuesText?.length === prevCuesText?.length &&
  cuesText?.join() === prevCuesText?.join()

const useOnVTTCueChange: UseOnVTTCueChange = ({
  player,
  vttCues,
  onCueChange,
}) => {
  const previousCuesTextRef = useRef<string[]>(undefined)

  const onTime = useCallback(
    ({ currentTime }: { currentTime: number }) => {
      if (vttCues) {
        const activeCues = getActiveCues(vttCues, currentTime)
        const activeCuesText = activeCues?.map((cue) => cue.text)
        const previousCuesText = previousCuesTextRef.current

        // prevent calling `onCueChange` if cue was not changed
        if (!isTheSameCuesText(activeCuesText, previousCuesText)) {
          onCueChange(activeCuesText)
        }
        previousCuesTextRef.current = activeCuesText
      }
    },
    [onCueChange, vttCues]
  )

  useEffect(() => {
    player.on('time', onTime)

    return () => {
      player.off('time', onTime)
    }
  }, [onTime, player])
}

export default useOnVTTCueChange
