import { FunctionComponent, ReactNode, useRef } from 'react'
import styled, { css } from 'styled-components'
import useIsInRecipeIngredientsContent from '@hooks/useIsInRecipeIngredientsContent'
import { printCSS } from '@utils/style'
import useGetPageIdentity from '@hooks/useGetPageIdentity'
import useIsInArticleBodyContent from '@hooks/useIsInArticleBodyContent'
import useIsInCueLiveContent from '@hooks/useIsInCueLiveContent'

export type VideoContainerProps = {
  children?: ReactNode
  isInScoreboardContent?: boolean
}

interface StyledVideoContainerProps extends VideoContainerProps {
  shouldStretchWidthOnMobile: boolean
  shouldStretchToCardWidth: boolean
}

export const StyledVideoContainer = styled.div<StyledVideoContainerProps>`
  ${({
    theme: {
      measures: { stretchToLayoutCardWidth, stretchWidthOnMobile },
    },
    shouldStretchWidthOnMobile,
    shouldStretchToCardWidth,
  }) => css`
    position: relative;

    ${printCSS(css`
      display: none;
    `)}

    ${shouldStretchWidthOnMobile && stretchWidthOnMobile()}

    ${shouldStretchToCardWidth && stretchToLayoutCardWidth()}
  `}
`

const VideoContainer: FunctionComponent<VideoContainerProps> = ({
  isInScoreboardContent,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { isArticle, isRecipe } = useGetPageIdentity()
  const isInArticleBody = useIsInArticleBodyContent()
  const isInCueLive = useIsInCueLiveContent()
  const isInRecipeIngredients = useIsInRecipeIngredientsContent()

  const shouldStretchWidthOnMobile =
    isArticle &&
    isInArticleBody &&
    !isRecipe &&
    !isInScoreboardContent &&
    !isInCueLive

  const shouldStretchToCardWidth = isInRecipeIngredients

  return (
    <StyledVideoContainer
      ref={containerRef}
      shouldStretchWidthOnMobile={shouldStretchWidthOnMobile}
      shouldStretchToCardWidth={shouldStretchToCardWidth}>
      {children}
    </StyledVideoContainer>
  )
}

export default VideoContainer
