import { FunctionComponent, useCallback } from 'react'
import config from '@config'
import translate from '@i18n'
import usePageMetadata from '@hooks/usePageMetadata'
import { mobileCSS } from '@measures/responsive'
import { SocialProps } from '@widgets/SocialShare/types'
import GamificationButton from '@components/Buttons/GamificationButton'
import styled, { css } from 'styled-components'
import EndOfArticleContext from '@contexts/endOfArticle'
import { stripHtml } from '@hooks/useTracking/utils'
import useIsInRecipeContent from '@hooks/useIsInRecipeContent'
import { printCSS } from '@utils/style'
import Feedback from '@components/Social/Feedback'
import PlusLinkShareButton from '@components/PlusLinkShare/Button'
import GoogleNews from '@components/Social/GoogleNews'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import ShareCTAButton from './ShareCTAButton'
import CommentButton from '@components/HeadlineLead/CommentButton'
import SocialButton from './Button'

const {
  backend: { baseUrl, rootUrl },
  socialembeds: { blickplusTitleAddition },
  publication: { publication },
} = config
const isRomandie = publication === 'romandie'

const StyledSocial = styled.div<{ isInRecipe: boolean; endOfArticle: boolean }>`
  ${({
    theme: {
      color: {
        tertiary: { grey300 },
      },
      spacing: { spacing8, spacing16, spacing20 },
    },
    isInRecipe,
    endOfArticle,
  }) => css`
    display: flex;
    justify-content: space-between;
    flex: 1;

    ${printCSS(css`
      display: none;
    `)}

    ${mobileCSS(css`
      align-self: flex-start;
    `)};

    margin: ${isInRecipe && !endOfArticle
      ? `${spacing16} ${spacing8} ${spacing16} 0`
      : `${spacing8} ${spacing8} ${spacing8} 0`};

    ${endOfArticle &&
    css`
      margin-bottom: 0;
      margin-right: 0;
      padding-bottom: ${spacing20};
      border-bottom: 1px solid ${grey300};
    `}
  `}
`

const SocialShareCTAWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    align-items: center;
    display: flex;
    gap: ${spacing8};
  `}
`

const StyledSocialWrapper = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: ${spacing8};
  `}
`

const SocialButtonsContainer = styled.div`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${spacing8};
  `}
`

const Social: FunctionComponent<SocialProps> = ({
  endOfArticle,
  setTogglePlayer,
  togglePlayer,
}) => {
  const { id, url, isPlus, title, catchword, ressort, hasTextToSpeechEnabled } =
    usePageMetadata()

  const isInRecipe = useIsInRecipeContent()
  const shouldShowCommentButton = !isRomandie && isInRecipe && !endOfArticle

  const trackingOnStart = useCallback<TrackingFnType>(
    () => ({
      event: 'audio_player_start',
      analyticsRessort: ressort,
    }),
    [ressort]
  )

  const handleStartClickTracking = useTracking(trackingOnStart)
  const toogleTextToSpeech = useCallback(() => {
    setTogglePlayer?.(!togglePlayer)
    if (!togglePlayer) {
      handleStartClickTracking()
    }
  }, [setTogglePlayer, togglePlayer, handleStartClickTracking])
  if (!url || !id) {
    return null
  }

  const blickplusTitleAdditionForUrl = encodeURIComponent(
    ` ${blickplusTitleAddition}`
  )

  const sharingTitle = isPlus
    ? `${stripHtml(title)}${blickplusTitleAdditionForUrl}`
    : stripHtml(title)
  const sharingCatchword = catchword ? stripHtml(catchword) : ''

  const shareUrl = `${baseUrl}${rootUrl}${url.slice(1)}`
  const fullShareTitleAndCatchword = `${sharingCatchword}%20${sharingTitle}`

  return (
    <EndOfArticleContext.Provider value={endOfArticle}>
      <StyledSocial isInRecipe={isInRecipe} endOfArticle={endOfArticle}>
        <StyledSocialWrapper>
          <SocialButtonsContainer>
            <SocialShareCTAWrapper>
              <ShareCTAButton
                title={fullShareTitleAndCatchword}
                articleId={id}
              />
              {!endOfArticle && <PlusLinkShareButton />}
              {!endOfArticle && hasTextToSpeechEnabled && (
                <SocialButton
                  isActive={togglePlayer}
                  ariaLabel={
                    togglePlayer
                      ? translate('social.stopTextToSpeech')
                      : translate('social.playTextToSpeech')
                  }
                  type={togglePlayer ? 'close' : 'headphones'}
                  onClick={toogleTextToSpeech}
                />
              )}
            </SocialShareCTAWrapper>
          </SocialButtonsContainer>
          {endOfArticle && <GoogleNews />}
        </StyledSocialWrapper>
        <GamificationButton />
        {shouldShowCommentButton && <CommentButton />}
      </StyledSocial>
      {endOfArticle ? (
        <Feedback articleUrl={shareUrl} articleTitle={title ?? ''} />
      ) : null}
    </EndOfArticleContext.Provider>
  )
}

export default Social
