import {
  FunctionComponent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import { CookWidgetJSON } from '@widgets/types'
import { desktopCSS, layout, mobileAndTabletCSS } from '@measures/responsive'
import useMount from '@hooks/useMount'
import { printCSS } from '@utils/style'
import JSONRenderer from '@components/JSONRenderer'
import { isValidAd } from '@utils/ads'
import { StyledWrapper as AdPlaceholderWrapper } from '@components/Ad/Wrapper'

export interface WelcomeAdProps {
  children: CookWidgetJSON
}

const {
  desktop: { minWidth: desktopMinWidth },
  header: {
    desktop: { offsetFirstRow: headerOffsetFirstRowDesktop },
    mobileAndTablet: { offsetFirstRow: headerOffsetFirstRowMobileAndTablet },
  },
} = layout

const getHeaderOffset = () =>
  window.innerWidth < desktopMinWidth
    ? headerOffsetFirstRowMobileAndTablet
    : headerOffsetFirstRowDesktop

let ticking = false

const Wrapper = styled.div<{ isVisible: boolean }>`
  ${({ isVisible }) => css`
    overflow-y: hidden;
    position: relative;
    width: 100vw;
    transition-property: height;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    height: 0;

    ${AdPlaceholderWrapper} {
      height: 100%;
    }

    ${desktopCSS(css`
      transition-duration: 0.5s;
      ${isVisible &&
      css`
        height: calc(100vh - ${headerOffsetFirstRowDesktop}px);
      `};
    `)};

    ${mobileAndTabletCSS(css`
      transition-duration: 0.3s;
      ${isVisible &&
      css`
        height: calc(100vh - ${headerOffsetFirstRowMobileAndTablet}px);
      `};
    `)};

    ${printCSS(css`
      display: none;
    `)};
  `};
`

const AdWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const setOpacity = ({
  elementRef,
  adWrapperRef,
  currentOpacity,
}: {
  elementRef: RefObject<HTMLDivElement | null>
  adWrapperRef: RefObject<HTMLDivElement | null>
  currentOpacity: RefObject<number>
}) => {
  //! Do not calculate or set opacity when already 0 and scrolled to the main content
  if (window.scrollY >= window.innerHeight - getHeaderOffset()) {
    if (currentOpacity.current !== 0) {
      if (elementRef?.current) {
        currentOpacity.current = 0
        elementRef.current.style.opacity = '0'
        if (adWrapperRef.current) {
          adWrapperRef.current.style.visibility = 'hidden'
        }
      }
    }
  } else {
    const newOpacity =
      Math.round(
        (1 -
          window.scrollY / (window.innerHeight - getHeaderOffset()) +
          Number.EPSILON) *
          100
      ) / 100

    if (Math.abs(newOpacity - currentOpacity.current) >= 0.01) {
      if (elementRef?.current) {
        if (currentOpacity.current === 0) {
          if (adWrapperRef.current) {
            adWrapperRef.current.style.visibility = ''
          }
        }
        currentOpacity.current = newOpacity
        elementRef.current.style.opacity = `${newOpacity}`
      }
    }
  }
}

const WelcomeAd: FunctionComponent<WelcomeAdProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const adWrapperRef = useRef<HTMLDivElement>(null)
  const currentOpacity = useRef<number>(1)
  const isMounted = useMount()

  const onScroll = useCallback(() => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        setOpacity({
          elementRef: wrapperRef,
          adWrapperRef,
          currentOpacity,
        })
        ticking = false
      })
      ticking = true
    }
  }, [wrapperRef])

  useEffect(() => {
    window.Ads.helpers.setWelcomeAd = () => {
      if (isMounted()) {
        setIsVisible(true)
      }
    }
    window.Ads.helpers.closeWelcomeAd = () => {
      setIsVisible(false)
    }
  }, [isMounted])

  useEffect(() => {
    if (isVisible) {
      window.removeEventListener('scroll', onScroll)
      window.addEventListener('scroll', onScroll, { passive: true })
      onScroll()
    } else {
      window.removeEventListener('scroll', onScroll)
    }
  }, [isVisible, onScroll])

  useEffect(() => {
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [onScroll])

  if (!isValidAd(children)) {
    return null
  }

  return (
    <Wrapper ref={wrapperRef} isVisible={isVisible}>
      <AdWrapper ref={adWrapperRef}>
        <JSONRenderer>{children}</JSONRenderer>
      </AdWrapper>
    </Wrapper>
  )
}

export default WelcomeAd
