import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { QueryClient, useQueryClient } from '@tanstack/react-query'

import config from '@config'

import useRiAdTagManagerInit from '@hooks/useRiAdTagManagerInit'
import useVideoSkeleton from '@hooks/useVideoSkeleton'
import useViewportType from '@hooks/useViewport/useViewportType'
import useIsRiAdTagManagerScriptLoadError from '@hooks/useIsRiAdTagManagerScriptLoadError'

import { areAdSlotsLoaded, deepCopy, loadSlots, logAd } from '@utils/ads'
import { Sound } from '@components/Ad/types'
import { Vtype } from '@components/Ad/types'
import { registerSlot as admTagManRegisterSlot } from '@components/Ad/utils'

import { IABCategory, UseVideoPlayerPropsInput } from '@widgets/Video/types'
import { getVideoTypeParam } from '@widgets/Video/utils'

import { AdTagManagerDaiResponse } from './types'
import { getDefaultDaiResponse } from './defaultDaiResponse'

const {
  ads: { videoTypeThresholdInSeconds: videoTypeThresholdInSecondsDefault },
} = config

interface RegisterAdProps
  extends Pick<UseVideoPlayerPropsInput, 'syndication' | 'videoTaxonomy'> {
  daiAssetKey?: string
  vtype: Vtype
  videoId: string
  duration: number
  adContainerId: string
  sound: boolean
  autostart: string
  handleAd: ({
    vmapUrl,
    daiVastUrl,
    message,
  }: {
    vmapUrl?: string
    daiVastUrl?: AdTagManagerDaiResponse
    message?: any
  }) => void
  queryClient: QueryClient
}

const registerAd = ({
  queryClient,
  daiAssetKey,
  vtype,
  videoId,
  duration,
  adContainerId,
  syndication,
  videoTaxonomy,
  sound,
  autostart,
  handleAd,
}: RegisterAdProps) => {
  const BlickTVSpecificProps = {
    source: 'Blick TV Player',
    targeting: {
      vtype,
      sound: (sound ? 'on' : 'off') as Sound,
      player: 'JWPlayer',
    },
    channel: 'BlickTV',
    slot: 'MIDROLL_1',
  }

  const VODSpecificProps = {
    source: 'Video Player',
    duration,
    targeting: {
      vtype,
      syndication,
      sherlock: videoTaxonomy?.map(({ id }: IABCategory) => id) ?? [],
      sound: (sound ? 'on' : 'off') as Sound,
      autostart,
      player: 'JWPlayer',
    },
  }

  const registrationOptions = {
    slot: 'VMAP_1',
    container: adContainerId,
    events: {
      adReady: handleAd,
      adEmpty: handleAd,
      adError: handleAd,
    },
    ...(daiAssetKey ? BlickTVSpecificProps : VODSpecificProps),
  }

  const deepCopiedRegistrationOptions = deepCopy(registrationOptions)

  // Register the VMAP adslot
  admTagManRegisterSlot(registrationOptions)

  const { slot, container } = deepCopiedRegistrationOptions

  const singleLogAd = (loadingBehaviorDescription?: string) => {
    logAd(
      { queryClient },
      `Slot Registered: %c${slot}\n${' '.repeat(5)}%cad type:${' '.repeat(9)}%c${daiAssetKey ? `Dai %cwith asset key: %c${daiAssetKey}` : 'CSAI%c%c'}\n${' '.repeat(5)}%cvtype:${' '.repeat(11)}%c${vtype}\n${' '.repeat(5)}%cvideoId:${' '.repeat(9)}%c${videoId}\n${' '.repeat(5)}%ccontainer:${' '.repeat(7)}%c${container}\n${' '.repeat(5)}%cloading state:${' '.repeat(3)}%c${loadingBehaviorDescription}\n${' '.repeat(5)}%cconfig:`,
      'font-weight: bold;',
      '',
      'font-weight: bold;',
      '',
      'font-weight: bold;',
      '',
      'font-weight: bold;',
      '',
      'font-weight: bold;',
      '',
      'font-weight: bold;',
      '',
      'font-weight: bold;',
      '',
      deepCopiedRegistrationOptions
    )
  }

  if (areAdSlotsLoaded(queryClient)) {
    loadSlots(queryClient)
    singleLogAd(`loaded manually, as batch loading has already happened`)
  } else {
    singleLogAd(`not loaded yet, as batch loading is pending`)
  }
}

type DaiAdTagParametersType = { [key: string]: any }

export type UseVideoAds = (
  options: Pick<
    UseVideoPlayerPropsInput,
    'syndication' | 'videoTaxonomy' | 'videoId' | 'duration'
  > & {
    adSound: boolean
    autostart: boolean
    daiAssetKey?: string
    disabledVideoAds: boolean
  }
) => {
  isAdsReady: boolean
  adContainerId: string
  adTagURL: string
  daiAdTagParameters: DaiAdTagParametersType
}

const useVideoAds: UseVideoAds = ({
  adSound,
  duration,
  syndication,
  videoTaxonomy,
  videoId,
  daiAssetKey,
  autostart,
  disabledVideoAds,
}) => {
  const queryClient = useQueryClient()
  const tagManagerInitCode = useRiAdTagManagerInit()
  const isRiAdTagManagerScriptLoadError = useIsRiAdTagManagerScriptLoadError()
  const viewportType = useViewportType()
  const [isAdsReady, setIsAdsReady] = useState<boolean>(false)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(undefined)
  const vmapUrlRef = useRef<string | null>(null)
  const hasAdRegisteredRef = useRef<boolean>(false)
  const daiAdTagParameters = useRef<DaiAdTagParametersType | null>(null)

  const { videoTypeThresholdInSeconds = videoTypeThresholdInSecondsDefault } =
    useVideoSkeleton()

  const vtype = getVideoTypeParam({
    duration,
    shortVideoLengthThresholdInSeconds: videoTypeThresholdInSeconds,
  })

  const adContainerId = videoId ? `jw-video-container-${videoId}` : ''

  const onTimeout = useCallback(() => {
    logAd(
      { queryClient, method: 'warn' },
      `%cSLOT LOADING SKIPPED: Timeout of 3000ms exceeded\n${' '.repeat(7)}%cvideoId:${' '.repeat(14)}%c${videoId}`,
      'font-weight: bold; color: #B22222;',
      '',
      'font-weight: bold;'
    )

    setIsAdsReady(true)
  }, [queryClient, videoId])

  const defaultDAIVastUrl = useMemo(
    () =>
      getDefaultDaiResponse({
        isMobile: viewportType === 'mobile',
        containerId: adContainerId,
        targeting: {
          sound: adSound,
        },
      }),
    [adContainerId, adSound, viewportType]
  )

  const handleCSAIAd = useCallback(
    ({ vmapUrl, message }: { vmapUrl?: string | null; message?: any }) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      if (vmapUrl) {
        vmapUrlRef.current = vmapUrl
      }

      logAd(
        { queryClient, ...(vmapUrl ? {} : { method: 'warn' }) },
        `%cVIDEO AD CALLBACK: %cVMAP${vmapUrl ? '' : ' NOT'} RETRIEVED\n${' '.repeat(vmapUrl ? 5 : 7)}%cvideoId:${' '.repeat(11)}%c${videoId}${message ? `\n${' '.repeat(vmapUrl ? 5 : 7)}%cmessage:${' '.repeat(11)}%c${message}` : '%c%c'}${vmapUrl ? `\n${' '.repeat(5)}%cvmapUrl:${' '.repeat(11)}%c${vmapUrl}` : '%c%c'}`,
        'font-weight: bold; color: #FFD700;',
        `font-weight: bold; color: ${vmapUrl ? '#2E8B57' : '#B22222'};`,
        '',
        'font-weight: bold;',
        '',
        'font-weight: bold;',
        '',
        'font-weight: bold;'
      )

      setIsAdsReady(true)
    },
    [queryClient, videoId]
  )

  const handleDaiAd = useCallback(
    ({
      daiVastUrl,
      message,
    }: {
      daiVastUrl?: AdTagManagerDaiResponse
      message?: any
    }) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      if (daiVastUrl?.queryString) {
        daiAdTagParameters.current = daiVastUrl?.queryString
      } else {
        daiAdTagParameters.current = defaultDAIVastUrl.queryString
      }

      logAd(
        { queryClient, ...(daiVastUrl?.queryString ? {} : { method: 'warn' }) },
        `%cVIDEO AD CALLBACK: %cdaiVastUrl${daiVastUrl?.queryString ? '' : ' NOT'} RETRIEVED${daiVastUrl?.queryString ? '' : ', defaultDAIVastUrl will be used instead'}\n${' '.repeat(daiVastUrl?.queryString ? 5 : 7)}%cvideoId:${' '.repeat(11)}%c${videoId}${message ? `\n${' '.repeat(daiVastUrl?.queryString ? 5 : 7)}%cmessage:${' '.repeat(11)}%c${message}` : '%c%c'}\n${' '.repeat(daiVastUrl?.queryString ? 5 : 7)}%c${daiVastUrl?.queryString ? 'daiVastUrl' : 'defaultDAIVastUrl'}:`,
        'font-weight: bold; color: #FFD700;',
        `font-weight: bold; color: ${daiVastUrl?.queryString ? '#2E8B57' : '#B22222'};`,
        '',
        'font-weight: bold;',
        '',
        'font-weight: bold;',
        '',
        deepCopy(daiAdTagParameters.current)
      )

      setIsAdsReady(true)
    },
    [queryClient, videoId, defaultDAIVastUrl]
  )

  const getAdAutostartValue = useCallback(() => {
    /**
     * the targeting values have to be strings
     */
    return `${!!autostart}`
  }, [autostart])

  useEffect(() => {
    // the ad will not be registered until we have `adContainerId`
    if (!disabledVideoAds && adContainerId) {
      if (tagManagerInitCode || isRiAdTagManagerScriptLoadError) {
        if (!hasAdRegisteredRef.current) {
          hasAdRegisteredRef.current = true

          if (isRiAdTagManagerScriptLoadError) {
            //! If there is a sript loading error it makes no sense to try to load any ad.
            //! Thus, we immediately proceed with the loading of the video.
            logAd(
              { queryClient, method: 'warn' },
              `%cSLOT LOADING SKIPPED: SDK failed to load\n${' '.repeat(7)}%cvideoId:${' '.repeat(14)}%c${videoId}`,
              'font-weight: bold; color: #B22222;',
              '',
              'font-weight: bold;'
            )

            setIsAdsReady(true)
          } else {
            timeoutRef.current = setTimeout(onTimeout, 3000)

            registerAd({
              queryClient,
              daiAssetKey,
              vtype,
              videoId,
              duration,
              adContainerId,
              syndication,
              videoTaxonomy,
              sound: adSound,
              autostart: getAdAutostartValue(),
              handleAd: daiAssetKey ? handleDaiAd : handleCSAIAd,
            })
          }
        }
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [
    adContainerId,
    adSound,
    daiAssetKey,
    disabledVideoAds,
    duration,
    getAdAutostartValue,
    handleCSAIAd,
    handleDaiAd,
    isRiAdTagManagerScriptLoadError,
    onTimeout,
    queryClient,
    syndication,
    tagManagerInitCode,
    videoId,
    videoTaxonomy,
    vtype,
  ])

  return {
    adContainerId,
    adTagURL: vmapUrlRef.current ?? '',
    daiAdTagParameters: daiAdTagParameters.current ?? {},
    isAdsReady,
  }
}

export default useVideoAds
