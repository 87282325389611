import isEqual from 'lodash.isequal'
import { CMPCookieCategories } from '@components/CMPManager/types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { logCMP } from '@components/CMPManager/utils'

type SubscriptionType =
  //* Used when we want to subscribe to a change that results in changing
  //* the boolean response of the following statement: "All categories are enabled".
  //* Returns undefined if the user has not interacted with the CMP (not provided a consent yet).
  | 'allCategoriesEnabledChanged'

  //* Used when we want to know subscribe to any category change.
  //* Returns all cookie categories with their respective status.
  //* Returns an empty object when user has not interacted with the CMP (not provided a consent yet).
  | 'anyCategoryChanged'

  //* Used when we want to know whether certain categories have changed status.
  //* Returns the selected categories with their respective status.
  //* Returns an empty object when user has not interacted with the CMP (not provided a consent yet).
  | 'someCategoriesChanged'

type UseCMPCookieCategoriesResponseType =
  | boolean
  | undefined
  | CMPCookieCategories

function useCMPCookieCategories(
  subscriptionType: 'allCategoriesEnabledChanged'
): boolean | undefined
function useCMPCookieCategories(
  subscriptionType: 'anyCategoryChanged'
): CMPCookieCategories
function useCMPCookieCategories(
  subscriptionType: 'someCategoriesChanged',
  subscribedCategories: string[]
): CMPCookieCategories

function useCMPCookieCategories(
  subscriptionType: SubscriptionType,
  subscribedCategories?: string[]
): UseCMPCookieCategoriesResponseType {
  const subscribedCategoriesComparisonKey = JSON.stringify(subscribedCategories)
  const queryClient = useQueryClient()

  const calculateCookieResponse = useCallback<
    (
      cookieCategories: CMPCookieCategories
    ) => UseCMPCookieCategoriesResponseType
  >(
    (cookieCategories) => {
      if (subscriptionType === 'allCategoriesEnabledChanged') {
        //! We need to exclude certain cookie categories from "allCategoriesEnabledChanged",
        //! since they're not serving their intended purpose.
        const filteredCookieCategories = Object.entries(
          cookieCategories
        ).reduce<CMPCookieCategories>((acc, cookieCategory) => {
          if (
            ['C0001', 'C0002', 'C0003', 'C0004', 'C0005']?.includes(
              cookieCategory[0]
            )
          ) {
            acc[cookieCategory[0]] = cookieCategory[1]
          }

          return acc
        }, {})
        return Object.keys(filteredCookieCategories).length > 0
          ? Object.values(filteredCookieCategories).every(
              (isCategoryEnabled) => isCategoryEnabled === true
            )
          : undefined
      } else if (subscriptionType === 'someCategoriesChanged') {
        return Object.entries(cookieCategories).reduce<CMPCookieCategories>(
          (acc, cookieCategory) => {
            if (subscribedCategories?.includes(cookieCategory[0])) {
              acc[cookieCategory[0]] = cookieCategory[1]
            }

            return acc
          },
          {}
        )
      } else {
        return cookieCategories
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subscriptionType, subscribedCategoriesComparisonKey]
  )

  const cookieCategoriesResponseRef =
    useRef<UseCMPCookieCategoriesResponseType>(undefined)

  const [cookieCategories, setCookieCategories] =
    useState<UseCMPCookieCategoriesResponseType>(() => {
      const initialCookieCategoriesResponse = calculateCookieResponse(
        queryClient.getQueryData<CMPCookieCategories>([
          'cmp-cookie-categories',
        ]) ?? {}
      )

      cookieCategoriesResponseRef.current = initialCookieCategoriesResponse

      return initialCookieCategoriesResponse
    })

  const queryCache = queryClient.getQueryCache()

  const setUpdatedCookieCategories = useCallback(() => {
    const newCookieCategoriesResponse = calculateCookieResponse(
      queryClient.getQueryData<CMPCookieCategories>([
        'cmp-cookie-categories',
      ]) ?? {}
    )

    logCMP(
      queryClient,
      '\nuseCMPCookieCategories (re-)RENDERED',
      `\nsubscriptionType: ${subscriptionType}`,
      `\nsubscribedCategories: ${JSON.stringify(subscribedCategories)}`,
      `\nOld subscription response: ${JSON.stringify(
        cookieCategoriesResponseRef.current
      )}`,
      `\nNew subscription response: ${JSON.stringify(
        newCookieCategoriesResponse
      )}`
    )

    if (
      !isEqual(newCookieCategoriesResponse, cookieCategoriesResponseRef.current)
    ) {
      cookieCategoriesResponseRef.current = newCookieCategoriesResponse
      setCookieCategories(newCookieCategoriesResponse)
    }
  }, [
    calculateCookieResponse,
    queryClient,
    subscribedCategories,
    subscriptionType,
  ])

  const unsubscribeFnRef = useRef<() => void>(undefined)

  const updateCookieCategories = useCallback(
    (args: any) => {
      if (args.query.queryKey[0] === 'cmp-cookie-categories') {
        logCMP(queryClient, 'Query key updated', args?.action?.type)
      }
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'cmp-cookie-categories'
      ) {
        setUpdatedCookieCategories()
      }
    },
    [setUpdatedCookieCategories, queryClient]
  )

  useEffect(() => {
    setUpdatedCookieCategories()

    unsubscribeFnRef.current = queryCache.subscribe(updateCookieCategories)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [queryCache, setUpdatedCookieCategories, updateCookieCategories])

  return cookieCategories
}

export default useCMPCookieCategories
