import { ImageRatios } from '@components/ResponsiveImageV2/types'

const IMAGE_RATIOS: ImageRatios = {
  desktop: '1_1',
  tablet: '1_1',
  mobile: '1_1',
}

const IMAGE_WIDTHS = {
  desktop: 182,
  tablet: 182,
  mobile: 182,
}

export { IMAGE_RATIOS, IMAGE_WIDTHS }
